<section class="webdev-sec2 servpage-header">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-div1">
                    <h1 class="webdev-div1-h1"><span>Web Application</span> Testing</h1>
                    <p class="webdev-div1-p1">Execute Precise Testing and<br/>Analysis Services</p>
                    <p class="webdev-div1-p2">Guaranteed comprehensive analysis to provide end-to-end solutions discovering your project’s functionality issues, vulnerabilities, and performance on multiple browsers and platforms to ensure that end-users receive the experience they’ll relish and keep on continuing. RPA Technologies consider web applications testing and QA as a crucial part of every project. We rigorously follow industry standards in software testing methodologies to achieve quality solutions with zero-error and quality assurance goals of every client. Our in-house team of experts are skilled professionals having experience of multiple technologies & software testing domains, tools, and standards which enables us to apply an integrated testing strategy and achieve the best of solutions. Our QA parameters include integration, reliability, customer satisfaction, end-user experience and prevention over inspection. In the past <strong>8 years,</strong> we have delivered <strong>500+ projects</strong> to <strong>1000+ clients</strong> in <strong>25 different countries</strong> and are still counting!</p>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-imgdiv1">
                    <img src="assets/img/qa-testing/qa-test-img1.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="servtabs-sec" *ngIf="isDesktop">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mvc-fixd" id="webdevelopment">
                  <section class="mvc-page-links">
                    <div class="wrapper">
                      <div class="mvc-links-main">
                        <div class="mvc-menu-list">
                          <ul>
                            <li>
                                <a tabindex="-1" class="servtab-ove-link" [ngClass]="{'active': currentTab == 1}" (click)="scroll(overview)"><i class="circle"></i><span>Overview</span></a>
                            </li>
                            <li>
                                <a  tabindex="-1" class="servtab-ser-link" [ngClass]="{'active': currentTab == 2}" (click)="scroll(services)"><i class="circle"></i><span>Services</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-hwd-link" [ngClass]="{'active': currentTab == 3}" (click)="scroll(how)"><i class="circle"></i><span>How we do it</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-sol-link" [ngClass]="{'active': currentTab == 4}" (click)="scroll(solutions)"><i class="circle"></i><span>Solutions</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-ow-link" [ngClass]="{'active': currentTab == 5}" (click)="scroll(work)"><i class="circle"></i><span>Our Work</span></a>
                            </li>
                            <li><a tabindex="-1" [ngClass]="{'active': currentTab == 6}" class="servtab-rq-link"><i class="circle"></i><span>Request Quote</span></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
            </div>
        </div>
    </div>
</section>  

<section class="servove-sec" id="overflow-slide_1" #overview>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="servove-text">  
                    <h2>Pushing the limits with a combination of <span class="secondary-color">intelligence, experience and vision</span> to re-invent the process of testing!</h2>
                    <div class="servove-quote">
                        <img src="assets/img/servove-quote.svg" alt="quote">
                        <p><span>Creativity</span> Simplified</p>
                    </div>
                    <p>RPA Technologies deeply understand the necessity of zero-error testing/analysis and focus on web application testing process to ensure that product behaves consistently while going through one version to another. Our quality assurance services are designed with a motto to help our prestigious client in managing their web application testing strategies and embellish the current maturity levels by improvising every phase of QA including personals, processes, products and services. The QA testing service includes planning, strategy designing, recommending tools, assets evaluation and process planning. </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="webdev-sec2">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-imgdiv1 qa-imgdiv1">
                    <img src="assets/img/qa-testing/qa-test-img2.svg" alt="image">
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-div1">
                    <h3 class="webdev-div1-h1">Test <span>Advisory Services</span></h3>
                    <p class="webdev-div1-p2">We define the goals for the Test Advisory services upfront and then perform an integrated evalution across various dimensions of your business. Our extensive QA accelerators are leveraged industry best practices and baselines to come up with the best possible solutions. We route testing processes & tools against the best practices to identify the gaps in ability for supporting organization growth, analyze improvement scope and provide recommendations for enterprise-level testing processes. We aid you in developing a roadmap of initiatives that will help align QA strategy with the enterprise goals.</p>
                </div>
            </div>            
        </div>
    </div>
</section>

<section class="servserv-sec" id="overflow-slide_2" #services>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h3 class="servserv-head">QA & Testing Services We Offer</h3>
                    <div class="bar"></div>
                    <p>At <strong>RPA Technologies,</strong> we think in new dimensions and outside the box to unlock your business potential.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/functional_icon.svg" alt="icon" />
                        </div>
                        <p>Functional<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>We test and codify every feature of the web application to ensure smooth user experience and better working.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/regression_icon.svg" alt="icon" />
                        </div>
                        <p>Regression<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>We make sure that new codes don't cause errors with previously released apps, so the users use new features effectively.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/performance_icon.svg" alt="icon" />
                        </div>
                        <p>Performance<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>We review applications focusing user demands, needs, and business processes to accomplish the acceptance criteria.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/configuration_icon.svg" alt="icon" />
                        </div>
                        <p>Configuration<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>Through our services for test design and execution, we monitor the effects of configuration changes on performance.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/isolation_icon.svg" alt="icon" />
                        </div>
                        <p>Isolation<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>Our experts of QA & Testing identify hidden issues without bothering about bug challenges with isolation testing.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="qa-servbox">
                    <div class="qa-servboxb">
                        <div class="qa-sbb-imgdiv">
                            <img src="assets/img/qa-testing/services-icons/browser_icon.svg" alt="icon" />
                        </div>
                        <p>Browser<br/>Testing</p>
                    </div>
                    <div class="qa-servboxa">
                        <p>We perform browser testing with the tools like Sauce Labs, and develop your app to work seamlessly on multiple browsers.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hwdi-sec" id="overflow-slide_3" #how>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h3 class="hwdi-head">Our Testing Process</h3>
                    <div class="bar"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="hwd-box hwd-box1">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/analysis.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Requirement Analysis</h4>
                        <p>Our QA specialist team studies your project requirements and identifies potential pitfalls.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-1.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">1</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box2">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/test-plan.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Test Plan Creation</h4>
                        <p>We build effective testing activities and select the most appropriate tools to conduct effectively.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-2.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">2</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box3">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/test-creation.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Test Case Creation</h4>
                        <p>Preparing test cases and suites to cover the entire testing scope your web application needs.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-3.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">3</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box4">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/test-execution.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Test Case Execution</h4>
                        <p>Our testing team performs rigorous testing and pen down identified bugs and issues.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-4.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">4</div>
                </div>
            </div> 
            <div class="col-md-3">
                
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box7">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/release.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Final Release</h4>
                        <p>Finally the app gets submitted to Google Play Store for releasing and start the process of user acquisition.</p>
                    </div>
                    <div class="hwdcount">7</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box6">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/defect-fixing.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Defect Fix & Re-verification</h4>
                        <p>We find the reasons for identified bugs and offer methods to fix them for reappearing in future.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-6.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">6</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box5">
                    <div class="hwdimg-box">
                        <img src="assets/img/qa-testing/process-icons/logging.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Defect Logging</h4>
                        <p>Reports of identified issues and bugs are shared with the responsible team of developer participants.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-5.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">5</div>
                </div>
            </div>  

        </div>
    </div>
</section>

<section class="serv-sol-sec" id="overflow-slide_4" #solutions>
    <section class="serv-sol-pro">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">                    
                        <h4 class="serv-sol-head">Platforms We've Tested</h4>
                        <div class="bar"></div>
                        <p>Since we are aware of limitations with every latest technology. We know which would be the ideal one for your web applications.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="servsol-box servsol-box1">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/cms-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>CMS</h5>
                            <p>
                                Fulfilling the core requirement of testing and QA in a SaaS-based implementation for delivering a bug-free and workflow-driven CMS. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box2">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/ecommerce-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Ecommerce</h5>
                            <p>
                                Thoroughly tested Ecommerce website to ensure that it provides exemplary user experience and higher sales are being made for our clients.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box3">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/webdev-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Web Development</h5>
                            <p>
                                We ensure our client websites are flawless, well-functioned, easy to navigate and without browser fragmentation for converting visitors into customers.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box4">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/uiux-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>UI / UX</h5>
                            <p>
                                We have performed usability testing with a focus on ease to use, flexibility in control handling and ability of the system to meet desired objectives.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box5">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/ios-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>iOS</h5>
                            <p>
                                Efficient testing with compliance to industry standards for efficient iOS testing that's without any deeply hidden issues, so it gets a good amount of downloads. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box6">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/qa-testing/android-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Android</h5>
                            <p>
                                Successfully tested android applications on the parameters of quality, user experience, and fragmentation to ensure a bug-free application gets published. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>
</section>

<section class="serv-ourwork-sec" id="overflow-slide_5" #work>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h6 class="serv-ourwork-head">Projects completed in <span id="currentMonth"></span>&nbsp;<span id="currentYear"></span></h6>
                    <div class="bar"></div>
                    <p>Have some insights to the range of projects delivered in different tech stack by us.</p>
                </div>
            </div>
        </div>
    </div>
        <owl-carousel
         #owlElement
         [options]="OwSlideOpt"
        
         [carouselClasses]="['owl-theme', 'row', 'sliding']" class="serv-ow-slider">
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/geohoneylogo.svg" alt="technology icon" class="geohoneylogo"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Geohoney is the UAE's largest supplier of pure and organic honey across the globe. It strives to deliver only the best quality &
                                     healthy honey products at affordable prices and that too at the customer's doorstep.
                                </h4>
                                <ul class="item_list pl-40 geohoneyul">
                                    <li>Custom Cart Management Module.</li>
                                    <li>Bulk Buying Module Integration.</li>
                                    <li>Integrated 3 Payment Gateway.</li>
                                    <li>Integrated Loyalty Program Module.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/RESTfulAPI.svg" alt="technology icon" />
                                            <span class="projectlaungetext">RESTfulAPI</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                      
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/geohoneymobilefram.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/bookmypetlogo.png" alt="technology icon" class="geohoneylogo"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Drawing the idea from the influence of technology, we helped Bookmypet in automating the process of serving towards the 
                                    welfare of the pets and facilitate pet owners to find reliable services all at one place.
                                </h4>
                                <ul class="item_list pl-40 boomypetul">
                                    <li>Created search functionality.</li>
                                    <li>Created custom calendar.</li>
                                    <li>Implemented map feature.</li>
                                    <li>Created module for partial refunds.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/GraphQL.svg" alt="technology icon" />
                                            <span class="projectlaungetext">GraphQL</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/KNEX.JS.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Knex.js</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/nodejs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Node.js</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/bookmypetmobilefram.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/BMPmobilenewimage1.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/discounternewlogo1.png" alt="technology icon" class="mobileappwebsiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    We helped Discounter, deal with targeted audiences through a UI concept capable of attracting more audiences and 
                                    allowing service providers to update offers & discounts to achieve popularity.
                                </h4>
                                <ul class="item_list pl-40 discounteryul">
                                    <li>Coupon Code Redemption Feature.</li>
                                    <li>Built Custom Location Based Filter.</li>
                                    <li>Integrated Deep Link Functionality.</li>
                                    <li>Leaderboard Functionality Creation.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Swift.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Swift</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Java.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Java</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/kotlin.svg" alt="technology icon" />
                                            <span class="projectlaungetext">kotlin</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/GoogleCloud.svg" alt="technology icon" />
                                        <span class="projectlaungetext">GCloud</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/discountermobilefram.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/discounterMobileimage8.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/sghired/Logo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    SG Hired is a mobile app for security agencies to hire security officers. The project aimed at providing agency 
                                    users with the ability to create jobs, get updates for interested officers & finally rate them after job completion.
                                </h4>
                                <ul class="item_list pl-40 sghiredulli">
                                    <li>Created functionality for real-time tracking.</li>
                                    <li>Stripe payment gateway integration.</li>
                                    <li>Chat support feature for resolving disputes.</li>
                                    <li>Functionality for real time job analytics.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/RESTfulAPI.svg" alt="technology icon" />
                                            <span class="projectlaungetext">RESTfulAPI</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                      
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/sghired/mobilephonebackhg.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/sportdotnet/Logo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    We help customers improve in sports with unique services and high-tech product guidance. We started out with 
                                    badminton and in 2016 handball was added. Offering 365-day badminton racket insurance globally.
                                </h4>
                                <ul class="item_list pl-40 sportdotnetulli">
                                    <li>Upgraded website to the Prestashop 1.7 </li>
                                    <li>Developed rating & price module.</li>
                                    <li>Implemented refine filter module.</li>
                                    <li>Implemented 360° view functionality.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/sportdotnet/PrestaShop.svg" alt="technology icon" />
                                            <span class="projectlaungetext">PrestaS..</span>
                                        </div>
                                       
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaSc..</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/sportdotnet/angular.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Angular</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Apache.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Apache</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/sportdotnet/PHP.svg" alt="technology icon" />
                                        <span class="projectlaungetext">PHP</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/sportdotnet/rightwebsiteimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/audio/newlogoaudi.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Audi Lifestyle Collection is an extraordinary product collection to help you explore the wonderful world of Audi. 
                                    The clients supply their products globally through the online store by  providing you an easy doorstep delivery.
                                </h4>
                                <ul class="item_list pl-40 audioulli">
                                    <li>Video animation for products promotion.</li>
                                    <li>Filter functionality on listing page.</li>
                                    <li>Size and color attribute selection feature.</li>
                                    <li>Customized CSV File for importing products</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/GraphQL.svg" alt="technology icon" />
                                            <span class="projectlaungetext">GraphQL</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaScript</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/Rails.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Rubys</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/Nginx.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Nginx</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/audio/rightimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/travelhub/tth-logo-small.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    The Travel Hub stands for a lasting immersive travel experience that brings different cultures together.
                                    The Travel Hub marked its beginning in 2020 with an aim to create a hub for passionate travellers to share their travel exp...
                                </h4>
                                <ul class="item_list pl-40 travelhubul">
                                    <li>Developed customizable article creation.</li>
                                    <li>Developed a cropping feature for images.</li>
                                    <li>Built admin analytics tool for data insights.</li>
                                    <li>Developed personalized ad feature.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/HTML.svg" alt="technology icon" />
                                            <span class="projectlaungetext">HTML</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Vue.svg" alt="technology icon" />
                                             <span class="projectlaungetext">Vue</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Jquery.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Jquery</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Laravel.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Apache.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Apache</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/PostgreSQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">PostgreSQL</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/travelhub/rightwebsite.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/7Walls/Asset7wallnewlogo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    7Walls Wallpaper Trading LLC is founded to provide a variety of innovative printed wall coverings on a diverse range which 
                                    includes wallpapers and canvases with a sustainable eco-friendly approach.
                                </h4>
                                <ul class="item_list pl-40 wallsulli">
                                    <li>Integrated tabby payment gateway for EMI.</li>
                                    <li>custom design for desktop and mobile.</li>
                                    <li>Customized whole site in Arabic language.</li>
                                    <li>Created module for generating quotation.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/HTML.svg" alt="technology icon" />
                                            <span class="projectlaungetext">HTML</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/7Walls/CSS.svg" alt="technology icon" />
                                             <span class="projectlaungetext">CSS</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Jquery.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Jquery</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/Wordpress.svg" alt="technology icon" />
                                         <span class="projectlaungetext">WP</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/Nginx.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Nginx</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/7Walls/right7wallimages.jpg" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/callgtc/CallGTClogo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Call GTC India, part of GTC Control Solutions Inc., offers trusted and reliable GE Speedronic Turbine and Excitation controls 
                                    servicing for 20+ years and now provides expertise beyond General Electric.
                                </h4>
                                <ul class="item_list pl-40 callgtculli">
                                    <li>Data security using encryption techniques.</li>
                                    <li>Created CSV import functionality.</li>
                                    <li>Created custom search functionality.</li>
                                    <li>Dynamically managed tags & meta titles.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                       
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaSc..</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/callgtc/Nextjs.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Next</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/nodejs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Node.js</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/callgtc/Expressjs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Express</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/callgtc/rightwebimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </owl-carousel> 
        <div class="carousel-navigation col-lg-12 col-md-12 col-sm-12">
            <div class="nav-left">
                <div class="item-number">
                    <span id="showcount">1/9</span>
                </div>
                <div class="nav-buttons owl-nav">
                    <!-- <img class="nav-btn previous-btn" src="assets/img/ic-ar-circle-left-new.svg" alt="Previous" id="previous-btn" (click)="owlprevslide()">
                    <img id="next-btn" class="nav-btn next-btn" src="assets/img/ic-ar-circle-right-new.svg" alt="Next" (click)="owlnextslide()"> -->
                </div>
            </div>
            <div class="right-nav">
                <a routerLink="/portfolio" class="card-link">
                    <span class="mobile-solid-blue-btn mobile-solid-btn">VIEW MORE PROJECTS 
                        <img class="arrow-right display-mobile-none" src="assets/img/ic-long-arrow-new.svg" alt="View
                        All
                        Projects">
                    </span>
                </a>
    
            </div>
        </div>
</section>




