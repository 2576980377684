<!-- Start Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <h2>Project Details</h2>
            </div>
        </div>
    </div>
    
    <div class="shape1"><img src="assets/img/shape1.png" alt="shape"></div>
    <div class="shape2 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
    <div class="shape3"><img src="assets/img/shape3.svg" alt="shape"></div>
    <div class="shape4"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape5"><img src="assets/img/shape5.png" alt="shape"></div>
    <div class="shape6 rotateme"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape7"><img src="assets/img/shape4.svg" alt="shape"></div>
    <div class="shape8 rotateme"><img src="assets/img/shape2.svg" alt="shape"></div>
</div>
<!-- End Page Title -->

<!-- Start Project Details Area -->
<section class="project-details-area ptb-80" *ngFor="let ourwork of ourworks">
    <div class="container">
        <div class="row">
			<div class="col-lg-12 col-md-12">
                <div class="project-details-image">
                    <img src="{{ourwork.banner_image}}" alt="work">
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="project-details-desc">
                    <h3>{{ourwork.title}}</h3>
                    
                    <div [innerHTML]="ourwork.description"></div>     

                    <div class="project-details-information">
                        <div class="single-info-box">
                            <h4>Happy Client</h4>
                            <p>{{ourwork.client}}</p>
                        </div>

                        <div class="single-info-box">
                            <h4>Industry Type</h4>
                            <p>{{ourwork.cat_name}}</p>
                        </div>

                        <div class="single-info-box">
                            <h4>Client Nationality</h4>
                            <!-- <p>February 28, 2019</p> -->
                            <p>{{ourwork.client_nationality}}</p>
                        </div>

                        <div class="single-info-box">
                            <h4>Share</h4>
                            <ul>
                                <li><a href="https://www.facebook.com/sharer.php?u={{sharehref}}" target="_blank"></a></li>
                                <li><a href="https://twitter.com/intent/tweet?url={{sharehref}}" target="_blank"></a></li>
                                <li><a href="https://www.linkedin.com/shareArticle?mini=true&url={{sharehref}}" target="_blank"></a></li>
                            </ul>
                        </div>

                        <div class="single-info-box" *ngIf="isDesktop">
                            <a href="{{ourwork.live_link}}" class="btn btn-primary" target="_blank">Live Preview</a>
                        </div>
                    </div>

                    <div *ngIf="isMobile">
                        <a href="{{ourwork.live_link}}" class="btn btn-primary m-liveprev-btn" target="_blank">Live Preview</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Project Details Area -->