import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-servicebanner',
  templateUrl: './servicebanner.component.html',
  styleUrls: ['./servicebanner.component.css']
})
export class ServicebannerComponent {

  @Input() heading: any;
  @Input() paragraph: any;
  @Input() trusted: any;
  @Input() bannerimg: any;
  @Input() cards: any;
  @Input() cards2: any;
  @Input() tagline: any;
  @Input() mobileImg: any;
  currentRoute: any = '';

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });
  }
  goToBottom() {
    // window.scrollTo(0, 6000);
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });

  }


  carouselConfig = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };


}
