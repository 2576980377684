import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { LoadingService } from '../utils/services/loading.service';

// import jquery
import * as $ from 'jquery';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  group,
} from '@angular/animations';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.css'],
})
export class CaseStudiesComponent implements OnInit {
  isMobile = true;
  isDesktop = true;

  @HostListener('window:resize', [])
  onResize() {
    var width = window.innerWidth;
    if (width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  // Meta Title
  title = 'Case Studies - Pro Web';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000);
    /* Generate code according to resolution open here */

    if (window.screen.width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }

    /* Generate code according to resolution close here */

    // Meta Title & Description
    this.titleService.setTitle(
      'Case Study - Web Design, Development & Marketing | Pro Web '
    );
    this.metaTagService.updateTag({
      name: 'description',
      content:
        "Discover success with Pro Web's case studies. We excel in smart software solutions and custom development for businesses. Your success story starts here!",
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content: 'case studies, pro web case studies',
    });
    // Meta Title & Description

    $(document).ready(function () {
      $('.single-story-logo').click(function () {
        $('.stories_logo_wrapper .ssl-cls').removeClass('active');
        $(this).addClass('active');

        if ($(this).hasClass('ssl-cls1')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_1').addClass('active');
        } else if ($(this).hasClass('ssl-cls2')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_2').addClass('active');
        } else if ($(this).hasClass('ssl-cls3')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_3').addClass('active');
        } else if ($(this).hasClass('ssl-cls4')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_4').addClass('active');
        } else if ($(this).hasClass('ssl-cls5')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_5').addClass('active');
        } else if ($(this).hasClass('ssl-cls6')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_6').addClass('active');
        } else if ($(this).hasClass('ssl-cls7')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_7').addClass('active');
        } else if ($(this).hasClass('ssl-cls8')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_8').addClass('active');
        } else if ($(this).hasClass('ssl-cls9')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_9').addClass('active');
        } else if ($(this).hasClass('ssl-cls10')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_10').addClass('active');
        } else if ($(this).hasClass('ssl-cls11')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_11').addClass('active');
        } else if ($(this).hasClass('ssl-cls12')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_12').addClass('active');
        } else if ($(this).hasClass('ssl-cls13')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_13').addClass('active');
        } else if ($(this).hasClass('ssl-cls14')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_14').addClass('active');
        } else if ($(this).hasClass('ssl-cls15')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_15').addClass('active');
        } else if ($(this).hasClass('ssl-cls16')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_16').addClass('active');
        } else if ($(this).hasClass('ssl-cls17')) {
          $('.success-story-list-wrapper .single-story').removeClass('active');
          $('#story_17').addClass('active');
        }
      });
    });
  }

  collections = [
    {
      bgImage: 'assets/img/webp-image/case-study/audiimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/audi-logocasestudy.webp',
      headingtext: 'Audi Lifestyle Store ( Middle East )',
      descriptiontext: 'Description',
      textdiscription:
        "Audi Collection's goal is to bring quality workmanship and modern design to everyday life. They launched an online store in the Middle East, offering premium items as a subtle nod to Audi enthusiasts. Audi Lifestyle's user-friendly e-shop allows hassle-free shopping and doorstep delivery of Audi Collection products, making it easy to order with a few clicks.",
      subtitle: 'Category',
      Categorytext: 'Lifestyle',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/audi',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/omnew.webp',
      image: 'assets/img/webp-image/case-study/logo.webp',
      headingtext: 'Organics Mantra',
      descriptiontext: 'Description',
      textdiscription:
        "Organics Mantra is your go-to online grocery and dairy products store for a healthier lifestyle. Discover a diverse selection of organic and natural food items sourced with care to nourish your body and support sustainable practices. From fresh produce to wholesome dairy, each product is chosen to provide you with the best of nature's goodness. Embrace a mindful approach to shopping and savor the flavors of wholesome, chemical-free delights with Organics Mantra.",
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/organics-mantra',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/kalachainnew.webp',
      image: 'assets/img/webp-image/case-study/kalachainlogo.webp',
      headingtext: 'Kalachain ',
      descriptiontext: 'Description',
      textdiscription:
        'Kalachain is a cutting-edge blockchain platform revolutionizing the way businesses harness the potential of decentralized technology. With its innovative tech stack comprising Ethereum for smart contracts, IPFS for decentralized storage, and Polkadot for interoperability, Kalachain empowers enterprises to build scalable, secure, and transparent solutions that redefine industries and drive digital transformation.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/kalachain',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/bookmypetimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/bookmypetlogocase.webp',
      headingtext: 'Bookmypet App',
      descriptiontext: 'Description',
      textdiscription:
        "Bookmypet is a platform that brings together reliable and established sources for boarding, grooming, day care, and veterinary services for pets. They offer a personalized experience by curating services based on availability, virtual viewings, and authentic customer reviews and pricing. The platform's comprehensive filtering criteria make it easy for users to compare pet services in their desired location and within their budget. With Bookmypet, pet owners can ensure that their furry friends receive the best care possible.",
      subtitle: 'Category',
      Categorytext: 'Health Care',
      teamtext: 'Team',
      teamstanth: '5',
      linktext: 'Read Full Case Study',
      link: '/case-study/bookmypet',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/webp-image/case-study/travelhubimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/tth-logo-case.webp',
      headingtext: 'The Travel Hub',
      descriptiontext: 'Description',
      textdiscription:
        'The Travel Hub stands for a lasting immersive travel experience that brings different cultures together. With the travel landscape changing at a fast pace, they wanted to create a common space for travelers and explorers to interact via Articles, Share Reviews, and discuss travel-related topics over forums. The Travel Hub marked its beginning in 2020 with an aim to create a hub for passionate travelers to share their travel experiences.',
      subtitle: 'Category',
      Categorytext: 'Tour & Travel Services',
      teamtext: 'Team',
      teamstanth: '5',
      linktext: 'Read Full Case Study',
      link: '/case-study/travelhub',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/webp-image/case-study/discounter-bg.webp',
      image: 'assets/img/webp-image/case-study/thediscounter-logo.webp',
      headingtext: 'The Discounter App',
      descriptiontext: 'Description',
      textdiscription:
        'The Discounter App provides discounts on a wide range of experiences, including restaurants, beauty treatments, and entertainment in the UAE. With thousands of offers available, it is rapidly emerging as a top app for instant offers and promo codes in the Middle East and North Africa. The app aims to revolutionize the market and provide a unique experience to its users.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/discounter',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/luxurybg.webp',
      image: 'assets/img/webp-image/case-study/luxury_logo.webp',
      headingtext: 'Luxury Gallery',
      descriptiontext: 'Description',
      textdiscription:
        'Elevate your lifestyle with a curated collection of timeless elegance and impeccable craftsmanship. Discover iconic watches and exquisite jewelry from distinguished brands since 1881. Immerse yourself in sophistication with horological masterpieces and dazzling creations. Experience a new standard of luxury indulgence with a rich heritage and unwavering commitment to excellence. Passion, precision, and prestige converge at Luxury Gallery.',
      subtitle: 'Category',
      Categorytext: 'Lifestyle',
      teamtext: 'Team',
      teamstanth: '5',
      linktext: 'Read Full Case Study',
      link: '/case-study/luxurygallery',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },

    {
      bgImage: 'assets/img/webp-image/case-study/quillbg.webp',
      image: 'assets/img/webp-image/case-study/quilllogo.webp',
      headingtext: 'Quill At Will',
      descriptiontext: 'Description',
      textdiscription:
        'Quill At Will has an Online journal / Diary writing platform designed for the mind and soul to boost positive energy. Store your thoughts and express yourself through journaling which makes writing fun and enables sharing creative expression with others. This interactive and intuitive platform aims at enhancing creative confidence. It is a versatile and easy-to-use portal that helps you write and publish blogs, poems, and essays, maintain your diary notes, and also send postcards to friends. ',
      subtitle: 'Category',
      Categorytext: 'Education',
      teamtext: 'Team',
      teamstanth: '3',
      linktext: 'Read Full Case Study',
      link: '/case-study/quillatwill',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/bmwbacjgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/bmwlogocase.webp',
      headingtext: ' BMW ',
      descriptiontext: 'Description',
      textdiscription:
        "The BMW Kuwait store for lifestyle products is a digital platform that provides a captivating window into the world of BMW beyond automobiles.The website seamlessly integrates BMW lifestyle products to purchase. It offers an array of lifestyle content, encompassing fashion, design, travel, and technology, all in alignment with BMW's values of luxury and sophistication.",
      subtitle: 'Category',
      Categorytext: 'Lifestyle',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/bmw',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },

    {
      bgImage: 'assets/img/webp-image/case-study/upsidebg.webp',
      image: 'assets/img/webp-image/case-study/upsidelogo.webp',
      headingtext: 'Upside Health ',
      descriptiontext: 'Description',
      textdiscription:
        'UpsideHealth is a groundbreaking platform that revolutionizes the approach to personal wellness. By unlocking the true potential of every individual, it empowers users to elevate their health and well-being. Through its innovative and tailor-made solutions, UpsideHealth offers a transformative journey towards a healthier and more fulfilling life. Embracing cutting-edge technology and personalized approaches, this platform is a beacon of hope for those seeking to enhance their overall quality of life.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer',
      teamtext: 'Team',
      teamstanth: '3',
      linktext: 'Read Full Case Study',
      link: '/case-study/upside-health',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/qdbbg.webp',
      image: 'assets/img/webp-image/case-study/qdblogo.webp',
      headingtext: 'QDB Bank',
      descriptiontext: 'Description',
      textdiscription:
        "QDB (Qatar Development Bank) plays a pivotal role in Qatar's economic growth by offering a wide range of financial and advisory services to entrepreneurs and SMEs. As a catalyst for development, QDB empowers these businesses with the tools and resources they need to thrive. Through its comprehensive suite of products, including financial support and expert guidance, QDB nurtures innovation and fosters sustainable growth in the entrepreneurial landscape of Qatar. By nurturing and supporting these enterprises, QDB contributes significantly to the country's economic development cycle.",
      subtitle: 'Category',
      Categorytext: 'Professional Service',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/qatar-bank',

      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/lamaisonbg.webp',
      image: 'assets/img/webp-image/case-study/lamaisonlogo.webp',
      headingtext: 'Maison Luxe',
      descriptiontext: 'Description',
      textdiscription:
        'Maison Luxe is a prestigious destination, renowned for curating an exceptional collection of international luxury brands and products. With a focus on delivering personalized shopping experiences, it offers a haven for discerning shoppers seeking the most sought-after brands. Nestled within its elegant confines, Maison Luxe provides a seamless fusion of opulence and exclusivity, making it a go-to hub for those with a taste for the finest in the world of luxury.',
      subtitle: 'Category',
      Categorytext: 'Lifestyle',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/maison-luxe',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/smartdoor.webp',
      image: 'assets/img/webp-image/case-study/smartdorlogo.webp',
      headingtext: 'SmartDoor ',
      descriptiontext: 'Description',
      textdiscription:
        'SmartDoor Provides a  convenient way to buy, rent or list your properties with the ability to access properties using digital keys and schedule visits at preferred time slots. It shows the property to prospective buyers without the need for physical visits to the site, using our smart solution to let technology do the work for you. We also provide flexible Access management where there is no need to change the lock with every change of tenant.',
      subtitle: 'Category',
      Categorytext: 'Property & Real Estate',
      teamtext: 'Team',
      teamstanth: '3',
      linktext: 'Read Full Case Study',
      link: '/case-study/smart-door',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },

    {
      bgImage:
        'assets/img/webp-image/case-study/7wallswallpaperimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/7wallslogocase.webp',
      headingtext: '7Walls',
      descriptiontext: 'Description',
      textdiscription:
        '7Walls specializes in providing innovative printed wall coverings, including wallpapers and canvases. They offer personalized design ideas to refresh and innovate indoor spaces in various settings, such as homes, schools, hospitals, offices, retail spaces, hotels, and celebrations. Their target customers are in the UAE, MENA countries, and all GCC regions, and they have an E-commerce platform that provides easy accessibility to their designs and simplifies the ordering process for users.',
      subtitle: 'Category',
      Categorytext: 'E-commerce',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item4',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/sportdotnetimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/sport-net-logocase.webp',
      headingtext: 'Sport-net',
      descriptiontext: 'Description',
      textdiscription:
        'Sport-net ApS was established on 27 May 2011 with the stated aim of making you better at badminton by guiding you correctly around the many badminton products available at Sport-net.dk.',
      subtitle: 'Category',
      Categorytext: 'Sports Accessories',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/callgtcindiaimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/gtclogocase.webp',
      headingtext: 'GTC Control Solutions',
      descriptiontext: 'Description',
      textdiscription:
        'Part of the globally company, GTC Control Solutions Inc., based out of New York, GTC India brings the same trusted and reliable service to the region. For over 20 Years GTC has been leading the market servicing',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/webp-image/case-study/brazelclubimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/tbclogocase.webp',
      headingtext: ' The Bezel Club',
      descriptiontext: 'Description',
      textdiscription:
        'The Bezel Club is an exclusive, watch club with the sole purpose of creating an environment for all its members to showcase their collections, buy and sell through a curated marketplace and have a like-minded',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      linktext: 'Read Full Case Study',
      link: 'case-study/the-bezel-club',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/webp-image/case-study/geohoneyimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/geohoneylogocase.webp',
      headingtext: ' Geohoney ',
      descriptiontext: 'Description',
      textdiscription:
        'Geohoney is a global brand owned by B A BARRY GROUP and is a leading global honey brand committed to delivering the highest quality of monofloral and natural honey to its customers. Geohoney is focusing on more initiatives and technological advancements to protect the environment and increase honey production for better health.They are not just a honey company, but are nature lovers and pioneers in green technologies working continuously to deploy strategic agricultural initiatives worldwide.',
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing ',
      teamtext: 'Team',
      teamstanth: '2',
      linktext: 'Read Full Case Study',
      link: '/case-study/geo-honey',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
        ],
      },
      class: 'slide-item4',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/sghiredimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/sghiredlogocase.webp',
      headingtext: ' Sg Hired ',
      descriptiontext: 'Description',
      textdiscription:
        'SG Hired, a mobile app for security agencies and officers, facilitates job creation, updates, and ratings for agencies. Security officers can filter, search, set preferences, apply to jobs, and receive payment. The admin console ensures seamless management of parties and jobs.',
      subtitle: 'Category',
      Categorytext: 'Education ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/webp-image/case-study/navabackimagescasestudy.webp',
      image: 'assets/img/webp-image/case-study/navalogocase.webp',
      headingtext: ' No-Va ',
      descriptiontext: 'Description',
      textdiscription:
        "No-Va Athleisure is a Dubai-based brand that focuses on providing comfortable and innovative athletic leisurewear. Their aim is to cater to the needs of sports enthusiasts and help them excel in their daily routines. They have launched an online ecommerce store with the motto 'Innovate it, perfect it and create the unknown'. No-Va strives to maintain a close relationship with the sports community. Their apparel is unique and innovative, designed to promote an active lifestyle.",
      subtitle: 'Category',
      Categorytext: 'Sports Accessories',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },

    {
      bgImage:
        'assets/img/webp-image/case-study/ptojectacaibackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/projectacailogocase.webp',
      headingtext: ' Project ACAI  ',
      descriptiontext: 'Description',
      textdiscription:
        "ACAI Café is a unique food chain in Singapore that offers delicious smoothie bowls filled with nutritious organic ingredients, all while avoiding unnecessary calories. Their Project Açaí is a loyalty app designed to give users an enjoyable experience with exclusive discounts and reward points from brand partners. Additionally, users can learn how to create delicious Açaí bowls at home through the app's recipes and articles",
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing ',
      teamtext: 'Team',
      teamstanth: '2',
	  linktext: 'Read Full Case Study',
      link: '/case-study/acai',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
        ],
      },
      class: 'slide-item4',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/ehmaayabackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/ehmaayalogocase.webp',
      headingtext: ' Eh-Maya ',
      descriptiontext: 'Description',
      textdiscription:
        "Eh-Maya aims to transform hair care with a range of simple and efficient products. They use gentle ingredients and powerful plant-based activities for all hair types. After 289 attempts, they have formulated a high-quality hair-care line that exceeds their standards. Eh-Maya strives to help customers reach their hair goals and embrace individuality, catering to all hair textures. 'Nourish Your Individuality' is their motto.	",
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/mysweswibackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/mysweswilogocase.webp',
      headingtext: ' MYSWESWI ',
      descriptiontext: 'Description',
      textdiscription:
        'MYSWESWI collections offer high-quality, sustainable garments that embody luxury, exquisiteness, and contemporary art. They celebrate South African heritage and create a community of fashion enthusiasts called the MYSWESWI TRIBE. Their garments are suitable for any occasion, from work to social gatherings and everyday wear. They aim to uplift underprivileged individuals by employing and upskilling them, whenever possible.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/cerrutibackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/currutilogo.webp',
      headingtext: ' Cerruti 1881 ',
      descriptiontext: 'Description',
      textdiscription:
        'Welcome to Cerruti Accessories, where Italian craftsmanship meets timeless elegance. Discover opulent leather goods and sleek eyewear, meticulously crafted to elevate your style. Embrace enduring sophistication and modern sensibility, making a statement on every occasion. With Cerruti, indulge in refined luxury and celebrate true craftsmanship. Elevate your look with our curated selection and step into a world of unparalleled quality and aesthetics.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/saverbackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/saverlogocase.webp',
      headingtext: ' Saver Grocery',
      descriptiontext: 'Description',
      textdiscription:
        "Saver Grocery is an online food grocery store that operates on a discount model and provides its customers with top-quality products at the best possible price. The brand's name,Saver,reflects its mission to help customers save money and make their lifestyles ",
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/ios.webp',
          },
          {
            image: 'assets/img/webp-image/case-study/android.webp',
          },
        ],
      },
      class: 'slide-item4',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/levantiquesbackgroundimagecasestuday.webp',
      image: 'assets/img/webp-image/case-study/levantiqueslogocase.webp',
      headingtext: ' LEVANTIQUES',
      descriptiontext: 'Description',
      textdiscription:
        'LEVANTIQUES was a workshop in Damascus, established in 1966, specializing in creating majestic, handcrafted art furniture and home decor using fine wood and seashells. Each piece is unique and reflects the passion and attention to detail of the artisans. The company has become a symbol of heritage and exclusivity for collectors and art lovers around the world. To meet growing demand, they launched an online store in 2023 and opened regional centers in Dubai and Montreal to better serve clients worldwide.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/webp-image/case-study/7wallswallpaperbackgroundimagecasestudy.webp',
      image: 'assets/img/webp-image/case-study/7wallspaperlogocase.webp',
      headingtext: ' Shakespeare',
      descriptiontext: 'Description',
      textdiscription:
        "Established in 2001 by Dr. Edward Saad in Dubai, Shakespeare, and Co. is a local café and restaurant brand that has expanded to operate 32 stores in the UAE and 8 franchised stores in the GCC. With a customer base of over 5 million annually, the brand offers an extensive menu of classic and modern cuisine, influenced by some of the world's most cherished culinary traditions, earning them a reputation for excellence in the food industry.",
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer',
      teamtext: 'Team',
      teamstanth: '4',
      linktext: 'Read Full Case Study',
      link: '/case-study/shakespeare',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.webp',
          },
        ],
      },
    },
  ];

  interior = [
    {
      bgImage:
        'assets/img/case-studies/newcase/7wallswallpaperimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/7wallslogocase.png',
      headingtext: '7Walls',
      descriptiontext: 'Description',
      textdiscription:
        '7Walls specializes in providing innovative printed wall coverings, including wallpapers and canvases. They offer personalized design ideas to refresh and innovate indoor spaces in various settings, such as homes, schools, hospitals, offices, retail spaces, hotels, and celebrations.',
      subtitle: 'Category',
      Categorytext: 'E-commerce',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/levantiquesbackgroundimagecasestuday.png',
      image: 'assets/img/case-studies/newcase/levantiqueslogocase.png',
      headingtext: ' LEVANTIQUES',
      descriptiontext: 'Description',
      textdiscription:
        'LEVANTIQUES was a workshop in Damascus, established in 1966, specializing in creating majestic, handcrafted art furniture and home decor using fine wood and seashells. Each piece is unique and reflects the passion and attention to detail of the artisans. ',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/webp-image/case-study/web.svg',
          },
        ],
      },
    },
  ];

  Retail = [
    {
      bgImage: 'assets/img/case-studies/newcase/audiimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/audi-logocasestudy.png',
      headingtext: 'Audi Collection Website',
      descriptiontext: 'Description',
      textdiscription:
        "Audi Collection's goal is to bring quality workmanship and modern design to everyday life. They launched an online store in the Middle East, offering premium items as a subtle nod to Audi enthusiasts. Audi Lifestyle's user-friendly e-shop allows hassle-free shopping and doorstep delivery of Audi Collection products, making it easy to order with a few clicks.",
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '4',
      link: 'case-studies/audi',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/case-studies/newcase/callgtcindiaimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/gtclogocase.png',
      headingtext: ' GTC Control Solutions',
      descriptiontext: 'Description',
      textdiscription:
        'Part of the globally company, GTC Control Solutions Inc., based out of New York, GTC India brings the same trusted and reliable service to the region. For over 20 Years GTC has been leading the market servicing',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',

      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/case-studies/newcase/brazelclubimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/tbclogocase.png',
      headingtext: ' The Bezel Club',
      descriptiontext: 'Description',
      textdiscription:
        'The Bezel Club is an exclusive, watch club with the sole purpose of creating an environment for all its members to showcase their collections, buy and sell through a curated marketplace and have a like-minded',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: 'case-studies/the-bezel-club',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/case-studies/newcase/geohoneyimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/geohoneylogocase.png',
      headingtext: ' Geohoney ',
      descriptiontext: 'Description',
      textdiscription:
        'Geohoney is a global pollination and green tech corporation which is a leading global supplier of raw honey offering unique varieties of honey to people worldwide.',
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '/casestudies-details',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
      class: 'slide-item4',
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/saverbackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/saverlogocase.png',
      headingtext: ' Saver Grocery',
      descriptiontext: 'Description',
      textdiscription:
        "Saver Grocery is an online food grocery store that operates on a discount model and provides its customers with top-quality products at the best possible price. The brand's name,Saver,reflects its mission to help customers save money and make their lifestyles ",
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/7wallswallpaperbackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/7wallspaperlogocase.png',
      headingtext: ' Shakespeare',
      descriptiontext: 'Description',
      textdiscription:
        "Established in 2001 by Dr. Edward Saad in Dubai, Shakespeare and Co. is a local café and restaurant brand that has expanded to operate 32 stores in the UAE and 8 franchised stores in the GCC. With a customer base of over 5 million annually, the brand offers an extensive menu of classic and modern cuisine, influenced by some of the world's most cherished culinary traditions, earning them a reputation for excellence in the food industry.",
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: 'case-studies/shakespeare',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/ehmaayabackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/ehmaayalogocase.png',
      headingtext: ' Eh-Maya ',
      descriptiontext: 'Description',
      textdiscription:
        'Eh-Maya aims to transform hair care with a range of simple and efficient products. They use gentle ingredients and powerful plant-based actives for all hair types. After 289 attempts, they have formulated a high-quality hair-care line that exceeds their standards. ',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/mysweswibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/mysweswilogocase.png',
      headingtext: ' MYSWESWI ',
      descriptiontext: 'Description',
      textdiscription:
        'MYSWESWI collections offer high-quality, sustainable garments that embody luxury, exquisiteness, and contemporary art. They celebrate South African heritage and create a community of fashion enthusiasts called the MYSWESWI TRIBE.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item4',
    },
  ];

  healthcare = [
    {
      bgImage:
        'assets/img/case-studies/newcase/ptojectacaibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/projectacailogocase.png',
      headingtext: ' Project ACAI  ',
      descriptiontext: 'Description',
      textdiscription:
        'ACAI Café is a unique food chain in Singapore that offers delicious smoothie bowls filled with nutritious organic ingredients, all while avoiding unnecessary calories. Their Project Açaí is a loyalty app designed to give users an enjoyable experience',
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing ',
      teamtext: 'Team',
      teamstanth: '2',
	  linktext: 'Read Full Case Study',
      link: 'case-studies/acai',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
      class: 'slide-item4',
    },

    {
      bgImage: 'assets/img/case-studies/newcase/geohoneyimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/geohoneylogocase.png',
      headingtext: ' Geohoney ',
      descriptiontext: 'Description',
      textdiscription:
        'Geohoney is a global pollination and green tech corporation which is a leading global supplier of raw honey offering unique varieties of honey to people worldwide.',
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
    },
    {
      bgImage: 'assets/img/case-studies/newcase/bookmypetimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/bookmypetlogocase.png',
      headingtext: 'Bookmypet App',
      descriptiontext: 'Description',
      textdiscription:
        'Bookmypet is a mobile app designed for pet owners to conveniently book various services for their pets. With this app, pet parents can access various services such as Boarding, Day Care, Grooming, training, Veterinary facilities, and more, from all over the UAE. The app offers multiple search filters such as location, customer reviews, prices, services, and ...',
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing',
      teamtext: 'Team',
      teamstanth: '3',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/saverbackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/saverlogocase.png',
      headingtext: ' Saver Grocery',
      descriptiontext: 'Description',
      textdiscription:
        "Saver Grocery is an online food grocery store that operates on a discount model and provides its customers with top-quality products at the best possible price. The brand's name,Saver,reflects its mission to help customers save money and make their lifestyles ",
      subtitle: 'Category',
      Categorytext: 'Healthcare & Wellbeing',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/ios.svg',
          },
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/android.svg',
          },
        ],
      },
      class: 'slide-item4',
    },
  ];

  tour = [
    {
      bgImage: 'assets/img/case-studies/newcase/travelhubimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/tth-logo-case.png',
      headingtext: 'The Travel Hub Website',
      descriptiontext: 'Description',
      textdiscription:
        'The Travel Hub stands for a lasting immersive travel experience that brings different cultures together. With the travel landscape changing at a fast pace, they wanted to create a common space for travelers and explorers to interact via Articles, Share Reviews, and discuss travel-related topics over forums.',
      subtitle: 'Category',
      Categorytext: 'Tour & Travel ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/case-studies/newcase/navabackimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/navalogocase.png',
      headingtext: ' No-Va ',
      descriptiontext: 'Description',
      textdiscription:
        'No-Va Athleisure is a Dubai-based brand that focuses on providing comfortable and innovative athletic leisurewear. Their aim is to cater to the needs of sports enthusiasts and help them excel in their daily routines. They have launched an online e-commerce store with',
      subtitle: 'Category',
      Categorytext: 'Sports Accessories',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/mysweswibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/mysweswilogocase.png',
      headingtext: ' MYSWESWI ',
      descriptiontext: 'Description',
      textdiscription:
        'MYSWESWI collections offer high-quality, sustainable garments that embody luxury, exquisiteness, and contemporary art. They celebrate South African heritage and create a community of fashion enthusiasts called the MYSWESWI TRIBE.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/cerrutibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/currutilogo.png',
      headingtext: ' Cerruti 1881 ',
      descriptiontext: 'Description',
      textdiscription:
        'Today, Cerruti 1881 produces an extensive line of ready-to-wear menswear that remains true to the brand’s distinctive ethos of fine fabrics, meticulous craftsmanship and intelligent design.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
  ];

  sports = [
    {
      bgImage: 'assets/img/case-studies/newcase/sportdotnetimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/sport-net-logocase.png',
      headingtext: 'Sport-net',
      descriptiontext: 'Description',
      textdiscription:
        'Sport-net ApS was established on 27 May 2011 with the stated aim of making you better at badminton by guiding you correctly around the many badminton products available at Sport-net.dk.',
      subtitle: 'Category',
      Categorytext: 'Sports Accessories',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
    {
      bgImage: 'assets/img/case-studies/newcase/navabackimagescasestudy.png',
      image: 'assets/img/case-studies/newcase/navalogocase.png',
      headingtext: ' No-Va ',
      descriptiontext: 'Description',
      textdiscription:
        'No-Va Athleisure is a Dubai-based brand that focuses on providing comfortable and innovative athletic leisurewear. Their aim is to cater to the needs of sports enthusiasts and help them excel in their daily routines. They have launched an online e-commerce store with',
      subtitle: 'Category',
      Categorytext: 'Sports Accessories',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/mysweswibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/mysweswilogocase.png',
      headingtext: ' MYSWESWI ',
      descriptiontext: 'Description',
      textdiscription:
        'MYSWESWI collections offer high-quality, sustainable garments that embody luxury, exquisiteness, and contemporary art. They celebrate South African heritage and create a community of fashion enthusiasts called the MYSWESWI TRIBE.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      link: '#',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
    },
    {
      bgImage:
        'assets/img/case-studies/newcase/cerrutibackgroundimagecasestudy.png',
      image: 'assets/img/case-studies/newcase/currutilogo.png',
      headingtext: ' Cerruti 1881 ',
      descriptiontext: 'Description',
      textdiscription:
        'Today, Cerruti 1881 produces an extensive line of ready-to-wear menswear that remains true to the brand’s distinctive ethos of fine fabrics, meticulous craftsmanship and intelligent design.',
      subtitle: 'Category',
      Categorytext: 'Retail & Consumer ',
      teamtext: 'Team',
      teamstanth: '2',
      techstack: {
        frontend: [
          {
            image: 'assets/img/casestudiesdetails_imagesbanner/web.svg',
          },
        ],
      },
      class: 'slide-item1',
    },
  ];

  // -------------------------------------------------

  currentPage = 1;
  pageSize = 12;
  totalPages: any;

  currentTab = 1;

  getPaginatedData(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.collections.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.collections.length / pageSize);
    return pageData;
  }
  getPaginatedDatainterior(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.interior.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.interior.length / pageSize);
    return pageData;
  }
  getPaginatedDataretail(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.Retail.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.Retail.length / pageSize);
    return pageData;
  }
  getPaginatedDatahealthcare(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.healthcare.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.healthcare.length / pageSize);
    return pageData;
  }
  getPaginatedDatasports(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.sports.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.sports.length / pageSize);
    return pageData;
  }
  getPaginatedDatatour(pageNumber: any, pageSize: any) {
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const pageData = this.tour.slice(startIndex, endIndex);
    this.totalPages = Math.ceil(this.tour.length / pageSize);
    return pageData;
  }
  getPageNumbers(): number[] {
    const pageNumbers: number[] = [];
    for (let i = 1; i <= this.totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  }
  changePage(page: any, el: HTMLElement) {
    this.currentPage = page;
    el.scrollIntoView({ behavior: 'smooth' });
  }

  changeTab(tab: any) {
    this.currentTab = tab;
  }
  getActiveClass(priority: any) {
    return { active: this.currentTab == priority };
  }
}
