<section class="webdev-sec2 servpage-header">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-div1">
                    <h1 class="webdev-div1-h1"><span>Ecommerce</span> Development Company</h1>
                    <p class="webdev-div1-p1">Get advantage over your competitors with premium ecommerce store that’s functionally excellent and gives you more conversion.</p>
                    <p class="webdev-div1-p2">We create highly engaging and customizable <strong>ecommerce development solutions</strong> that will help you dominate online marketplace, reach the global audience by using advanced tools & technology. We create highly engaging and customizable e- commerce development solutions that will help you dominate online marketplace, reach the global audience by using advanced technological platforms like prestashop, magento, shopify and open cart to offer précised ecommerce web development solutions for business of every size and revenue.</p>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                <div class="webdev-imgdiv1">
                    <img src="assets/img/ecommerce-development/ecom-devimg1.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="servtabs-sec" *ngIf="isDesktop">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="mvc-fixd" id="webdevelopment">
                  <section class="mvc-page-links">
                    <div class="wrapper">
                      <div class="mvc-links-main">
                        <div class="mvc-menu-list">
                          <ul>
                            <li>
                                <a tabindex="-1" class="servtab-ove-link" [ngClass]="{'active': currentTab == 1}" (click)="scroll(overview)"><i class="circle"></i><span>Overview</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-ser-link" [ngClass]="{'active': currentTab == 2}" (click)="scroll(services)"><i class="circle"></i><span>Services</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-hwd-link" [ngClass]="{'active': currentTab == 3}" (click)="scroll(how)"><i class="circle"></i><span>How we do it</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-sol-link" [ngClass]="{'active': currentTab == 4}" (click)="scroll(solutions)"><i class="circle"></i><span>Solutions</span></a>
                            </li>
                            <li>
                                <a tabindex="-1" class="servtab-ow-link" (click)="scroll(work)" [ngClass]="{'active': currentTab == 5}"><i class="circle"></i><span>Our Work</span></a>
                            </li>
                            <li>
                                <a href="javascript:;" tabindex="-1" class="servtab-rq-link" [ngClass]="{'active': currentTab == 6}" ><i class="circle"></i><span>Request Quote</span></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
            </div>
        </div>
    </div>
</section>  

<section class="servove-sec" id="overflow-slide_1" #overview>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="servove-text">  
                    <h2>We create ecommerce stores to let you unlock <span class="secondary-color">new revenue opportunities</span> and gain more!</h2>
                    <div class="servove-quote">
                        <img src="assets/img/servove-quote.svg" alt="quote">
                        <p><span>Creativity</span> Simplified</p>
                    </div>
                    <p>Being recognized as one of the <strong>best ecommerce websites development company</strong> in India and Europe has been the driving force to boost our expertise in the <strong>ecommerce web and application development</strong> domain by using the latest tools & technologies to help our clients expand their business to unrivaled heights.  Unlike traditional <strong>ecommerce development companies</strong> we not only offer a complete range of ecommerce solutions through innovative use of ecommerce platforms but also get you high returns for your investments.  Through customized ecommerce websites and shopping cart solutions that’s extremely versatile for the client’s complete requirement, our aim is to deliver you with all of the crucial services you require to be prosperous online.  We are among one of the few <strong>custom ecommerce development companies</strong> serving various clients all over the world.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="servserv-sec" id="overflow-slide_2" #services>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h3 class="servserv-head">Our Ecommerce Development Services</h3>
                    <div class="bar"></div>
                    <p>At <strong>RPA Technologies</strong>, we create custom designs that’s never seen before with perfect mix of creativity and latest technology!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/custom-ecommerce.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Custom Ecommerce Website Design</h3>                        
                        <p>Get an appealing and engaging website Design to leverage your ecommerce venture and move ahead of the rest.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/store.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Ecommerce Store Customization</h3>
                        <p>Avail reliable ecommerce web development and customization services to build solutions that match the client’s requirements.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/shopping-cart.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Shopping Cart Development</h3>
                        <p>Explore our expertise in creating high-performance, feature-rich shopping carts that drive conversions and revenue for the business.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/marketplace.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Ecommerce Marketplace Development</h3>
                        <p>We offer ecommerce marketplace development services for businesses running on multi-vendor selling models.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/module.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Plugin And Module Development</h3>
                        <p>Empower your business with high-end plugins and modules that enrich your ecommerce stores with new features and functionalities.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/responsive.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Responsive Website Development</h3>
                        <p>Build responsive websites that deliver unmatched user experiences irrespective of the device being used to access them.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/app.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Ecommerce App Development</h3>
                        <p>We provide the m-commerce advantage for businesses by rendering amazing ecommerce mobile apps for them.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/payment.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Payment Gateway Integration</h3>
                        <p>Integrate multiple payment gateways into the business stores to facilitate simple and secure online transactions.</p>
                    </div>                    
                </div>
            </div>
            <div class="col-md-4">
                <div class="servserv-box">
                    <div class="ssimg-box">
                        <img src="assets/img/ecommerce-development/services-icons/maintenance.svg" alt="image">
                    </div>
                    <div class="sstxt-box">
                        <h3>Maintenance & Support</h3>
                        <p>We provide round-the-clock maintenance and support services to ensure smooth and seamless online shopping experiences.</p>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hwdi-sec" id="overflow-slide_3" #how>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h3 class="hwdi-head">Our Ecommerce Development Process</h3>
                    <div class="bar"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="hwd-box hwd-box1">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/client-brief.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Client Briefing</h4>
                        <p>We create a detailed summary about the ecommerce website  by asking questions.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-1.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">1</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box2">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/info-analysis.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Information Analysis</h4>
                        <p>We define functionalities and elements through complete research and analysis.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-2.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">2</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box3">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/statement-of-work.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Strategies & Planning</h4>
                        <p>It’s time to choose a plan on ecommerce platforms for starting the development process.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-3.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">3</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box4">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/ui-ux.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>UX and UI design</h4>
                        <p>Refine the elements like Text Size, Color, Fonts, Theme Color, Images to make it enticing for you.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-4.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">4</div>
                </div>
            </div> 
            <div class="col-md-3">
                
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box7">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/testing.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Testing</h4>
                        <p>Finally we do testing for preventing the errors and add product value by ensuring conformity to client requirements.</p>
                    </div>
                    <div class="hwdcount">7</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box6">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/back-end.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Back-end Development</h4>
                        <p>Our developers create the skeleton behind beautiful and enticing websites with the right functionality to work well.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-6.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">6</div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="hwd-box hwd-box5">
                    <div class="hwdimg-box">
                        <img src="assets/img/ecommerce-development/process-icons/front-end.png" alt="image">
                    </div>
                    <div class="hwdtxt-box">
                        <h4>Front-end Development</h4>
                        <p>Our designing team works collaboratively for the best website appearance with less delay and easy functionalities.</p>
                    </div>
                    <div class="hwdline">
                        <img src="assets/img/web-design-development/line-5.png">
                        <span class="hwd-arrow"></span>
                    </div>
                    <div class="hwdcount">5</div>
                </div>
            </div>  

        </div>
    </div>
</section>

<section class="serv-sol-sec" id="overflow-slide_4" #solutions>
    <section class="serv-sol-pro">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">                    
                        <h5 class="serv-sol-head">Platforms We Work On</h5>
                        <div class="bar"></div>
                        <p>Since we are aware of limitations with every latest technology. We know which would be the ideal one for your business website.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="servsol-box servsol-box1">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/magento-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Magento</h5>
                            <p>
                                Developed Ecommerce websites on the latest Magento 2 version that’s equipped with advanced search filters, fully customized, integrated with payment gateway, deals,discounts and return features.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box2">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/shopify-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Shopify</h5>
                            <p>
                                With a restriction of less customization and requirement of paid API integration, we have created custom modules to reduce the development costs and latest trends in UI/UX development. 
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box3">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/opencart-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Opencart</h5>
                            <p>
                                Our team has all the expertise of creating fully customized Ecommerce websites on opencart with integration of custom modules & extensions developed by us in-accordance to our clients specific requirements.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box3">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/drupal-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Drupal</h5>
                            <p>
                                Developed fully customized websites according to clients specific needs using predefined backend, also having the expertise of integrating payment gateway and other security related modules.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box3">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/prestashop-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>Prestashop</h5>
                            <p>
                                We have successfully developed various custom modules like payment integration, shipping module and coupon code integration on Ecommerce websites that’s feature-rich and easy to customize.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="servsol-box servsol-box3">                    
                        <div class="servsol-imgbox"> 
                            <img src="assets/img/ecommerce-development/woocommerce-platform.png" alt="image">
                        </div>
                        <div class="servsol-txt">
                            <h5>WooCommerce</h5>
                            <p>
                                Successfully developed high-security websites using custom secured plugins along with reducing the page load time and addition of search feature, add to cart functionality.
                            </p>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </section>
    <section class="pro-language relative" *ngIf="isDesktop">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">                    
                        <h5 class="serv-sol-head">Our Expertise</h5>
                        <div class="bar"></div>
                        <p>Our integrated approach is designed to help organizations across the spectrum of technological needs and offer a wide range of solutions!</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-xl-7">
                    <app-platforms-scrollar></app-platforms-scrollar>
                </div>
                <div class="col-xl-5 pl-xl-5">
                    <div class="pt-5">
                        <h4 class="title wow fadeInUp pb-4 blue-secondary-color">
                            Solutions catering to all major platforms
                        </h4>
                        <h5 class="platform-h5 title wow fadeInUp pb-3 brand-color">
                            <strong>Technology Stack</strong> we use for Ecommerce Web Development
                        </h5>
                        <p class="title wow fadeInUp black font-w_400 f-20 pb-4 pt-2 line_height0">
                            Being a leading ecommerce development company, we create ecommerce websites that’s easy to deploy. We use advanced programming languages, frameworks and database platforms to address the challenges of the ever-growing IT industry. We provide you the best solutions to overcome your business challenges.
                        </p>
                        <p class="title wow fadeInUp black font-w_400 f-20 pb-4 line_height0">
                            We provide ecommerce development solutions for platforms like:<br>
                            <strong>Prestashop, Magento, Opencart, Shopify, Woocommerce, Drupal</strong>
                        </p>
                    </div>
                    <div class="pro-language-block pt-4 wow fadeInUp mb-5">
                        <div class="px-5 py-4 box-shadow-orangeshade text-center border-radius15">
                            <h2 class="green-color mx-4 pt-3 px-lg-4"><strong>20+</strong></h2>
                            <h4 class="green-color pb-3 px-lg-4"><strong>Frameworks</strong></h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> 
</section>

<section class="serv-ourwork-sec" id="overflow-slide_5" #work>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-title">                    
                    <h6 class="serv-ourwork-head">Projects completed in <span id="currentMonth"></span>&nbsp;<span id="currentYear"></span></h6>
                    <div class="bar"></div>
                    <p>Have some insights to the range of projects delivered in different tech stack by us.</p>
                </div>
            </div>
        </div>
    </div>
        <owl-carousel
         #owlElement
         [options]="OwSlideOpt"
        
         [carouselClasses]="['owl-theme', 'row', 'sliding']" class="serv-ow-slider">
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/geohoneylogo.svg" alt="technology icon" class="geohoneylogo"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Geohoney is the UAE's largest supplier of pure and organic honey across the globe. It strives to deliver only the best quality &
                                     healthy honey products at affordable prices and that too at the customer's doorstep.
                                </h4>
                                <ul class="item_list pl-40 geohoneyul">
                                    <li>Custom Cart Management Module.</li>
                                    <li>Bulk Buying Module Integration.</li>
                                    <li>Integrated 3 Payment Gateway.</li>
                                    <li>Integrated Loyalty Program Module.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/RESTfulAPI.svg" alt="technology icon" />
                                            <span class="projectlaungetext">RESTfulAPI</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                      
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/geohoneymobilefram.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/bookmypetlogo.png" alt="technology icon" class="geohoneylogo"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Drawing the idea from the influence of technology, we helped Bookmypet in automating the process of serving towards the 
                                    welfare of the pets and facilitate pet owners to find reliable services all at one place.
                                </h4>
                                <ul class="item_list pl-40 boomypetul">
                                    <li>Created search functionality.</li>
                                    <li>Created custom calendar.</li>
                                    <li>Implemented map feature.</li>
                                    <li>Created module for partial refunds.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/GraphQL.svg" alt="technology icon" />
                                            <span class="projectlaungetext">GraphQL</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/KNEX.JS.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Knex.js</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/nodejs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Node.js</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/bookmypetmobilefram.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/BMPmobilenewimage1.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/geohoneyicon/discounternewlogo1.png" alt="technology icon" class="mobileappwebsiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    We helped Discounter, deal with targeted audiences through a UI concept capable of attracting more audiences and 
                                    allowing service providers to update offers & discounts to achieve popularity.
                                </h4>
                                <ul class="item_list pl-40 discounteryul">
                                    <li>Coupon Code Redemption Feature.</li>
                                    <li>Built Custom Location Based Filter.</li>
                                    <li>Integrated Deep Link Functionality.</li>
                                    <li>Leaderboard Functionality Creation.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Swift.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Swift</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Java.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Java</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/kotlin.svg" alt="technology icon" />
                                            <span class="projectlaungetext">kotlin</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/GoogleCloud.svg" alt="technology icon" />
                                        <span class="projectlaungetext">GCloud</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/geohoneyicon/discountermobilefram.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/discounterMobileimage8.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/sghired/Logo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    SG Hired is a mobile app for security agencies to hire security officers. The project aimed at providing agency 
                                    users with the ability to create jobs, get updates for interested officers & finally rate them after job completion.
                                </h4>
                                <ul class="item_list pl-40 sghiredulli">
                                    <li>Created functionality for real-time tracking.</li>
                                    <li>Stripe payment gateway integration.</li>
                                    <li>Chat support feature for resolving disputes.</li>
                                    <li>Functionality for real time job analytics.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/Redux.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Redux</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/geohoneyicon/RESTfulAPI.svg" alt="technology icon" />
                                            <span class="projectlaungetext">RESTfulAPI</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/Laravel.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/MySQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                      
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/sghired/mobilephonebackhg.png" alt="Image" >
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/sportdotnet/Logo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    We help customers improve in sports with unique services and high-tech product guidance. We started out with 
                                    badminton and in 2016 handball was added. Offering 365-day badminton racket insurance globally.
                                </h4>
                                <ul class="item_list pl-40 sportdotnetulli">
                                    <li>Upgraded website to the Prestashop 1.7 </li>
                                    <li>Developed rating & price module.</li>
                                    <li>Implemented refine filter module.</li>
                                    <li>Implemented 360° view functionality.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/sportdotnet/PrestaShop.svg" alt="technology icon" />
                                            <span class="projectlaungetext">PrestaS..</span>
                                        </div>
                                       
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaSc..</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/sportdotnet/angular.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Angular</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Apache.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Apache</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/sportdotnet/PHP.svg" alt="technology icon" />
                                        <span class="projectlaungetext">PHP</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/sportdotnet/rightwebsiteimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/audio/newlogoaudi.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Audi Lifestyle Collection is an extraordinary product collection to help you explore the wonderful world of Audi. 
                                    The clients supply their products globally through the online store by  providing you an easy doorstep delivery.
                                </h4>
                                <ul class="item_list pl-40 audioulli">
                                    <li>Video animation for products promotion.</li>
                                    <li>Filter functionality on listing page.</li>
                                    <li>Size and color attribute selection feature.</li>
                                    <li>Customized CSV File for importing products</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/GraphQL.svg" alt="technology icon" />
                                            <span class="projectlaungetext">GraphQL</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaScript</span>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/Rails.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Rubys</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/Nginx.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Nginx</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/audio/rightimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/travelhub/tth-logo-small.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    The Travel Hub stands for a lasting immersive travel experience that brings different cultures together.
                                    The Travel Hub marked its beginning in 2020 with an aim to create a hub for passionate travellers to share their travel exp...
                                </h4>
                                <ul class="item_list pl-40 travelhubul">
                                    <li>Developed customizable article creation.</li>
                                    <li>Developed a cropping feature for images.</li>
                                    <li>Built admin analytics tool for data insights.</li>
                                    <li>Developed personalized ad feature.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/HTML.svg" alt="technology icon" />
                                            <span class="projectlaungetext">HTML</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Vue.svg" alt="technology icon" />
                                             <span class="projectlaungetext">Vue</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Jquery.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Jquery</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Laravel.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Laravel</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/Apache.svg" alt="technology icon" />
                                         <span class="projectlaungetext">Apache</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/travelhub/PostgreSQL.svg" alt="technology icon" />
                                        <span class="projectlaungetext">PostgreSQL</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/travelhub/rightwebsite.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/7Walls/Asset7wallnewlogo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    7Walls Wallpaper Trading LLC is founded to provide a variety of innovative printed wall coverings on a diverse range which 
                                    includes wallpapers and canvases with a sustainable eco-friendly approach.
                                </h4>
                                <ul class="item_list pl-40 wallsulli">
                                    <li>Integrated tabby payment gateway for EMI.</li>
                                    <li>custom design for desktop and mobile.</li>
                                    <li>Customized whole site in Arabic language.</li>
                                    <li>Created module for generating quotation.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/HTML.svg" alt="technology icon" />
                                            <span class="projectlaungetext">HTML</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/7Walls/CSS.svg" alt="technology icon" />
                                             <span class="projectlaungetext">CSS</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/travelhub/Jquery.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Jquery</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/Wordpress.svg" alt="technology icon" />
                                         <span class="projectlaungetext">WP</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/7Walls/Nginx.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Nginx</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/7Walls/right7wallimages.jpg" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="portfolio-item">
                    <div class="portfolio-block rita_block">
                        <div class="card_wrapper">
                            <div class="content">
                                <div class="heading pl-40 logobox">
                                    <img src="assets/img/web-design-development/callgtc/CallGTClogo.png" alt="technology icon" class="websiteloghome"/>
                                </div>
                                <h4 class="sub-heading pl-40">
                                    Call GTC India, part of GTC Control Solutions Inc., offers trusted and reliable GE Speedronic Turbine and Excitation controls 
                                    servicing for 20+ years and now provides expertise beyond General Electric.
                                </h4>
                                <ul class="item_list pl-40 callgtculli">
                                    <li>Data security using encryption techniques.</li>
                                    <li>Created CSV import functionality.</li>
                                    <li>Created custom search functionality.</li>
                                    <li>Dynamically managed tags & meta titles.</li>
                                </ul>
                                <h2 class="heading pl-40 geohoneyheadingtextpadding">
                                   Tech Stack Used :
                                </h2>
                                <div class="row techstack">
                                    <div class="col-lg-6 col-md-6 border-righthome">
                                        <h2 class="frantendtextheading">
                                            Front-End :
                                         </h2>
                                        <div class="port-techdiv1">
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/ReactNative.svg" alt="technology icon" />
                                            <span class="projectlaungetext">React</span>
                                        </div>
                                       
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/audio/JavaScript.svg" alt="technology icon" />
                                             <span class="projectlaungetext">JavaSc..</span>
                                        </div>
                                        <div class="portico-div">
                                            <img src="assets/img/web-design-development/callgtc/Nextjs.svg" alt="technology icon" />
                                            <span class="projectlaungetext">Next</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 boxdesignportfolio">
                                    <h2 class="frantendtextheading ">
                                        Back-End :
                                     </h2>
                                    <div class="port-techdiv1">
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/audio/MySQl.svg" alt="technology icon" />
                                         <span class="projectlaungetext">MySQL</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/geohoneyicon/nodejs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Node.js</span>
                                    </div>
                                    <div class="portico-div">
                                        <img src="assets/img/web-design-development/callgtc/Expressjs.svg" alt="technology icon" />
                                        <span class="projectlaungetext">Express</span>
                                    </div>
                                </div>
                            </div>
                                </div> 
                            </div>
                            <div class="content_image screenshot">
                                <div class="image_wrapper">
                                    <img src="assets/img/web-design-development/callgtc/rightwebimage.png" alt="Image">
                                    <!-- <img src="assets/img/web-design-development/geohoneyicon/GohoneyMobileImage.svg " alt="Image" class="desktop-slider-none"> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </owl-carousel> 
        <div class="carousel-navigation col-lg-12 col-md-12 col-sm-12">
            <div class="nav-left">
                <div class="item-number">
                    <span id="showcount">1/9</span>
                </div>
                <div class="nav-buttons owl-nav">
                    <!-- <img class="nav-btn previous-btn" src="assets/img/ic-ar-circle-left-new.svg" alt="Previous" id="previous-btn" (click)="owlprevslide()">
                    <img id="next-btn" class="nav-btn next-btn" src="assets/img/ic-ar-circle-right-new.svg" alt="Next" (click)="owlnextslide()"> -->
                </div>
            </div>
            <div class="right-nav">
                <a routerLink="/portfolio" class="card-link">
                    <span class="mobile-solid-blue-btn mobile-solid-btn">VIEW MORE PROJECTS 
                        <img class="arrow-right display-mobile-none" src="assets/img/ic-long-arrow-new.svg" alt="View
                        All
                        Projects">
                    </span>
                </a>
    
            </div>
        </div>
</section>




