<app-servicebanner
  heading="Secure and Scalable Web Hosting Services for Your Business"
  paragraph="Pro Web's elite web hosting solution is designed for an enhanced online experience. Get your website hosted with our reliable services, high performance, and dedicated support."
  bannerimg="assets/img/servicesdev/webdesignbg.webp"
  mobileImg="web_hosting_mob.webp"
  [cards]="logo"
  [cards2]="logo2"
  [tagline]="taglinearr"
></app-servicebanner>

<!-- breadcrumbs -->

<nav aria-label="breadcrumb" class="ps-5 pt-3 pt-md-0 d-none d-md-block">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href="/">Services</a></li>
    <li class="breadcrumb-item active" aria-current="page">Web Hosting</li>
  </ol>
</nav>

<app-technology-stack
  firsttitle="Exceptional Web Hosting"
  semititle="Solutions for Digital Success"
  description="Pro Web, based in Dubai, is your go-to option for top-notch web hosting services. We excel in seamlessly blending affordability with unmatched reliability, driven by cutting-edge technology. Specializing in creating visually stunning and robust hosting solutions, we prioritize delivering a smooth user experience. Trust us for budget-friendly and trustworthy web hosting services in Dubai, designed to elevate your online presence. With a transparent approach and market-savvy strategies, we ensure prime digital positioning, making your online success our top priority in web hosting services.
    "
  [cards]="cardsItems"
></app-technology-stack>

<section class="gradientwrap">
  <div>
    <div
      class="d-none d-md-flex justify-content-between align-items-center py-3 px-5"
    >
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img src="assets/img/servicesdev/grad-one.png" alt="" srcset="" />
          <p class="text-white ps-2">Host In Dubai, UAE Data Center</p>
        </div>
      </div>
      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img src="assets/img/servicesdev/grad-two.png" alt="" srcset="" />
          <p class="text-white ps-2">20x Faster Speed</p>
        </div>
      </div>

      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>

      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img src="assets/img/servicesdev/grad-three.png" alt="" srcset="" />
          <p class="text-white ps-2">Dedicated Support</p>
        </div>
      </div>

      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img
            src="assets/img/servicesdev/firewall-icon.png"
            alt=""
            srcset=""
          />
          <p class="text-white ps-2">Firewall Protection</p>
        </div>
      </div>
      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img src="assets/img/servicesdev/country-data.png" alt="" srcset="" />
          <p class="text-white ps-2">In Country Data Compliance</p>
        </div>
      </div>
    </div>
    <div
      class="d-block d-md-none justify-content-center align-items-center py-3"
    >
      <ngx-slick-carousel
        class="carousel owl-carousel branflex"
        [config]="carouselConfig2"
      >
        <div
          class="contentIcon"
          ngxSlickItem
          *ngFor="let item of iconsdata"
          class="slider-inlinefixe"
        >
          <div class="text-center">
            <img src="{{ item.icon }}" class="m-auto" alt="icon" srcset="" />

            <p class="text-white my-2" style="font-size: 12px">
              {{ item.name }}
            </p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<app-dedicated-hosting
  heading="Why Online Businesses"
  subheading="Pro Web for Dedicated Hosting?"
  [data]="dataItems"
></app-dedicated-hosting>

<app-testimonial-service
  profile="user.svg"
  clientname="Nanette Miessner"
  designation="Founder and General Manager - Life Smoothies"
  location="United
Arab Emirates"
  flag="uk-flag.svg"
  clientcomment=" We’ve been working with Rohit since the start of our company in 2016, He is always willing to help and
go out of his way for his clients, thank you for keeping our company connected always!!"
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data"
  [clientmobile]="datamobile"
  clienttarget="life-smoothy.png"
></app-testimonial-service>

<!-- <app-dedicated-map></app-dedicated-map> -->

<!-- first - How a CDN works -->
<section class="my-5">
  <div class="container cdn-word">
    <div class="row align-items-center">
      <div class="col-md-6">
        <div class="cdnwrap">
          <img
            src="../../assets/img/servicesdev/cloud-hosting/cdn_two.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="cdn-content">
          <h3 class="mb-3">How a CDN works</h3>
          <p class="content-para">
            A Content Delivery Network (CDN) works to enhance website loading
            speed for users worldwide.
          </p>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                It achieves this by strategically caching website copies on
                servers located at various points around the globe, known as
                Points of Presence (POPs). For instance, if your website is
                hosted in a US data center and a user accesses it from the UK, a
                cached copy on a nearby POP reduces delays.
              </p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                By storing multiple copies of your website's data across
                different POPs globally, the CDN ensures quicker loading times
                for users, regardless of their location. This optimization
                facilitates smoother navigation for your site visitors,
                enhancing their overall experience.
              </p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                Beyond user experience, there are additional benefits to using
                the CDN offered by Pro Web. Notably, search engines prioritize
                fast-loading websites, thus implementing a CDN can boost your
                site's SEO performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- second - How a CDN works -->
<section class="my-5">
  <div class="container cdn-word">
    <div class="row align-items-center">
      <div class="col-md-6 d-block d-md-none">
        <div class="cdnwrap">
          <img
            src="../../assets/img/servicesdev/cloud-hosting/deployment.png"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="cdn-content">
          <h3 class="mb-3">Free CDN Deployment</h3>
          <p class="content-para">
            For businesses or side hustles aiming for an international presence,
            leveraging a CDN is paramount. Our Pro Web CDN, akin to premium
            offerings like Cloudflare, provides cutting-edge edge caching
            features essential for global reach.
          </p>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                While simplicity is key with our one-click activation, we
                empower advanced users with development mode and dynamic cache
                settings, granting them the freedom to explore and customize.
              </p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                Need to make instant updates? A single click allows you to purge
                the cache. Plus, hassle-free blocking of unwanted visitors by IP
                address or country adds an extra layer of security.
              </p>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-2 col-md-1">
              <img
                src="../../assets/img/servicesdev/cloud-hosting/check-icon.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="col-10 col-md-11">
              <p>
                Stay ahead of the curve with our comprehensive CDN statistics.
                Gain insights into Pro Web CDN activity through intuitive
                charts, empowering you to make data-driven decisions, track
                traffic trends, and swiftly address any issues post-site
                changes.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 d-none d-md-block">
        <div class="cdnwrap">
          <img
            src="../../assets/img/servicesdev/cloud-hosting/deployment.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- global reach -->
<section class="spacing">
  <div class="map-padding">
    <div class="d-flex flex-column justify-content-center processWrap mb-2">
      <h2 class="my-2">A Free CDN <span>with a Global Reach</span></h2>
      <p>
        Our global data centres and CDN nodes deliver lightning-fast speeds
        wherever your traffic is coming from.
      </p>
    </div>

    <div class="container my-5">
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col" *ngFor="let item of cardsdata">
          <div class="card">
            <img
              src="../../assets/img/servicesdev/{{ item.cardimg }}"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title fw-bold">{{ item.cardheading }}</h5>
              <p class="card-text">
                {{ item.cardspara }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- table -->
<section class="container mb-5">
  <table class="table">
    <thead>
      <tr>
        <th scope="col" class="featurehead">Features</th>
        <th
          scope="col"
          class="d-flex justify-content-center align-items-center border-0"
        >
          <img
            src="../../assets/img/servicesdev/web-hosting/cloudflare.png"
            width="280px"
            alt=""
            srcset=""
          />
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Cost</td>
        <td>FREE</td>
      </tr>
      <tr>
        <td>Global Content Delivery Network</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>DDoS Protection</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Free Dedicated SSL Certificates</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Web Application Firewall (WAF)</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Image & Mobile Optimization</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>HTTP/2</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Unlimited Usage</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Load Balancing</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>Activation Time</td>

        <td>Instant</td>
      </tr>
      <tr>
        <td>Unlimited Blocking By Country</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
      <tr>
        <td>PCI Compliance</td>

        <td>
          <i
            class="fa fa-check text-success"
            style="font-size: 20px"
            aria-hidden="true"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</section>

<app-why-choose-proweb
  heading="Why Choose Pro Web For Web Hosting Services?"
  desc="We excel in Web Hosting, delivering top-tier solutions with expertise and reliability."
  [obj]="cards"
></app-why-choose-proweb>

<app-check-out
  checkoutImg="../../assets/img/servicesdev/checkout-webhosting.png"
  alt="check out"
  checkoutText="Check Out Our"
  checkoutName="Web Hosting Packages"
  checkoutUrl="/web-hosting-packages"
></app-check-out>

<app-bottom-bar
  text="Ready To Go Live With Our Dedicated Servers?"
></app-bottom-bar>

<section class="spacing">
  <div class="container">
    <div class="row justify-content-center">
      <h2>Frequently <span>Asked Questions</span></h2>
      <p class="my-3" style="padding: 0 35px">
        Find the answers you need to know more about web hosting
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 5); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span>-->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(5); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 5)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
