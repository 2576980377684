import { Component, OnInit, HostListener } from '@angular/core';

// import Jquery
import * as $ from 'jquery';

@Component({
  selector: 'app-platforms-scrollar',
  templateUrl: './platforms-scrollar.component.html',
  styleUrls: ['./platforms-scrollar.component.css']
})
export class PlatformsScrollarComponent implements OnInit {

	isMobile = true;
	isDesktop = true;

	@HostListener("window:resize", [])
	  onResize() {
	    var width = window.innerWidth;
	    if (width < 768) {
	      this.isMobile = true;
	      this.isDesktop = false;
	    } else {
	      this.isMobile = false;
	      this.isDesktop = true;
	    }
	}

  constructor() { }

  ngOnInit() {

  	/* Generate code according to resolution open here */

      if (window.screen.width < 768) {
        this.isMobile = true;
        this.isDesktop = false;
      }
      else{
        this.isMobile = false;
        this.isDesktop = true;
      }

    /* Generate code according to resolution close here */

    $(document).ready(function(){

    	/* Platform icons vertical scroll script open */

          verticalSmoothscroll();

          function verticalSmoothscroll() {
            var v_parent = $('.verticalsmoothSlider').attr('id');
            var v_inner = $('#' + v_parent).find('.verticalsliderInner');
            var v_item = $('#' + v_parent).find('.verticalslideItem');

              console.log($(v_item).length);
              var runvslider = setInterval(verticalScroll, 50);
              $(v_item).find('.contentTofade').mouseover(function() { clearInterval(runvslider) });
              $(v_item).find('.contentTofade').mouseleave(function() { runvslider = setInterval(verticalScroll, 50) });
              var i = 0;
              $('#' + v_parent).find('.verticalslideItem').clone().addClass('vcloned').insertAfter('.verticalslideItem:last');

              function verticalScroll() {
                  // if (i == $(v_item).height() * $(v_item).length) {
                  //     $('#' + v_parent).find('.vcloned').remove();
                  //     i = 0;
                  //     $('#' + v_parent).find('.verticalslideItem').clone().addClass('vcloned').insertAfter('.verticalslideItem:last');
                  // }
                  i++;
                  $(v_inner).css('transform', 'translateY(-' + i + 'px)')
              }

          }

        /* Platform icons vertical scroll script close */

    });

  }

}
