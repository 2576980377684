import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-smm-service',
  templateUrl: './smm-service.component.html',
  styleUrls: ['./smm-service.component.css']
})
export class SmmServiceComponent {

  constructor(private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    // Meta Title , Description , keywords
    this.titleService.setTitle('Social Media Marketing Agency in Dubai | Pro Web');
    this.metaTagService.updateTag({
      name: 'description',
      content: `Amplify your brand's digital reach with Pro Web's expert Social Media Marketing services in Dubai. From strategy development to content creation and management, we help you engage your audience and drive results. Transform your social media today.`,
    });
    this.metaTagService.updateTag({
      name: 'keywords',
      content:
        'social media marketing, social media marketing agency, social media marketing company, social media marketing services, social media marketing company in dubai',
    });
  }

  logoarr = ['/assets/img/packages/uae-driving-logo.svg', '/assets/img/packages/carry-tune-logo.svg', '/assets/img/packages/avea-logo.svg', '/assets/img/packages/6skins-logo.svg'];
  logoarr2 = ['/assets/img/packages/uae-driving-logo.svg', '/assets/img/packages/carry-tune-logo.svg', '/assets/img/packages/avea-logo.svg'];

  cardsItems = [
    {
      title: 'Strategic social media advertising',
      desc: 'Strategic social media advertising can elevate your brand by creating impactful campaigns that captivate your audience and deliver measurable results. At Pro Web, we specialize in crafting tailored strategies that enhance your online presence and drive engagement. Our team designs compelling content, utilizes precise targeting, and continuously optimizes campaigns to ensure maximum ROI. Partner with us to transform your social media efforts into powerful advertising tools that foster growth, increase conversions, and boost brand recognition across all major platforms.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Paid social media management',
      desc: `Precision is key to elevating your brand, and our paid social media management excels in delivering results. At Pro Web, we boost visibility, engagement, and conversions with targeted, meticulously crafted campaigns. Our team analyzes audience behavior, creates compelling content, and optimizes ads across major platforms to make your brand stand out. Maximize your social media ROI and achieve significant growth with our expertise. Trust us to manage your paid social media efforts and transform your online presence into a powerful driver of success.`,
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Comprehensive digital marketing',
      desc: 'Pro Web’s comprehensive digital marketing solutions are designed to elevate your brand. We provide tailored strategies, deliver measurable results, and bring unparalleled experience to drive your success. Our integrated approach enhances your online presence across various channels, boosting engagement and achieving your business goals. With Pro Web, expect a strategic blend of tactics that are customized to your needs, ensuring impactful and effective digital marketing that propels your brand forward. Partner with us to unlock growth and maximize your digital impact.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Small business social media marketing',
      desc: 'Increase your small business’s social media impact with Pro Web. We enhance visibility, engage your audience, and drive growth with tailored strategies designed to maximize results. Our expert team creates compelling content and targeted campaigns to help you stand out in a crowded market. By leveraging the latest trends and tools, we ensure your social media efforts are effective and aligned with your business goals. Partner with us to elevate your social media presence, achieve significant growth, and build lasting connections with your audience.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Engagement and community building',
      desc: 'Foster meaningful connections and build a thriving community with Pro Web. We specialize in engagement techniques that strengthen relationships and enhance brand loyalty. Our approach focuses on creating authentic interactions and nurturing your audience to drive deeper connections and lasting engagement. By implementing strategic practices, we help you establish a loyal community that supports and advocates for your brand. Partner with us to elevate your engagement efforts and build a vibrant, engaged audience that drives sustainable, long-term success.',
      img: 'assets/img/servicesdev/globe.webp',
    },
    {
      title: 'Data-driven analytics',
      desc: 'Our data-driven analytics empower you to make informed decisions and optimize your strategy effectively. At Pro Web, we provide valuable insights that significantly enhance the effectiveness of your campaigns. By analyzing key metrics and trends, we help you identify opportunities for improvement and refine your approach strategically. Leverage our expertise to boost your campaign performance and achieve outstanding results.  With our comprehensive analytics, you’ll gain the clarity needed to drive impactful decisions and elevate your overall marketing effectiveness.',
      img: 'assets/img/servicesdev/globe.webp',
    },
  ];

  iconsdata = [
    {
      icon: 'assets/img/servicesdev/package-service/grad-one.svg',
      name: 'Ad Campaigns',
    },
    { icon: 'assets/img/servicesdev/package-service/grad-two.svg', name: 'Click-Through Rate' },
    {
      icon: 'assets/img/servicesdev/package-service/grad-three.svg',
      name: 'ROI Maximization',
    },
    {
      icon: 'assets/img/servicesdev/package-service/grad-four.svg',
      name: 'Lead Generation',
    },
    {
      icon: 'assets/img/servicesdev/package-service/grad-five.svg',
      name: 'Keyword Bidding',
    },
  ];


  dataItems = [
    {
      heading: 'Market & Audience Research',
      description:
        'Gain a competitive edge with Pro Web’s comprehensive market and audience research.We analyze market trends and audience behavior to provide actionable insights. Our thorough research helps you understand your target audience, identify opportunities, and craft strategies that drive success and growth.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Platform Selection',
      description:
        'Choose the right platforms with Pro Web’s strategic platform selection. We analyze your business goals and audience preferences to recommend the most effective platforms for your campaigns. Our tailored approach ensures you reach your target audience where they are most active, maximizing impact and engagement.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Content Strategy',
      description:
        'Develop a powerful content strategy with Pro Web. We craft tailored content plans that align with your brand’s goals and audience needs. Our approach focuses on creating engaging, high-quality content that drives traffic, boosts engagement, and enhances your brand’s online presence and visibility.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Engagement and Community Building',
      description:
        'Build strong connections and a thriving community with Pro Web. We specialize in engagement strategies that foster meaningful interactions and enhance brand loyalty. Our approach creates vibrant communities that support and advocate for your brand, driving sustained growth and engagement.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Influencer Collaborations',
      description:
        'Leverage the power of influencer collaborations with Pro Web. We connect your brand with influential voices to enhance credibility and expand reach. Our targeted approach ensures effective partnerships that amplify your message, engage your audience, and drive impactful, meaningful results.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Awareness Creation',
      description: 'Boost your brand’s visibility with Pro Web’s awareness creation strategies. We craft targeted campaigns to increase brand recognition and reach your ideal audience. Our approach combines creative tactics and data-driven insights to effectively elevate your brand’s presence and impact.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Ad Campaigns',
      description:
        `Optimize your marketing efforts with Pro Web’s expert ad campaigns. We design and manage targeted campaigns that drive results, boost visibility, and maximize ROI. Our data-driven approach ensures your ads reach the right audience and achieve your business goals efficiently.`,
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Monitoring and Analytics',
      description:
        'Enhance your strategy with Pro Web’s monitoring and analytics services. We track key metrics and analyze performance to provide actionable insights. Our data-driven approach helps you understand campaign effectiveness, make informed decisions, and continuously optimize for better results.',
      bgimg: 'hosting-card.png',
    },
    {
      heading: 'Cultural Sensitivity and Compliance',
      description:
        `Ensure your brand respects diverse audiences with Pro Web’s cultural sensitivity and compliance services. We help you navigate cultural nuances and adhere to legal standards, crafting campaigns that are inclusive and effective. Our expertise ensures your messaging resonates respectfully and aligns with global regulations.`,
      bgimg: 'hosting-card.png',
    },
  ];


  data = [
    'levantiques-logo.webp',
    '6skins-logo.webp',
    'landmark-logo.png',
    'whites-logo-ppc.webp',
    'sea7-logo.webp',
  ];

  datamobile = [
    'levantiques-logo.webp',
    '6skins-logo.webp',
    'whites-logo-ppc.webp',
    'sea7-logo.webp',
  ];



  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1000,
    arrows: true,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  carouselConfig2 = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 7000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  faq = [
    {
      title: 'What is Social Media Management?',
      des: '<p> Social Media Management refers to the process of creating, scheduling, analyzing, and engaging with content across various social media platforms, such as Facebook, Instagram, Twitter, LinkedIn, Pinterest, and YouTube. At Pro Web, we handle everything from content creation to interacting with your audience to ensure your brand stays relevant and engaging online. This includes monitoring trends, managing comments, and ensuring your brand voice remains consistent across all channels.</p>',
      isActive: false,
    },
    {
      title:
        'Why is Social Media Management important for my business?',
      des: `<p>A strong social media presence is crucial for business success in today's digital-first world. Social Media Management helps increase brand visibility, builds trust with your audience, and encourages direct communication with potential customers. Businesses can boost website traffic, generate leads, and drive conversions through consistent posting and engagement. Additionally, well-managed social media accounts help improve customer service by providing an accessible way for clients to interact with your brand. Without effective social media management, you risk missing valuable growth opportunities.</p>`,
      isActive: false,
    },
    {
      title: 'Which platforms do you manage?',
      des: "<p> We manage a variety of social media platforms, including Facebook, Instagram, Twitter, LinkedIn, Pinterest, and YouTube. Depending on your business’s target audience and industry, our services are available on the platforms where your audience is most active. For example, if you’re a B2B company, we may prioritize LinkedIn and Twitter. For lifestyle or consumer brands, we focus more on visual platforms like Instagram and Pinterest. We also stay up to date with new and emerging platforms to ensure your brand remains competitive.</p>",
      isActive: false,
    },
    {
      title: 'Can I request customized content for my social media accounts?',
      des: '<p>Absolutely! We believe that every brand is unique, and that’s why we create customized content that aligns with your business’s voice, goals, and audience preferences. We collaborate closely with you to understand your brand identity, core values, and messaging. Based on this information, we create personalized posts, including eye-catching graphics, videos, captions, and hashtags, to increase engagement. Whether you’re launching a new product or running a seasonal campaign, we’ll ensure your social media content reflects your goals.</p>',
      isActive: false,
    },
    {
      title:
        'How often will you post on my social media accounts?',
      des: "<p> The frequency of posts depends on your business’s specific needs and the platform. Typically, for platforms like Instagram and Facebook, we recommend posting 3–5 times weekly to maintain consistent visibility without overwhelming your audience. More frequent posting may be necessary on platforms like Twitter, where content has a shorter lifespan. We’ll develop a content calendar for your brand and work closely with you to ensure the schedule fits your marketing strategy.</p>",
      isActive: false,
    },
    {
      title: 'Do you provide analytics and reports?',
      des: '<p>Yes, data-driven insights are a key part of our social media management services. We provide regular, detailed reports that track key performance indicators (KPIs) such as follower growth, engagement rates, click-through rates, and overall audience behavior. Our reports help you understand how your social media efforts are impacting your business and provide actionable insights for ongoing improvement. We use these insights to continuously refine your strategy, ensuring your social media presence stays dynamic and effective.</p>',
      isActive: false,
    },
    {
      title:
        ' How do you create content for my brand?',
      des: '<p> Our content creation process begins with an in-depth consultation to understand your brand’s goals, audience, and messaging. We conduct research on your industry and competitors to develop a content strategy that resonates with your target audience. From there, our team of creative professionals designs posts, including visuals (images, graphics, videos), copywriting, and hashtags to engage your audience. We use the latest tools and trends to ensure that your content is both relevant and visually appealing, keeping your brand at the forefront of social media conversations.</p>',
      isActive: false,
    },
    {
      title: `Can you help with paid social media advertising?`,
      des: '<p>Yes, we offer comprehensive social media advertising services to help boost your reach and engagement. Our team specializes in designing and managing paid social campaigns across platforms like Facebook, Instagram, and LinkedIn. We assist with targeting the right audience based on demographics, interests, and behavior, creating ad creatives that capture attention, and optimizing your budget to get the best possible results. Whether you’re looking to increase brand awareness, drive sales, or generate leads, our social media advertising services will help you achieve your business objectives.</p>',
      isActive: false,
    },
    {
      title:
        'What if I want to approve content before it’s posted?',
      des: '<p> We value transparency and collaboration, so we give you full control over the content we create. Before any post goes live, you will have the opportunity to review and approve the content. This ensures that everything aligns with your brand’s vision and messaging. We typically work with you to create a content calendar where you can preview posts in advance. This level of collaboration ensures that you’re always comfortable with what’s being published, giving you peace of mind./p>',
      isActive: false,
    },
    {
      title: `How do I get started with your social media management services?`,
      des: '<p>Getting started with our social media management services is easy. Simply reach out to us through our contact form or schedule a consultation. During the consultation, we’ll discuss your business goals, target audience, and current social media presence. Based on this discussion, we’ll create a customized strategy that fits your needs. Once we agree on the plan, our team will begin managing your accounts, creating content, and delivering reports to help you achieve your social media goals.</p>',
      isActive: false,
    },
  ];

  toggleAccordian(event: any, index: any) {
    this.faq.forEach((item, i) => {
      if (i !== index) {
        item.isActive = false;
      }
    });

    // Toggle the clicked item
    this.faq[index].isActive = !this.faq[index].isActive;
  }




}
