<div class="repair-main-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="repair-banner-content">
                    <h1>Your Local Computer Repair Experts!</h1>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                    <a href="#" class="btn btn-primary">Get Started</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="repair-banner-image">
                    <img src="assets/img/repair-banner-image/repair-banner-img.png" alt="image">
                    <img src="assets/img/repair-banner-image/1.png" class="wow zoomIn" data-wow-delay="0.6s" alt="image">
                    <img src="assets/img/repair-banner-image/2.png" class="wow fadeInLeft" data-wow-delay="0.6s" alt="image">
                    <img src="assets/img/repair-banner-image/circle.png" class="rotateme" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>



<section class="repair-about-area ptb-80">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="repair-about-content">
                    <span class="sub-title">About Us</span>
                    <h2>We have 35 years of experience in repair services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <ul>
                        <li><span>Experienced Professionals</span></li>
                        <li><span>Expert Technical Skills</span></li>
                        <li><span>Excellent Reputation</span></li>
                        <li><span>Friendly Service</span></li>
                        <li><span>Trustworthy See Reviews</span></li>
                        <li><span>Affordable Diagnosis</span></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="repair-about-image">
                    <img src="assets/img/about1.jpg" class="wow fadeInDown" alt="image">
                    <img src="assets/img/about2.jpg" class="wow zoomIn" alt="image">
                    <img src="assets/img/about3.jpg" class="wow fadeInUp" alt="image">
                    <img src="assets/img/about-shape1.png" alt="image">
                    <img src="assets/img/about-shape2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="repair-services-area ptb-80 bg-f7fafd">
    <div class="container">
        <div class="section-title">
            <h2>Our Featured Services that We Provide</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg1">
                    <div class="icon">
                        <i class="flaticon-monitor"></i>
                    </div>

                    <h3>Laptop Repair</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg2">
                    <div class="icon">
                        <i class="flaticon-idea"></i>
                    </div>

                    <h3>Computer Repair</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg3">
                    <div class="icon">
                        <i class="flaticon-layout"></i>
                    </div>

                    <h3>Apple Products Repair</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg4">
                    <div class="icon">
                        <i class="flaticon-update-arrows"></i>
                    </div>

                    <h3>Software Update</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg5">
                    <div class="icon">
                        <i class="flaticon-smartphone"></i>
                    </div>

                    <h3>Smartphone Repair</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-services bg6">
                    <div class="icon">
                        <i class="flaticon-hard-disk"></i>
                    </div>

                    <h3>Data Backup & Recovery</h3>
                    <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="repair-cta-area bg-0f054b">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="cta-repair-content">
                    <h3>We'll help you get back to work</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#" class="btn btn-primary">Contact Us</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="cta-repair-img">
                    <img src="assets/img/man-and-women.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="circle-box"><img src="assets/img/circle.png" alt="image"></div>
    <div class="cta-shape"><img src="assets/img/cta-shape.png" alt="image"></div>
</section>



<section class="team-area repair-team-area pt-80 pb-50 bg-f9f6f6">
    <div class="container">
        <div class="section-title">
            <h2>Our Awesome Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/repair-team-image/1.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Josh Buttler</h3>
                            <span>CEO & Founder</span>
                        </div>
                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/repair-team-image/2.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Alex Maxwel</h3>
                            <span>Marketing Manager</span>
                        </div>
                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/repair-team-image/3.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Janny Cotller</h3>
                            <span>Web Developer</span>
                        </div>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/repair-team-image/4.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Jason Statham</h3>
                            <span>UX/UI Designer</span>
                        </div>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="repair-why-choose-us pt-80">
    <div class="container">
        <div class="section-title">
            <h2>Why We Are Best From Others</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-repair-box">
                    <div class="icon">
                        <i class="flaticon-handshake"></i>
                    </div>
                    <h3>Quick Repair Process</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <div class="back-icon">
                        <i class="flaticon-handshake"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-repair-box">
                    <div class="icon">
                        <i class="flaticon-repair"></i>
                    </div>
                    <h3>Free Diagnostics</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class="flaticon-repair"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-repair-box">
                    <div class="icon">
                        <i class="flaticon-delivery-truck"></i>
                    </div>
                    <h3>Fast Delivery</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    
                    <div class="back-icon">
                        <i class="flaticon-delivery-truck"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-80">
    <div class="container">
        <div class="section-title">
            <h2>The News from Our Blog</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/1.jpg" alt="image">
                        </a>

                        <div class="date">
                           March 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">The security risks of changing package owners</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/2.jpg" alt="image">
                        </a>

                        <div class="date">
                            March 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/3.jpg" alt="image">
                        </a>

                        <div class="date">
                            March 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
