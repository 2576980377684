<section class="contp-sec1">
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <div class="contp-txtdiv">
                    <h2 class="contp-txt1" id="contp-typed">Hey</h2>
                    <p class="contp-txt2">Pro Web is ready to outline your amazing growth story, get in touch now!</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="cont-rotimgdiv">
                    <img width="452" height="476" class="cont-rotimg1 entered lazyloaded"
                        src="assets/img/rotating-text.png" alt="Proweb rotating tagline"
                        data-lazy-src="assets/img/rotating-text.png" data-ll-status="loaded">
                    <img width="254" height="251" class="cont-rotimg2 entered lazyloaded" src="assets/img/pwt-globe.png"
                        alt="Proweb globe icon" data-lazy-src="assets/img/pwt-globe.png" data-ll-status="loaded">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contp-sec2">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="contp-formbox">
                    <div role="form" class="wpcf7" id="wpcf7-f27120-o6" lang="en-US" dir="ltr">
                        <div class="screen-reader-response"></div>
                        <app-alert></app-alert>
                        <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
                        <form action="" method="POST" class="wpcf7-form" novalidate="novalidate"
                            [formGroup]="contactForm" (ngSubmit)="onSubmit($event)" #contactFormNew="ngForm"
                            id="contactForm">
                            <div class="contp-form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="">What's your name?</label><br />
                                            <span class="wpcf7-form-control-wrap text-427">
                                                <input type="text" name="name" value="" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required conatct_form_white"
                                                    aria-required="true" aria-invalid="false" placeholder="Name*"
                                                    formControlName="name"
                                                    [ngClass]="{ 'is-invalid': submitted && fn.name.errors }" />

                                                <div *ngIf="submitted && fn.name.errors" class="invalid-feedback">
                                                    <div *ngIf="fn.name.errors.required">Please, enter your name</div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <label for="">What's your email address?</label><br />
                                            <span class="wpcf7-form-control-wrap email-97">
                                                <input type="email" name="email" value="" size="40"
                                                    class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email conatct_form_white"
                                                    aria-required="true" aria-invalid="false" placeholder="Email*"
                                                    formControlName="email"
                                                    [ngClass]="{ 'is-invalid': submitted && fn.email.errors }" />

                                                <div *ngIf="submitted && fn.email.errors" class="invalid-feedback">
                                                    <div *ngIf="fn.email.errors.required">Please, enter a valid email
                                                    </div>
                                                    <div *ngIf="fn.email.errors.email">Please, enter a valid email</div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <label for="">What's your phone number?</label><br />
                                            <span class="wpcf7-form-control-wrap intl_tel-153">
                                                <div class="d-flex mb-2 countrycontact">
                                                    <select name="country_code" style="background-position: 98%;"
                                                        formControlName="country_code" class="ctry-select">
                                                        <option *ngFor="let country of countries"
                                                            [attr.data-countryCode]="country.country_code"
                                                            value="{{country.phone_code}}">+{{ country.phone_code }}
                                                        </option>
                                                    </select>
                                                    <div class="contactfield">
                                                        <input type="tel" placeholder="Contact No.*"
                                                            class="inpstyle contactinp" name="phone_number"
                                                            formControlName="phone_number"
                                                            (keypress)="numberOnly($event)"
                                                            [ngClass]="{ 'is-invalid': submitted && fn.phone_number.errors }"
                                                            (countryChange)="countryChange($event)" />
                                                        <div *ngIf="submitted && fn.phone_number.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="fn.phone_number.errors.required">Please, enter
                                                                Phone
                                                                number</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <label for="">Interested In*</label><br />
                                            <span class="wpcf7-form-control-wrap menu-932">
                                                <select name="service" style="background-position: 98%;"
                                                    class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required ctry-select"
                                                    id="service" aria-required="true" aria-invalid="false"
                                                    formControlName="service"
                                                    [ngClass]="{ 'is-invalid': submitted && fn.service.errors }">
                                                    <option value="">Select Service</option>
                                                    <option *ngFor="let service of services" value="{{service.title}}">
                                                        {{ service.title }}</option>
                                                </select>
                                                <div *ngIf="submitted && fn.service.errors" class="invalid-feedback">
                                                    <div *ngIf="fn.service.errors.required">Please, select service</div>
                                                </div>
                                            </span>
                                        </div>
                                        <p></p>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group textarea-form-group">
                                            <label for="">Your message</label><br />
                                            <textarea cols="40" rows="10"
                                                class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required conatct_form_white"
                                                aria-required="true" aria-invalid="false" placeholder="Message*"
                                                name="message" formControlName="message"
                                                [ngClass]="{ 'is-invalid': submitted && fn.service.errors }"></textarea>
                                            <div *ngIf="submitted && fn.message.errors" class="invalid-feedback">
                                                <div *ngIf="fn.message.errors.required">Please, write your message</div>
                                            </div>
                                        </div>
                                        <div class="form-group send_me_check">
                                            <span class="wpcf7-form-control-wrap checkbox-181">
                                                <span class="wpcf7-form-control wpcf7-checkbox">
                                                    <span class="wpcf7-list-item first last">
                                                        <!-- <input type="checkbox" name="checkbox-181[]"
                                                            value="Send me the signed NDA" /><span
                                                            class="wpcf7-list-item-label">Send me the signed NDA</span> -->
                                                    </span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="form-group msg_div">

                                            <p class="contp-note">By sending this form I confirm that I have read and
                                                accepted Pro Web <a href="privacy-policy"
                                                    style="text-decoration: underline; color:#F7993B"
                                                    target="_blank">Privacy Policy</a></p>

                                            <p class="or_para contp-note" style="text-align: center;"><span
                                                    class="or_class">OR</span></p>

                                            <p class="send_enq contp-note">Send us an enquiry on <a
                                                    href="mailto:info@prowebtechnos.com"
                                                    mailto>info&#64;prowebtechnos.com</a>
                                            </p>
                                            <p></p>
                                        </div>

                                        <div class="form-group submit_btn"> <button type="submit"
                                                class="nav-link btn btn-primary w-100">Send Enquiry</button> <span
                                                class="ajax-loader"></span></div>

                                        <p></p>
                                    </div>
                                    <p></p>
                                </div>
                            </div>
                            <p style="text-align: center;
                         margin-top: 30px;
                         font-size: 15px;
                         ">Check out our <a href="/careers" style="color: #F7993B;"><b>*Career</b></a> page to view the
                                job opportunities at Pro Web!</p>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="dedicated-team-benefit">
    <div class="container">
        <div class="dedicated-team-benefit-blog">
            <h1 class="dedicated-title ">Our Presence</h1>
            <p>We've served over 500 companies, empowering them to revolutionize their <br /> operations through
                cutting-edge technological solutions.
            </p>

            <!-- ----------------------- -->

            <ul class="city-listed">

                <li>
                    <img src="assets/img/webp-image/dedicated-team/citi2.webp" width="202" height="450" alt="image" />

                    <div class="location-maping">
                        <span><img src="assets/img/webp-image/home/uaeflag2.webp" class="mapimg" width="30" height="20"
                                alt="flag" /> United Arab Emirates</span>
                        <p><i class="fa fa-map-marker"></i> Office #1606, SIT Tower,
                            Dubai Silicon Oasis , Dubai
                            UAE</p>

                    </div>
                </li>

                <li>
                    <img src="assets/img/webp-image/dedicated-team/citi1.webp" width="202" height="450" alt="image" />

                    <div class="location-maping">
                        <span><img src="assets/img/webp-image/home/india-flag-icon.webp" class="mapimg" width="30"
                                height="20" alt="flag" /> India</span>
                        <p><i class="fa fa-map-marker"></i> 232, Zone-2 MP Nagar, Bhopal, Madhya
                            Pradesh, 462011</p>
                        <!-- <p><i class="fa fa-map-marker"></i> 18th Cross Street, Mahalakshmi Colony Chennai , 600047</p> -->
                    </div>
                </li>

                <li>
                    <img src="assets/img/webp-image/dedicated-team/citi3.webp" width="202" height="450" alt="image" />

                    <div class="location-maping">
                        <span><img src="assets/img/webp-image/home/Canada_flag.webp" class="mapimg" width="30"
                                height="20" alt="flag" /> Canada</span>
                        <p><i class="fa fa-map-marker"></i> 4025 River Mill Way Mississauga
                            Ontario, Canada
                            L4W 4C1</p>

                    </div>
                </li>

            </ul>


            <!-- ------------------------------------ -->

        </div>
    </div>

</section>

<section class="dedicated-team-benefit p-t0">
    <div class="container">
        <div class="dedicated-team-benefit-blog">
            <h2 class="dedicated-title ">Frequently Asked Questions</h2>
            <div class="row">
                <div class="col-sm-6">
                    <div class="faq-list" *ngFor="let item of faq.slice(0, 3); let i = index">
                        <div class="faq-title d-flex justify-content-between" (click)="toggleAccordian($event, i)">
                            <h3>{{item.title}}</h3>
                            <!-- <span class="arrow" [ngClass]="{'up': item.isActive}">  </span> -->

                            <h4 *ngIf="item.isActive == false"> + </h4>
                            <h4 *ngIf="item.isActive == true"> - </h4>


                        </div>
                        <div class="faq-des" *ngIf="item.isActive">
                            <div [innerHTML]="item.des"></div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="faq-list" *ngFor="let item of faq.slice(3); let i = index">
                        <div class="faq-title d-flex justify-content-between" (click)="toggleAccordian($event, i+3)">
                            <h3>{{item.title}}</h3>
                            <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

                            <h4 *ngIf="item.isActive == false"> + </h4>
                            <h4 *ngIf="item.isActive == true"> - </h4>

                        </div>
                        <div class="faq-des" *ngIf="item.isActive">
                            <div [innerHTML]="item.des"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</section>