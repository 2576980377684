<!-- Start Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row justify-content-between">
                    <div class="col-md-8">
                        <h4 class="text-start">{{ category.title }}</h4>
                        <hr>
                    </div>
                    <!-- <div class="col-md-4">
                        <form action="">
                            <div class="mb-3" class="searchinp">
                                <input type="search" class="form-control" id="exampleInputEmail1" placeholder="Search" aria-describedby="emailHelp">
                            </div>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Start Blog Area -->
<section class="blog-area">
    <div class="container-lg"  #target>
        <div class="row">
            <div class="col-md-8">
                <div class="row row-cols-1 row-cols-md-2 g-0 g-md-4">
                    <div class="col my-2" *ngFor="let item of getPaginatedData(currentPage, pageSize)">
                        <div class="card border-0 blogwrap">
                            <div class="blogimg">
                                <img src={{item.image}} class="card-img-top" alt="...">
                            </div>
                            <div class="blogtxt card-body">
                                <h5 class="card-title">{{item.title}}</h5>
                                <div class="d-flex justify-content-between">
                                    <div class="text-secondary">
                                        <i class="fa fa-user me-2"></i> <span>Pro Web</span>
                                    </div>
                                    <div class="text-secondary">
                                        <i class="fa fa-calendar me-2"></i><span>{{item.post_date}}</span>
                                    </div>
                                </div>
                                <p class="card-text mt-2 text-secondary descrip">
                                    {{item.short_description}}
                                </p>
                                <div class="text-end">
                                    <a [routerLink]="['/blog', item.url]" class="btn btn-small btn-primary">Read
                                        More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagination-area">
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <li class="page-item" *ngIf="currentPage > 1">
                                <a class="page-link"
                                    (click)="changePage(currentPage - 1, target)">Prev</a>
                            </li>
                            <li class="page-item" *ngIf="currentPage === 1"><a
                                    class="page-link">Prev</a></li>

                            <li *ngFor="let pageNumber of getPageNumbers()" class="page-item"
                                [class.active]="pageNumber === currentPage"
                                (click)="changePage(pageNumber, target)">
                                <a class="page-link">{{ pageNumber }}</a>
                            </li>

                            <li class="page-item" *ngIf="currentPage < totalPages">
                                <a class="page-link"
                                    (click)="changePage(currentPage + 1, target)">Next</a>
                            </li>
                            <li class="page-item" *ngIf="currentPage === totalPages"><a
                                    class="page-link">Next</a></li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="col-md-4 my-3 my-md-0">
                <app-blog-sidebar></app-blog-sidebar>
            </div>
        </div>
    </div>
</section>