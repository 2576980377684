import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';
import { Router } from "@angular/router";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Platform } from '@angular/cdk/platform';
import { LoadingService } from '../utils/services/loading.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
	isDesktop: boolean | undefined;
	isMobile: boolean | undefined;
	model: any = {};
	mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

	// Meta Title
	title = 'Contact - Pro Web'; 
	constructor(
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private titleService: Title,
		private metaTagService: Meta,
		private alertService: AlertService,
		private router: Router,
		private platform: Platform,
		private loadingService: LoadingService,

	) {
		this.isMobile = this.platform.ANDROID || this.platform.IOS;
		if (!this.isMobile) {
			this.isDesktop = true;
		}
	}

	apiurl = environment.apiUrl;
	blogUrl = environment.blogUrl

	contactForm: any = FormGroup;
	submitted  = false;
	isDisabled = false;
	country_code = '';
	showLoader   = false;
	countries: any = [];
	services: any = [];

	ngOnInit() {
		this.loadingService.setLoadingState(true);

		setTimeout(() => {
		  this.loadingService.setLoadingState(false);
		}, 2000)
		
		this.titleService.setTitle("Contact Pro Web - Connect to kickstart your journey");
		this.metaTagService.updateTag(
			{ name: 'description', content: "Get in touch with Pro Web for web solutions, app development, software expertise, and digital marketing services. Let's collaborate to boost your online impact!" }
		);
		this.metaTagService.updateTag(
			{ name: 'keywords', content: "contact pro web, get in touch with pro web" }
		);

		this.http.get(this.blogUrl + 'countries').subscribe(async response => {
			let data: any = response;
			this.countries = data.response;
		});
		this.http.get(this.blogUrl + 'services').subscribe(async response => {
			let data: any = response;
			this.services = data.response;
		});

		this.contactForm = this.formBuilder.group({
			name: ['', Validators.required],
			country_code: ['', Validators.required],
			phone_number: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			service: ['', Validators.required],
			message: ['', Validators.required]
		});

		this.http.get('https://ipapi.co/json').subscribe(async data => {
			let ClientsData: any = data;
			var countryCode = ClientsData.country_calling_code.replace('+', '');
			this.contactForm.controls.country_code.setValue(countryCode);
		});

		this.addBodyClass();
	}

	get fn() { return this.contactForm.controls; }

	onSubmit(event: any) {
		this.submitted = true;
		if (this.contactForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		this.http.post(this.blogUrl + 'save_contact_enquiry', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.contactForm.reset();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
		// $('a.button').click(function () {
		// 	$('a.button').removeClass("active");
		// 	$(this).addClass("active");
		// });
	}

	ngAfterViewInit(): void {
		gsap.registerPlugin(ScrollTrigger);
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".contact-animation-trigger",
				start: "top",
				end: 'bottom',
				pin: true,
				scrub: true,
			},
		});
		tl.to(".we_r", 0.5, { ease: Expo.easeInOut, opacity: 0 })
		tl.to(".Exploremore", 0.5, { ease: Expo.easeInOut, opacity: 0 })

		tl.to(".appinventiv_img_mast", 1, { ease: Sine.easeInOut, scale: 40 }, "<0.2");
		tl.to(".talk_us_inner", 1, { ease: Sine.easeInOut, css: { opacity: 1, zIndex: 99 } }, "<0.2");
		// tl.to(".bg-gradient", 1, { ease: Sine.easeInOut, css: { opacity: 1} },"<0.2");	
	}

	

	addBodyClass() {
		const bodyTag = document.body;
		bodyTag.classList.add('contact-page');
	}

	

	telInputObject(obj: any) {
		console.log(obj);
		obj.setCountry('in');
	}

	countryChange(country: any) {
		this.contactForm.controls.country_code.setValue(country.dialCode);
	}

	numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	faq = [
		{
			title: " Do we operate with NDAs?",
			des: "Yes, We are guarantying that our cooperation will be confidential.",
			isActive: false,
		},

		{
			title: "Can we transfer copyrights to the developed projects?",
			des: "Certainly, the code that our web development company create is yours. We can also sign a contract to have all questions clear.",
			isActive: false,
		},
		{
			title: " Can we offer hosting for your web solution?",
			des: "Yes, our web development & hosting team can assist you here. Moreover, we host a lot of our clients' sites and APIs on AWS, Google Cloud, Dedicated Servers , VPS & Shared Servers with data center in USA, Canada, Europe, India. You can choose the most convenient hosting plan that include supporting, monitoring and other useful services.",
			isActive: false,
		},
		{
			title: "Can we take over a project developed by another company?",
			des: "Sure, we are ready for such kind of work! At first our company need to analyze it, then we’ll give you our recommendations on its improvement.",
			isActive: false,
		},
	
		{
			title: "What security standards do you follow for web development?",
			des: "We follow the standard security practices for all our web applications that ensure the best level of security. We adhere to the practices to prevent critical security flaws such as:Data validation via an HTTP request to prevent attacks such as SQL injection, log injection, Cross Site Scripting and HTTP Response Splitting.SSL (HTTPS) for protecting session IDs and cookies.Validation of server-side data as it is subjected to being modified.Frequent vulnerability scans while production and update.However, if the project needs added security we also provide on-demand third-party audits and comply with Open Web Application Security Project (OWASP) for web apps, and PCI requirements for ecommerce.",
			isActive: false,
		},

		{
			title: "How do you choose the ideal technology for my project?",
			des: "We select the ideal technology considering your project and its requirements. There are many factors and parameters that must be considered that’s the reason we analyze and drill down through your project at micro-levels to select optimum technology. The few primary things that we consider are: scalability and maintainability, cost, security, time to market, the performance of the application, database structure (relational/non-relational, graph database), ecosystem and more.",
			isActive: false,
		}
	];

	toggleAccordian(event: any, index: any) {
		
		this.faq.forEach((item, i) => {
			if (i !== index) {
				item.isActive = false;
			}
		});

		this.faq[index].isActive = !this.faq[index].isActive;
	}
}