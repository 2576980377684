<div class="banner-wrap">
  <img
    src="../../assets/img/servicesdev/seo-service/seo-banner.png"
    class="seobannerimg"
    alt=""
    srcset=""
  />


  
  <div class="banner-content">
    <div class="seo-banner">
      <div class="row align-items-center">
        <div class="col-6 col-lg-7">
          <div class="banner-text">
            <h1>
              Maximize Your Digital Presence with Dubai’s Expert SEO Services
            </h1>
            <p>
              Craft a distinctive online footprint with our personalized SEO
              tactics. Propel your brand to the peak of search results,
              generating top-notch leads and amplifying revenue streams. Embrace
              bespoke strategies for unparalleled online presence and business
              advancement.
            </p>
            <div class="my-md-2 d-flex align-items-center">
              <div class="google">
                <img
                  src="/assets/img/servicesdev/web-design/google-icons.webp"
                  width="100%"
                  alt="google-icon"
                  srcset=""
                />
              </div>
              <span>🏆 Award-Winning Agency in 2021, 2022 & 2023</span>
            </div>
            <h5>
              Popular Brands Who
              <img
                src="/assets/img/awardslogo/hearticonhome.png"
                width="25px"
                alt="heart"
                srcset=""
              />
              Pro Web
            </h5>
            <div class="trusted-logo py-1 py-md-3">
              <table>
                <tr>
                  <td>
                    <img
                      src="../../assets/img/servicesdev/seo-service/industrial_control_care.webp"
                      class="py-2"
                      alt="book my pet"
                      srcset=""
                    />
                  </td>
                  <td>
                    <img
                      src="../../assets/img/servicesdev/seo-service/avea_contracting_logo.svg"
                      class="py-2"
                      alt="book my pet"
                      srcset=""
                    />
                  </td>

                  <td>
                    <img
                      src="../../assets/img/servicesdev/seo-service/Whites.webp"
                      class="py-2"
                      alt="book my pet"
                      srcset=""
                    />
                  </td>
                  <td>
                    <img
                      src="../../assets/img/servicesdev/seo-service/luxuru_gallery_logo.webp"
                      class="py-2"
                      alt="book my pet"
                      srcset=""
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-5">
          <div>
            <app-form></app-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- mobile -->
<div class="mobilebanner">
  <div class="content">
    <h2 class="p-1 p-md-3">
      Customized SEO services that add more leads to your website and dirhams to
      your bank account
    </h2>
    <p>
      Craft a distinctive online footprint with our personalized SEO tactics.
      Propel your brand to the peak of search results, generating top-notch
      leads and amplifying revenue streams. Embrace bespoke strategies for
      unparalleled online presence and business advancement.
    </p>

    <h5>
      Popular Brands Who ️<img
        src="/assets/img/awardslogo/hearticonhome.png"
        width="20px"
        alt="heart"
        srcset=""
      />
      Pro Web
    </h5>

    <div class="trusted-logo pt-0 pb-3">
      <table class="m-auto">
        <tr>
          <td>
            <img
              src="../../assets/img/servicesdev/seo-service/industrial_control_care.webp"
              class="py-2"
              alt="book my pet"
              srcset=""
            />
          </td>
          <td>
            <img
              src="../../assets/img/servicesdev/seo-service/avea_contracting_logo.svg"
              class="py-2"
              alt="book my pet"
              srcset=""
            />
          </td>

          <td>
            <img
              src="../../assets/img/servicesdev/seo-service/Whites.webp"
              class="py-2"
              alt="book my pet"
              srcset=""
            />
          </td>
        </tr>
      </table>
    </div>

    <div class="my-md-2 text-center">
      <span>🏆 Award-Winning Agency in 2021, 2022 & 2023</span>
      <div class="google m-auto">
        <img
          src="/assets/img/servicesdev/web-design/google-icons.webp"
          width="100%"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <div>
      <app-form></app-form>
    </div>
  </div>
</div>

<!-- breadcrumbs -->
<nav aria-label="breadcrumb" class="ps-5 pt-3 pt-md-0 d-none d-md-block">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href="/">Services</a></li>
    <li class="breadcrumb-item active" aria-current="page">Search Engine Optimization</li>
  </ol>
</nav>


<!-- ------------------------------------>
<app-technology-stack
  firsttitle="Maximize Google Conversions"
  semititle="With Pro Web's SEO Services"
  description="In the world of SEO, a standardized approach falls short. The key lies in a finely tuned process that distinguishes effective strategies from inadequate ones. Pro Web, leveraging years of industry experience, has meticulously formulated an SEO methodology strategically planned to enhance your website's organic visibility with the utmost precision and efficacy. It’s not just rankings we grow; we increase your traffic, revenue, and ROI. Our custom SEO services ensure you reach new heights by delivering what matters most."
  [cards]="cardsItems"
></app-technology-stack>

<section class="gradientwrap">
  <div>
    <div
      class="d-none d-md-flex justify-content-between align-items-center py-3 px-5"
    >
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img
            src="assets/img/servicesdev/link_building_icon.svg"
            alt=""
            srcset=""
          />
          <p class="text-white ps-2">Link Building</p>
        </div>
      </div>
      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img
            src="assets/img/servicesdev/content_creation_icon.svg"
            alt=""
            srcset=""
          />
          <p class="text-white ps-2">Content Creation</p>
        </div>
      </div>

      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>

      <div class="contentIcon">
        <a
          href="https://www.google.com/partners/agency?id=2237427351&_gl=1*1tt2qqj*_ga*MzM3NjY1MjE1LjE2MjQwMTkyNjU.*_ga_V9K47ZG8NP*MTcxNTI2Mjc2OC4xOS4wLjE3MTUyNjI3NjguNjAuMC4w"
        >
          <img
            src="../../assets/img/servicesdev/google_partner.svg"
            alt=""
            srcset=""
          />
        </a>
      </div>

      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img
            src="assets/img/servicesdev/technical_auditing_icon.svg"
            alt=""
            srcset=""
          />
          <p class="text-white ps-2">Technical Auditing</p>
        </div>
      </div>
      <div class="px-3 d-none d-md-block">
        <img src="assets/img/servicesdev/white-diamond.svg" alt="" srcset="" />
      </div>
      <div class="contentIcon">
        <div class="d-flex align-items-center">
          <img
            src="assets/img/servicesdev/transparent_reporting.svg"
            alt=""
            srcset=""
          />
          <p class="text-white ps-2">Transparent Reporting</p>
        </div>
      </div>
    </div>

    <div
      class="d-block d-md-none justify-content-center align-items-center py-3"
    >
      <ngx-slick-carousel
        class="carousel owl-carousel branflex"
        [config]="carouselConfig2"
      >
        <div
          class="contentIcon"
          ngxSlickItem
          *ngFor="let item of iconsdata"
          class="slider-inlinefixe"
        >
          <div class="text-center">
            <a href="{{ item.link }}">
              <img src="{{ item.icon }}" class="m-auto" alt="icon" srcset="" />
            </a>

            <p class="text-white my-2" style="font-size: 12px">
              {{ item.name }}
            </p>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<app-dedicated-hosting
  heading="SEO"
  subheading="Features"
  [data]="dataItems"
></app-dedicated-hosting>

<section class="spacing technical-seo p-3 p-md-5">
  <div class="container">
    <div class="row align-items-center my-3">
      <div class="col-md-6">
        <div>
          <img
            src="../../assets/img/servicesdev/seo-service/technical_seo.png"
            width="100%"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="text-start">
          <h2 class="text-white mainheading">
            Wait! What is Technical SEO, & Why is it Important?
          </h2>
          <p class="text-white my-3 para">
            Technical SEO refers to website and server optimizations that help
            search engine spiders crawl and index your site more effectively (to
            help improve organic rankings). Technical SEO is important because
            search engines give preferential treatment in search results pages
            (SERPs) to websites with specific technical characteristics. For
            example, a secure connection, a responsive design, and a fast
            loading time. We conduct technical SEO analysis to ensure your
            website meets the performance standards to rank in today’s
            competitive search results.
          </p>
          <h3 class="text-white mt-5 investigateHead">What We Investigate?</h3>

          <div class="row">
            <div class="col col-md-6">
              <ul class="seo-content">
                <li>
                  <h6 class="text-white">Duplicate issues</h6>
                </li>

                <li>
                  <h6 class="text-white">Crawlability issues</h6>
                </li>

                <li>
                  <h6 class="text-white">Broken links</h6>
                </li>
              </ul>
            </div>
            <div class="col col-md-6">
              <ul class="seo-content">
                <li>
                  <h6 class="text-white">XML sitemap issues</h6>
                </li>

                <li>
                  <h6 class="text-white">Dynamic URLs</h6>
                </li>

                <li>
                  <h6 class="text-white">W3C Errors</h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- the proweb way section -->
<section class="spacing">
  <div class="proweb-padding">
    <div class="row justify-content-center processWrap">
      <h2 class="my-3">The <span>Pro Web Way</span></h2>

      <div class="d-none d-md-flex p-3">
        <div class="row px-5">
          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/seo1-gray.svg"
                    class="gray"
                    alt=""
                    srcset=""
                  />
                  <img
                    src="../../assets/img/servicesdev/process-way/seo1-orange.svg"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Technical Auditing</h5>
                <ul>
                  <li>Conduct a comprehensive website technical audit</li>
                  <li>
                    Evaluate site architecture, page loading speed, and mobile
                    friendliness
                  </li>
                  <li>
                    Ensure search engines can easily crawl and index pages
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/seo2-gray.svg"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/seo2-orange.svg"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Keyword Research</h5>

                <ul>
                  <li>
                    Perform in-depth keyword research to identify relevant and
                    high-performing keywords
                  </li>
                  <li>
                    Analyze search volume, competition, and user intent for
                    targeted keywords
                  </li>
                  <li>
                    Create a strategic keyword list aligned with the brand's
                    business goals
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/seo3-gray.svg"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/seo3-orange.svg"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Content Strategy & Creation</h5>

                <ul>
                  <li>
                    Craft engaging, informative, and SEO-optimized content
                  </li>
                  <li>
                    Seamlessly integrate targeted keywords within the content
                  </li>
                  <li>
                    Craft content tailored to the target audience while aligning
                    with SEO objectives
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/seo4-gray.svg"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/seo4-orange.svg"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Link-Building Strategy</h5>
                <ul>
                  <li>Develop a robust link-building strategy</li>
                  <li>Acquire high-quality backlinks from reputable sources</li>
                  <li>Maintain a diverse and natural link profile</li>
                  <li>Regular backlinks audit to maintain a clean profile.</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/seo5-gray.svg"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/seo5-orange.svg"
                    class="orange"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Ongoing Optimization</h5>
                <ul>
                  <li>
                    Monitor industry trends, algorithm updates, and competitor
                    activities
                  </li>
                  <li>
                    Adapt SEO strategies to match changing search engine trends
                  </li>
                  <li>
                    Implement iterative improvements to enhance on-page SEO
                    effectiveness
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-md-none my-4">
        <ngx-slick-carousel
          class="carousel owl-carousel branflex processSlider"
          [config]="carouselConfig"
        >
          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/seo1-orange.svg"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>

              <h5 class="text-center fw-bold my-3">Technical Auditing</h5>
              <ul>
                <li>Conduct a comprehensive website technical audit</li>
                <li>
                  Evaluate site architecture, page loading speed, and mobile
                  friendliness
                </li>
                <li>Ensure search engines can easily crawl and index pages</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/seo2-orange.svg"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>

              <h5 class="text-center fw-bold my-3">Keyword Research</h5>

              <ul>
                <li>
                  Perform in-depth keyword research to identify relevant and
                  high-performing keywords
                </li>
                <li>
                  Analyze search volume, competition, and user intent for
                  targeted keywords
                </li>
                <li>
                  Create a strategic keyword list aligned with the brand's
                  business goals
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/seo3-orange.svg"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>

              <h5 class="text-center fw-bold my-3">
                Content Strategy and Creation
              </h5>

              <ul>
                <li>Craft engaging, informative, and SEO-optimized content</li>
                <li>
                  Seamlessly integrate targeted keywords within the content
                </li>
                <li>
                  Craft content tailored to the target audience while aligning
                  with SEO objectives
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/seo4-orange.svg"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>

              <h5 class="text-center fw-bold my-3">Link-Building Strategy</h5>
              <ul>
                <li>Develop a robust link-building strategy</li>
                <li>Acquire high-quality backlinks from reputable sources</li>
                <li>Maintain a diverse and natural link profile</li>
                <li>Regular backlinks audit to maintain a clean profile</li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/seo5-orange.svg"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>

              <h5 class="text-center fw-bold my-3">Ongoing Optimization</h5>
              <ul>
                <li>
                  Monitor industry trends, algorithm updates, and competitor
                  activities
                </li>
                <li>
                  Adapt SEO strategies to match changing search engine trends
                </li>
                <li>
                  Implement iterative improvements to enhance on-page SEO
                  effectiveness
                </li>
              </ul>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<!-- other section -->

<section class="text-center">
  <div>
    <div>
      <h2 class="revenue-heading my-3">
        Helping 100+ leading organizations
        <span> double their revenue growth organically</span>
      </h2>
      <p>
        Our results speak for themselves. It’s one of the many reasons we’re the
        award wining SEO company in the United Arab Emirates.
      </p>
    </div>

    <div class="revenue-bg">
      <div class="container">
        <ngx-slick-carousel
          class="carousel seoSlider"
          #slickModal
          [config]="slideConfig"
          id="slider"
        >
          <div ngxSlickItem class="slide">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="whiteswrap">
                    <img
                      src="../../assets/img/servicesdev/seo-service/icc-img.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div>
                    <h5 class="table-heading">
                      <div class="d-flex justify-content-center">
                        <span>Keywords Ranking on </span>
                        <img
                          src="../../assets/img/servicesdev/seo-service/google-ae.svg"
                          class="mx-1"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p><a href="javascript:;"> www.iccfzco.com </a></p>
                    </h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Keywords</th>
                          <th scope="col">Ranking</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            <span>Bently Nevada 3500 Repair</span>
                          </td>
                          <td>#1</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Alstom P320
                          </td>
                          <td>#1</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Rustronic Repair
                          </td>
                          <td>#2</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Woodward Netcon
                          </td>
                          <td>#3</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Vfd Repair
                          </td>
                          <td>#3</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ngxSlickItem class="slide">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="whiteswrap">
                    <img
                      src="../../assets/img/servicesdev/seo-service/luxury-img.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div>
                    <h5 class="table-heading">
                      <div class="d-flex justify-content-center">
                        <span>Keywords Ranking on </span>
                        <img
                          src="../../assets/img/servicesdev/seo-service/google-ae.svg"
                          class="mx-1"
                          alt=""
                          srcset=""
                        />
                      </div>
                      <p>
                        <a href="javascript:;">
                          www.luxurygallery.com
                        </a>
                      </p>
                    </h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Keywords</th>
                          <th scope="col">Ranking</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            <span>Fendi watch</span>
                          </td>
                          <td>#1</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Ducati watch
                          </td>
                          <td>#1</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Fendi watches
                          </td>
                          <td>#4</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Aigner Accessories
                          </td>
                          <td>#5</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Saint Honore
                          </td>
                          <td>#5</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ngxSlickItem class="slide">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="whiteswrap">
                    <img
                      src="../../assets/img/servicesdev/seo-service/avea-img.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div>
                    <h5 class="table-heading">
                      <div class="d-flex justify-content-center">
                        <span>Keywords Ranking on </span>
                        <img
                          src="../../assets/img/servicesdev/seo-service/google-ae.svg"
                          class="mx-1"
                          alt=""
                          srcset=""
                        />
                      </div>

                      <p>
                        <a href="javascript:;"
                          >www.aveacontracting.com
                        </a>
                      </p>
                    </h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Keywords</th>
                          <th scope="col">Ranking</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            <span>Interior Design and Contracting Company</span>
                          </td>
                          <td>#3</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Interior Contracting Companies in Dubai
                          </td>
                          <td>#2</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Restaurant Fit Out Contractors Dubai
                          </td>
                          <td>#5</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Interior Contractors Dubai
                          </td>
                          <td>#3</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Interior Contracting Companies in UAE
                          </td>
                          <td>#5</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ngxSlickItem class="slide">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="whiteswrap">
                    <img
                      src="../../assets/img/servicesdev/seo-service/whites-img.png"
                      width="100%"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
                <div class="col-md">
                  <div>
                    <h5 class="table-heading">
                      <div class="d-flex justify-content-center">
                        <span>Keywords Ranking on </span>
                        <img
                          src="../../assets/img/servicesdev/seo-service/google-ae.svg"
                          class="mx-1"
                          alt=""
                          srcset=""
                        />
                      </div>

                      <p><a href="javascript:;"> www.whites.ae</a></p>
                    </h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Keywords</th>
                          <th scope="col">Ranking</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            <span>Dry Cleaners Dubai Marina</span>
                          </td>
                          <td>#5</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Laundry Uubai Marina
                          </td>
                          <td>#5</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Laundry Business Bay
                          </td>
                          <td>#9</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Laundry in Discovery Gardens
                          </td>
                          <td>#10</td>
                        </tr>
                        <tr>
                          <td class="text-start d-flex p-3">
                            <img
                              src="../../assets/img/servicesdev/seo-service/diamond-icon.svg"
                              alt=""
                              class="px-1"
                              srcset=""
                            />
                            Laundry Silicon Oasis
                          </td>
                          <td>#10</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<app-testimonial-service
  profile="girish-profile.svg"
  clientname="Mr. Girish Gullapudi"
  designation="Product Lead - Landmark Group"
  location="United Arab Emirates"
  flag="uk-flag.svg"
  clientcomment="Have worked with Proweb team on couple of projects for Balance Spa, Nandos KSA that are part of Landmark Hospitality group.Efficient and cost effective team. Responsible and accountable."
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data"
  [clientmobile]="datamobile"
  clienttarget="landmark-group-logo.png"
></app-testimonial-service>

<app-check-out
  checkoutImg="../../assets/img/servicesdev/checkout_seo.png"
  alt="check out"
  checkoutText="Check Out Our"
  checkoutName="SEO packages"
  checkoutUrl="/seo-packages"
></app-check-out>

<app-bottom-bar
  text="Let's create a custom plan that fits all your requirements perfectly!"
></app-bottom-bar>

<!-- faq -->

<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center">
      <h2>Frequently <span>Asked Questions</span></h2>
      <p class="my-3" style="padding: 0 35px">
        Find the answers you need to know more about SEO
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span>-->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
