<!-- Start Page Title -->
<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="row align-items-center">
					<div class="col-md-6 choose-text">
						<h1 class="text-white text-center text-md-start"><img src="assets/img/star-glob.svg" width="28"
								height="28" alt="image" class="mx-0 mx-md-2">Our Case Studies</h1>
						<h4 class="proofheading">Featured Case Studies</h4>
						<p class="exampletext">Take a look at the portals and applications we've developed in
							collaboration with our fantastic clients. Get in touch with us today and become the next
							standout success in our portfolio!</p>
					</div>
					<div class="col-md-6 righttopsliderbox">
						<div class="casestudy-banner">
							<img src="assets/img/casestudy-sideimg.png" alt="" srcset="">
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>
<!-- End Page Title -->


<!-- breadcrumbs -->
<nav aria-label="breadcrumb" class="p-3 p-md-5">
	<ol class="breadcrumb mb-0">
		<li class="breadcrumb-item"><a href="/" class="text-dark">Home</a></li>
		<li class="breadcrumb-item active" aria-current="page"> Case Studies</li>
	</ol>
</nav>



<div class="casestudy-sec1">
	<div class="containerfull" #target>
		<div class="row">
			<div class="col-md-12">
				<div class="success-story-wrapper">
					<div class="success-story-list-wrapper">

						<div class="tabimages">
							<div class="single-story active" id="story_1">
								<div class="wrapper">
									<div class=" single-story active" id="story_1">
										<section *ngFor="let data of getPaginatedData(currentPage, pageSize)"
											class="casestudy-wrap">
											<div class="case-wrapper">
												<div class="case-front">
													<div class="wrap-front">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
														<img alt="Appointment scheduling app" loading="lazy"
															src="{{data.bgImage}}" width="640" height="641">
													</div>
												</div>
												<div class="case-back">
													<div class="">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title"
															*ngIf="isDesktop">{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
															<div class="home-port-swiper__slide-item__icons">
																<div class="home-port-swiper__slide-item-platform-icon"
																	*ngFor="let front of data.techstack.frontend">
																	<img alt="icons" loading="lazy" width="36"
																		height="36" decoding="async" data-nimg="1"
																		style="color:transparent;width:100%;height:auto"
																		src="{{front.image}}">
																</div>
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton"
															*ngIf="data.linktext">{{data.linktext}}</a>
													</div>
												</div>
											</div>

										</section>
									</div>

									<div class="col-lg-12 col-md-12">
										<div class="pagination-area">
											<nav aria-label="Page navigation">
												<ul class="pagination justify-content-center">
													<li class="page-item" *ngIf="currentPage > 1">
														<a class="page-link"
															(click)="changePage(currentPage - 1, target)">Prev</a>
													</li>
													<li class="page-item" *ngIf="currentPage === 1"><a
															class="page-link">Prev</a></li>

													<li *ngFor="let pageNumber of getPageNumbers()" class="page-item"
														[class.active]="pageNumber === currentPage"
														(click)="changePage(pageNumber, target)">
														<a class="page-link">{{ pageNumber }}</a>
													</li>

													<li class="page-item" *ngIf="currentPage < totalPages">
														<a class="page-link"
															(click)="changePage(currentPage + 1, target)">Next</a>
													</li>
													<li class="page-item" *ngIf="currentPage === totalPages"><a
															class="page-link">Next</a></li>
												</ul>
											</nav>
										</div>
									</div>
								</div>
							</div>
							<div class="single-story" id="story_2">
								<div class="wrapper">
									<div class="examples single-story" id="story_2">
										Automobile
									</div>
								</div>
							</div>
							<div class="single-story" id="story_3">
								<div class="wrapper">
									<div class="examples single-story" id="story_3">
										Energy
									</div>
								</div>
							</div>
							<div class="single-story" id="story_4">
								<div class="wrapper">
									<div class="examples single-story" id="story_4">
										Education
									</div>
								</div>
							</div>
							<div class="single-story " id="story_5">
								<div class="wrapper">
									<div class="examples single-story " id="story_5">
										Beauty Salon
									</div>
								</div>
							</div>
							<div class="single-story " id="story_6">
								<div class="wrapper">
									<div class="examples single-story " id="story_6">
										Fashion
									</div>
								</div>
							</div>
							<div class="single-story " id="story_7">
								<div class="wrapper">
									<div class="examples single-story " id="story_7">
										Finance
									</div>
								</div>
							</div>
							<div class="single-story" id="story_8">
								<div class="wrapper">
									<div class="examples single-story" id="story_8">
										Food & Beverages
									</div>
								</div>
							</div>
							<div class="single-story" id="story_9">
								<div class="wrapper">
									<div class="examples single-story" id="story_9">
										Music
									</div>
								</div>
							</div>
							<div class="single-story " id="story_10">
								<div class="wrapper">
									<div class="examples single-story " id="story_10">
										Home Services
									</div>
								</div>
							</div>
							<div class="single-story " id="story_11">
								<div class="wrapper">
									<div class="examples single-story " id="story_11">
										Logistic
									</div>
								</div>
							</div>
							<div class="single-story " id="story_12">
								<div class="wrapper">
									<div class="examples single-story " id="story_12">
										Real Estate
									</div>
								</div>
							</div>
							<div class="single-story" id="story_13">
								<div class="wrapper">
									<div class="examples single-story active" id="story_13">
										<section *ngFor="let data of getPaginatedDatainterior(currentPage, pageSize)"
											class="common-porfolio-sec">

											<div
												class="swiper-slide home-port-swiper__slide home-port-swiper__slide_default swiper-slide-active ">
												<figcaption class="ls-layout-bottom-left">
													<div class="ls-reveal-right ls-delay-100">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
													</div>
												</figcaption>
												<div class="home-port-swiper__slide-item {{data.class}}">
													<img alt="Appointment scheduling app" loading="lazy"
														src="{{data.bgImage}}">

													<div class="home-port-swiper__slide-item__inner">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title">
															{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton">Learn
															More</a>
														<div class="home-port-swiper__slide-item__icons">
															<div class="home-port-swiper__slide-item-platform-icon"
																*ngFor="let front of data.techstack.frontend">
																<img alt="icons" loading="lazy" width="36" height="36"
																	decoding="async" data-nimg="1"
																	style="color:transparent;width:100%;height:auto"
																	src="{{front.image}}">
															</div>

														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
								</div>
							</div>
							<div class="single-story" id="story_14">
								<div class="wrapper">
									<div class="examples single-story active" id="story_14">
										<section *ngFor="let data of getPaginatedDataretail(currentPage, pageSize)"
											class="common-porfolio-sec">

											<div
												class="swiper-slide home-port-swiper__slide home-port-swiper__slide_default swiper-slide-active ">
												<figcaption class="ls-layout-bottom-left">
													<div class="ls-reveal-right ls-delay-100">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
													</div>
												</figcaption>
												<div class="home-port-swiper__slide-item {{data.class}}">
													<img alt="Appointment scheduling app" loading="lazy"
														src="{{data.bgImage}}">

													<div class="home-port-swiper__slide-item__inner">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title">
															{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton">Learn
															More</a>
														<div class="home-port-swiper__slide-item__icons">
															<div class="home-port-swiper__slide-item-platform-icon"
																*ngFor="let front of data.techstack.frontend">
																<img alt="icons" loading="lazy" width="36" height="36"
																	decoding="async" data-nimg="1"
																	style="color:transparent;width:100%;height:auto"
																	src="{{front.image}}">
															</div>

														</div>
													</div>
												</div>
											</div>
										</section>

									</div>
								</div>
							</div>
							<div class="single-story" id="story_15">
								<div class="wrapper">
									<div class="examples single-story active" id="story_15">
										<section *ngFor="let data of getPaginatedDatahealthcare(currentPage, pageSize)"
											class="common-porfolio-sec">

											<div
												class="swiper-slide home-port-swiper__slide home-port-swiper__slide_default swiper-slide-active ">
												<figcaption class="ls-layout-bottom-left">
													<div class="ls-reveal-right ls-delay-100">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
													</div>
												</figcaption>
												<div class="home-port-swiper__slide-item {{data.class}}">
													<img alt="Appointment scheduling app" loading="lazy"
														src="{{data.bgImage}}">

													<div class="home-port-swiper__slide-item__inner">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title">
															{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton">Learn
															More</a>
														<div class="home-port-swiper__slide-item__icons">
															<div class="home-port-swiper__slide-item-platform-icon"
																*ngFor="let front of data.techstack.frontend">
																<img alt="icons" loading="lazy" width="36" height="36"
																	decoding="async" data-nimg="1"
																	style="color:transparent;width:100%;height:auto"
																	src="{{front.image}}">
															</div>

														</div>
													</div>
												</div>
											</div>
										</section>

									</div>
								</div>
							</div>
							<div class="single-story" id="story_16">
								<div class="wrapper">
									<div class="examples single-story active" id="story_16">
										<section *ngFor="let data of getPaginatedDatatour(currentPage, pageSize)"
											class="common-porfolio-sec">

											<div
												class="swiper-slide home-port-swiper__slide home-port-swiper__slide_default swiper-slide-active ">
												<figcaption class="ls-layout-bottom-left">
													<div class="ls-reveal-right ls-delay-100">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
													</div>
												</figcaption>
												<div class="home-port-swiper__slide-item {{data.class}}">
													<img alt="Appointment scheduling app" loading="lazy"
														src="{{data.bgImage}}">

													<div class="home-port-swiper__slide-item__inner">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title">
															{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton">Learn
															More</a>
														<div class="home-port-swiper__slide-item__icons">
															<div class="home-port-swiper__slide-item-platform-icon"
																*ngFor="let front of data.techstack.frontend">
																<img alt="icons" loading="lazy" width="36" height="36"
																	decoding="async" data-nimg="1"
																	style="color:transparent;width:100%;height:auto"
																	src="{{front.image}}">
															</div>

														</div>
													</div>
												</div>
											</div>
										</section>

									</div>
								</div>
							</div>
							<div class="single-story" id="story_17">
								<div class="wrapper">
									<div class="examples single-story active" id="story_17">
										<section *ngFor="let data of getPaginatedDatasports(currentPage, pageSize)"
											class="common-porfolio-sec">

											<div
												class="swiper-slide home-port-swiper__slide home-port-swiper__slide_default swiper-slide-active ">
												<figcaption class="ls-layout-bottom-left">
													<div class="ls-reveal-right ls-delay-100">
														<h4>
															<a href="{{data.link}}"><img
																	alt="Appointment scheduling app" loading="lazy"
																	src="{{data.image}}" class="logoimages"></a>
														</h4>
													</div>
												</figcaption>
												<div class="home-port-swiper__slide-item {{data.class}}">
													<img alt="Appointment scheduling app" loading="lazy"
														src="{{data.bgImage}}">

													<div class="home-port-swiper__slide-item__inner">
														<div class="home-port-swiper__slide-item__title">
															{{data.headingtext}}</div>
														<div class="home-port-swiper__slide-item__sub-title">
															{{data.descriptiontext}}</div>
														<div
															class="home-port-swiper__slide-item__text swiper-no-swiping">
															{{data.textdiscription}}</div>
														<div class="home-port-swiper__slide-item-info">
															<div class="home-port-swiper__slide-item-category">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.subtitle}}</div>{{data.Categorytext}}
															</div>
															<div
																class="home-port-swiper__slide-item-category home-port-swiper__slide-item-margin">
																<div class="home-port-swiper__slide-item__sub-title">
																	{{data.teamtext}}</div>{{data.teamstanth}}
															</div>
														</div>
														<a href="{{data.link}}" class="learnmorbutton">Learn
															More</a>
														<div class="home-port-swiper__slide-item__icons">
															<div class="home-port-swiper__slide-item-platform-icon"
																*ngFor="let front of data.techstack.frontend">
																<img alt="icons" loading="lazy" width="36" height="36"
																	decoding="async" data-nimg="1"
																	style="color:transparent;width:100%;height:auto"
																	src="{{front.image}}">
															</div>

														</div>
													</div>
												</div>
											</div>
										</section>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>

</div>