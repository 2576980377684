<div class="about-img"></div>
<nav aria-label="breadcrumb" class="ps-3 ps-md-5 mt-3 mt-md-0">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item active" aria-current="page">About us</li>
    </ol>
</nav>
<section class="about-area ptb-80">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-4">
                <h2 class="ourstoryhead">
                    The
                    <span class="pro-txt">Pro</span>
                    <span class="web-txt ms-2">Web</span>
                    <br />
                    Story
                </h2>
            </div>
            <div class="col-md-8">
                <p class="para">
                    Pro Web is a team of 70+ highly qualified and experienced professionals offering technological
                    solutions by industry standards from the year 2012. We've accomplished business & technology
                    objectives for both medium-sized and large-scale corporations to meet their demands of long-term
                    growth & success in several domains including Web Design, Web Development, Mobile App Development,
                    E-commerce Development, Web Maintenance, SEO, Google Ads Management, Web Hosting Domain & Dedicated
                    Hiring. We have delivered innovative and satisfactory projects to our clients, enhancing their
                    online presence and business intelligence. We have also worked on increasing their ROI and branding
                    through our customized solutions. Our mission is to develop more innovative solutions every day and
                    to make our clients successful. We are passionate about creating the best IT solutions for your
                    specific needs.
                </p>
            </div>
        </div>
    </div>
    <!-- portfilio -->
    <section class="about-portfilio my-5"
        style="background: url('https://www.rpatechnologies.in/assets/img/aboutpage/bgimgabout.png');">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="port-block">
                        <div class="blue-box">
                            <h3>500+</h3>
                            <img src="assets/img/aboutpage/g1.png" />
                        </div>
                        <h3>Projects <br /> Delivered</h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="port-block">
                        <div class="blue-box light-blue">
                            <h3>70+</h3>
                            <img src="assets/img/aboutpage/g2.png" />
                        </div>
                        <h3>In-House <br /> Specialists</h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="port-block">
                        <div class="blue-box">
                            <h3>11+</h3>
                            <img src="assets/img/aboutpage/g3.png" />
                        </div>
                        <h3>Years of <br /> Experience </h3>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="port-block">
                        <div class="blue-box light-blue">
                            <h3> 25+</h3>
                            <img src="assets/img/aboutpage/g4.png" />
                        </div>
                        <h3>Industries <br /> Served </h3>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- industries -->
    <section class="services-area ind-services-area">
        <div class="container">
            <div class="row h-100 justify-content-center align-items-center">

                <div class="col-lg-5 col-12 services-content">
                    <div class="section-title">
                        <h2>Industries <span style="color: #F7993B;">We Cater</span> </h2>
                        <div class="bar"></div>
                        <p>Empowering digital transformations with the most current IT solutions across a wide range of
                            industries.</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-user"></i> Lifestyle
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-book"></i> Education
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-plane"></i> Tour & Travel
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-shopping-cart"></i> Retail & Consumer
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-music"></i> Music
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-cogs"></i> Professional Services
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-building-o"></i> Property & Real Estate
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="box">
                                <i class="fa fa-paw"></i> Pet Care
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 col-12">
                    <div class="globe-img">
                        <img class="globe-image" src="assets/img/globe-image.png" alt="globe">
                        <div class="globe-info">
                            <p>Building Technology-Driven World</p>
                            <h2>360<sup>0</sup></h2>
                            <p><span>Countries<br>
                                    &amp; <br>
                                    Industries</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




    
    <!-- approach -->
    <section class="about-area ptb-80 ptb-100 mobile-hide">
        <div class="container">
            <div class="about-content-bg approch-our">
                <h2> <span class="pro-txt"> Our </span><span class="web-txt">Approach</span> </h2>
                <div class="approach section">
                    <div class="approach-weird-stuff">

                        <div id="approach-p1">
                            <div class="approach-header-flex">
                                <a>
                                    <span>Technology consulting</span>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.9104 3.83724L0.888447 16.8592L2.95849 18.9292L15.9715 5.91614L15.9753 12.7102L18.9028 12.7085L18.8961 0.925636L7.08445 0.886087L7.07465 3.81355L13.9104 3.83724Z"
                                            fill="#151915" />
                                    </svg>
                                </a>
                            </div>
                            <p class="approach-p">Pro Web can be your technology advisor guiding every business with the
                                right
                                approach.</p>
                            <div id="start-top-face"></div>
                        </div>

                        <div class="scene">
                            <div class="cube" id="cube" [ngClass]="classnamenew">
                                <div class="face -front" id="right" (mouseenter)="onMouseEnter(3)"
                                    (mouseleave)="onMouseLeave(3)">
                                    <span class="color-white">Development Team</span>
                                    <div id="end-right-face"></div>
                                </div>
                                <div class="face -left"></div>
                                <div class="face -head" id="head" (mouseenter)="onMouseEnter(1)"
                                    (mouseleave)="onMouseLeave(1)">
                                    <span>Technology consulting</span>
                                    <div id="end-top-face"></div>
                                </div>
                                <div class="face -bottom"></div>
                                <div class="face -right cube-face-hover" id="left" (mouseenter)="onMouseEnter(2)"
                                    (mouseleave)="onMouseLeave(2)">
                                    <span>Software Solutions</span>
                                    <div id="end-left-face"></div>
                                </div>
                                <div class="face -back"></div>
                            </div>
                        </div>

                        <div id="approach-p2">
                            <div class="approach-header-flex">
                                <a>
                                    <span>Software Solutions</span>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.9104 3.83724L0.888447 16.8592L2.95849 18.9292L15.9715 5.91614L15.9753 12.7102L18.9028 12.7085L18.8961 0.925636L7.08445 0.886087L7.07465 3.81355L13.9104 3.83724Z"
                                            fill="#151915" />
                                    </svg>
                                </a>
                            </div>
                            <p class="approach-p">We provide full-cycle software solutions covering the complete
                                development
                                lifecycle.</p>
                            <div id="start-left-face"></div>

                        </div>

                        <div id="approach-p3">
                            <div class="approach-header-flex">
                                <a>
                                    <span>Development Team</span>
                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M13.9104 3.83724L0.888447 16.8592L2.95849 18.9292L15.9715 5.91614L15.9753 12.7102L18.9028 12.7085L18.8961 0.925636L7.08445 0.886087L7.07465 3.81355L13.9104 3.83724Z"
                                            fill="#151915" />
                                    </svg>
                                </a>
                            </div>
                            <p class="approach-p">We build a strong dedicated team to meet a wide range of customers'
                                requirements.</p>
                            <div id="start-right-face"></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- explore -->

    <section class="about-area ptb-80 ptb-100 black-bg">
        <div class="container">
            <div class="time-line-blocks">
                <div class="row align-center align-items-center my-3">
                    <div class="col-md-6">
                        <div class="title-blocks">
                            <h2 class="bluetitle">Explore <span>11+ Years</span> Of</h2>
                            <h2 class="whitetitle">Ideation. Innovation. Transformation</h2>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="imgroted">
                            <img src="assets/img/aboutpage/circleyellow.png" />
                            <h3>11+</h3>
                        </div>
                    </div>
                </div>
                <div class="time-start top-start">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2012</h2>
                                <p><b> Pro Web </b><br />
                                    was launched to cater businesses in staying upto-date with evolving technology in IT
                                    industry.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2013</h2>
                                <p>Team strength Increased to <br /><b>30+</b> designers & developers</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2014</h2>
                                <p>Added New Technology Stack</p>
                                <img src="assets/img/aboutpage/tim1.png" />
                            </div>
                        </div>

                    </div>
                </div>
                <div class="time-start center-1 desktop-v">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2017</h2>
                                <p>Raised team to <br /> 50+ designers & developers</p>
                                <img src="assets/img/aboutpage/team3.png" />
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2016</h2>
                                <p>Awarded with FB Marketing, <br> Big commerce & telr Badge</p>
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3">
                                                <img src="assets/img/aboutpage/tim3.png" />
                                                <img src="assets/img/aboutpage/2016-img.webp" />
                                                <img src="assets/img/aboutpage/2016-img2.webp" />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2015</h2>
                                <p>Introduced In House <br />
                                    Car Rental Brand</p>
                                <div style="background-color: white;
                                padding: 15px;
                                display: inline;
                                border-radius: 25px;">
                                    <img src="assets/img/aboutpage/uae-driving-logo.png" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="time-start center-1 mobile-v">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2015</h2>
                                <p>Introduced In House <br />
                                    Music studio Brand</p>
                                <img src="assets/img/aboutpage/tim2.png" />
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2016</h2>
                                <p>Awarded with <br /> FB Marketing Badge</p>
                                <!-- <img src="assets/img/aboutpage/tim3.png" /> -->
                                <div class="marquee3 bgwhite">
                                    <div class="track3">
                                        <div class="content3">
                                            <img src="assets/img/aboutpage/tim3.png" />
                                            <img src="assets/img/aboutpage/2016-img.webp" />
                                            <img src="assets/img/aboutpage/2016-img2.webp" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2017</h2>
                                <p>Raised team to <br /> 50+ designers & developers</p>
                                <img src="assets/img/aboutpage/team3.png" />
                            </div>
                        </div>

                    </div>
                </div>

                <div class="time-start center-2">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2018</h2>
                                <p>Awarded with Zoho Partner Badge</p>
                                <!-- <img src="assets/img/aboutpage/tim4.png" /> -->
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3">
                                                <img src="assets/img/aboutpage/2018-img1.webp" />
                                                <img src="assets/img/aboutpage/2018-img2.webp" />
                                                <img src="assets/img/aboutpage/2018-img3.webp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2019</h2>
                                <p>Awarded with Google Partner Badge</p>
                                <!-- <img src="assets/img/aboutpage/tim5.png" /> -->
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3">
                                                <img src="assets/img/aboutpage/tim5.png" />
                                                <img src="assets/img/aboutpage/2019-img1.webp" />
                                                <img src="assets/img/aboutpage/2019-img2.webp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2020</h2>
                                <p>Added New
                                    Technology Stack</p>
                                <img src="assets/img/aboutpage/tim6.png" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="time-start center-1 desktop-v">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2023</h2>

                                <p class="mb-4">Gaining pace by adopting the demand for evolving technologies like -
                                    Blockchain, Generative AI & More</p>
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3">
                                                <img src="assets/img/aboutpage/blockchain.png"
                                                    style="max-width: 130px!important;" />
                                                <img src="assets/img/aboutpage/dynamic-365.png"
                                                    style="max-width: 130px!important;" />
                                                <img src="assets/img/aboutpage/artificial-intelligence.png"
                                                    style="max-width: 130px!important;" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2022</h2>
                                <p class="mb-4">Honored as "Top Web Design & Development Company" by GoodFirms</p>
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3 logos-ze">
                                                <img src="assets/img/aboutpage/goof.png" />
                                                <img src="assets/img/aboutpage/2022-img2.webp" />
                                                <img src="assets/img/aboutpage/goof.png" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2021</h2>
                                <p class="mb-4">Honored as “ Top Digital <br /> Marketing Agency” by DesignRush</p>
                                <div class="extradiv-white">
                                    <div class="marquee3 bgwhite">
                                        <div class="track3">
                                            <div class="content3 logos-ze">
                                                <img src="assets/img/aboutpage/design.png" />
                                                <img src="assets/img/aboutpage/2021-img2.webp" />
                                                <img src="assets/img/aboutpage/2021-img3.webp" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="time-start bottom-end desktop-v">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2024</h2>
                                <p class="mb-4">Started our journey in Canada and raised team to 70+ designers and
                                    developers</p>
                                <img src="assets/img/aboutpage/team-2023.png" />

                            </div>
                        </div>
                    </div>
                </div>
                <div class="time-start bottom-end mobile-v">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2021</h2>
                                <p>Honored as “ Top Digital <br /> Marketing Agency” by Prestigious <br /> Platforms
                                </p>
                                <div class="marquee3 bgwhite">
                                    <div class="track3">
                                        <div class="content3 logos-ze">
                                            <img src="assets/img/aboutpage/design.png" />
                                            <img src="assets/img/aboutpage/design.png" />
                                            <img src="assets/img/aboutpage/design.png" />
                                            <img src="assets/img/aboutpage/design.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2022</h2>
                                <p>Honored as "Top Web Design & Development Company" by Prestigious Platforms</p>
                                <div class="marquee3 bgwhite">
                                    <div class="track3">
                                        <div class="content3 logos-ze">
                                            <img src="assets/img/aboutpage/goof.png" />
                                            <img src="assets/img/aboutpage/goof.png" />
                                            <img src="assets/img/aboutpage/goof.png" />
                                            <img src="assets/img/aboutpage/goof.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 bottom">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2023</h2>
                                <p class="mb-4">Started our journey in Canada and raised team to 70+ designers and
                                    developers</p>
                                <img src="assets/img/aboutpage/team-2023.png" />

                            </div>
                        </div>
                        <div class="col-md-4 bottom">
                            <div class="timeli-years">
                                <div class="circle"></div>
                                <h2>2024</h2>
                                <p class="mb-4">Gaining pace by adopting the demand for evolving technologies like -
                                    Blockchain, Generative AI & More</p>
                                <div class="marquee3 bgwhite">
                                    <div class="track3">
                                        <div class="content3">
                                            <img src="assets/img/aboutpage/blockchain.png"
                                                style="max-width: 130px!important;" />
                                            <img src="assets/img/aboutpage/dynamic-365.png"
                                                style="max-width: 130px!important;" />
                                            <img src="assets/img/aboutpage/artificial-intelligence.png"
                                                style="max-width: 130px!important;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- certifications -->
    <section class="about-area ptb-80 ptb-100 ">
        <div class="marquee">
            <div class="track">
                <div class="content">
                    <span *ngFor="let certifications1 of certifications1">{{ certifications1.industryname }}</span>
                </div>
            </div>
        </div>
        <div class="marquee2">
            <div class="track2">
                <div class="content2">
                    <span *ngFor="let certifications2 of certifications2" style="white-space: nowrap;
                -webkit-text-stroke-color: #000;
                -webkit-text-stroke-width: 1px;">{{certifications2.industryname}}</span>
                </div>
            </div>
        </div>
    </section>
    <!--  Specialists -->
    <section class="about-area ptb-80 black-bg  ptb-100">
        <div class="container">
            <div class="about-content-bg black-color">
                <div class="section-title">
                    <h2 class="blue-gradient">70+ In-House Specialists</h2>

                    <div class="about-us-stack__specialists-percent">
                        <div class="about-us-stack__specialists-percent-item">
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="about-us-stack__specialists-cursor specialists-cursor-senior"><img
                                    alt="senior cursor" loading="lazy" width="429" height="237" decoding="async"
                                    data-nimg="1" style="color: transparent; width: 100%; height: auto;"
                                    src="assets/img/aboutpage/seniorCursor.png">
                            </div>
                            <div class="about-us-stack__specialists-percent-item-content h1">55%</div>
                        </div>
                        <div class="about-us-stack__specialists-percent-item">
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="about-us-stack__specialists-cursor specialists-cursor-middle">
                                <img alt="middle cursor" loading="lazy" width="438" height="234" decoding="async"
                                    data-nimg="1" style="color: transparent; width: 100%; height: auto;"
                                    src="assets/img/aboutpage/middleCursor.png">
                            </div>
                            <div class="about-us-stack__specialists-percent-item-content h1">30%</div>
                        </div>
                        <div class="about-us-stack__specialists-percent-item">
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="border-square "
                                style="width:10px;height:10px;border-color:#fff;background-color:#fff">
                            </div>
                            <div class="about-us-stack__specialists-cursor specialists-cursor-junior"><img
                                    alt="junior cursor" loading="lazy" width="438" height="234" decoding="async"
                                    data-nimg="1" style="color: transparent; width: 100%; height: auto;"
                                    src="assets/img/aboutpage/juniorCursor.png">
                            </div>
                            <div class="about-us-stack__specialists-percent-item-content h1">15%</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- Achievements -->
    <section class="about-area ptb-80 ptb-100 mob-bo-0">
        <div class="container">
            <div class="awrods-achive-blog">
                <div class="row align-center">
                    <div class="col-md-6">
                        <div class="section-title">
                            <h2> <span class="pro-txt">Achievements &</span> <br /> <span class="web-txt">Awards</span>
                            </h2>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="gray-block">
                            <div class="gray-box-logo">
                                <img src="assets/img/aboutpage/aa1.png" />
                                <img src="assets/img/aboutpage/aa2.png" />
                                <img src="assets/img/aboutpage/aa3.png" />
                            </div>
                            <p>Featured on Cyber News, Medium, Pro News Report. </p>
                        </div>
                    </div>
                </div>
                <div class="row mar-b-30">
                    <div class="col-md-4">
                        <div class="gray-block">
                            <div class="gray-box-logo flex-image">
                                <img src="/assets/img/awardslogo/clutchnew.png" />
                                <img src="/assets/img/awardslogo/top-web-design-company-in-dubainew.png" />

                            </div>
                            <p>Top Web Design & Development Company
                                According to Clutch & Goodfirms</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-block">
                            <div class="gray-box-logo flex-image">
                                <img src="/assets/img/awardslogo/bestdigitalmarketingnew.png" />
                                <img src="/assets/img/awardslogo/uaebusinessnew.png" />

                            </div>
                            <p>Top Digital Marketing Agency
                                According to Designrush & Mea Markets</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="gray-block flex-center">
                            <div class="gray-box-logo ">
                                <img src="assets/img/aboutpage/google-rating.png" class="mobile-width mob-0" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="gray-block">
                            <div class="gray-box-logo ">
                                <div class="marquee3">
                                    <div class="track3">
                                        <div class="content3">
                                            <img src="/assets/img/aboutpage/t1.png" />
                                            <img src="/assets/img/aboutpage/t2.png" />
                                            <img src="/assets/img/aboutpage/t3.png" />
                                            <img src="/assets/img/aboutpage/t4.png" />
                                            <img src="/assets/img/aboutpage/t5.png" />
                                            <img src="/assets/img/aboutpage/t6.png" />
                                            <img src="/assets/img/aboutpage/t7.png" />
                                            <img src="/assets/img/aboutpage/t8.png" />
                                            <img src="/assets/img/aboutpage/t9.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>Endorsed by Microsoft Certificates for <br /> Outstanding Proficiency</p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="gray-block">
                            <div class="gray-box-logo ">
                                <img src="/assets/img/aboutpage/businessOfApps.png" />
                            </div>
                            <p>Featured On
                                Business <br /> of Apps</p>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="gray-block ">
                            <div class="gray-box-logo margindiff">
                                <img src="assets/img/aboutpage/aw2.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/aw1.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/payfort.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/opencart.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/aw3.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/aw4.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/aw5.png" class="mobile-width" />
                                <img src="assets/img/aboutpage/bigcommerce.png" class="mobile-width" />
                            </div>
                            <p class="margin-top-24">Proud Partner of industry-leading <br /> technology providers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>