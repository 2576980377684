<header *ngIf="!hideHeader" id="header" class="header">
  <div id="navbar" class="startp-nav">
    <nav class="navbar navbar-expand-md py-0 navbar-light">
      <div class="container-fluid mx-0 px-0">
        <a class="navbar-brand px-2" routerLink="/">
          <img
            src="assets/img/webp/homepage/proweb-logo.webp"
            alt="logo"
            class="rpa-logo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="toggleNavbar()"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarSupportedContent"
          [ngClass]="{ active: headerShow }"
        >
          <ul class="navbar-nav nav ml-auto">
            <li (click)="toggleNavbar()" class="nav-item">
              <a routerLink="/" class="nav-link">Home</a>
            </li>
            <li (click)="toggleNavbar()" class="nav-item">
              <a routerLink="/about-us" class="nav-link"> About Us </a>
            </li>
            <li (click)="toggleNavbar()" class="nav-item">
              <a class="nav-link">
                Services
                <i class="fa fa-angle-down phoneicontop" aria-hidden="true"></i>
              </a>
              <ul class="dropdown_menu">
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/web-design-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Web Design</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/web-development-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Web Development</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/ecommerce-development-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >E-Commerce Development</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/mobile-app-development-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Mobile App Development</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/web-hosting-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Web Hosting</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/blockchain-development-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Blockchain Development</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/ai-development/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >AI Development</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/machine-learning/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Machine Learning</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="software-quality-assurance/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    > Quality Assurance
                  </a>
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="seo-company/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Search Engine Optimization
                  </a>
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="adwords-agency/dubai"
                    routerLinkActive="active"
                    class="nav-link"
                    >Google Ads 

                  </a>
                </li>
              </ul>
            </li>

            <li (click)="toggleNavbar()" class="nav-item">
              <a class="nav-link">
                Packages
                <i class="fa fa-angle-down phoneicontop" aria-hidden="true"></i>
              </a>
              <ul class="dropdown_menu">
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/seo-packages"
                    routerLinkActive="active"
                    class="nav-link"
                    >SEO Packages</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/ppc-packages"
                    routerLinkActive="active"
                    class="nav-link"
                  >
                    PPC Packages</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/smm-packages"
                    routerLinkActive="active"
                    class="nav-link"
                    >SMM Packages</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/website-maintenance-packages"
                    routerLinkActive="active"
                    class="nav-link"
                    >Web Maintenance Packages</a
                  >
                </li>
                <li (click)="toggleNavbar()" class="nav-item">
                  <a
                    routerLink="/web-hosting-packages"
                    routerLinkActive="active"
                    class="nav-link"
                    >Web Hosting Packages</a
                  >
                </li>
              </ul>
            </li>

            <li (click)="toggleNavbar()" class="nav-item">
              <a routerLink="/case-study" class="nav-link">Case Studies </a>
            </li>

            <li (click)="toggleNavbar()" class="nav-item">
              <a routerLink="/portfolio" class="nav-link">Our Work</a>
            </li>

            <li (click)="toggleNavbar()" class="nav-item">
              <a routerLink="/hire-dedicated-developers/dubai" class="nav-link">
                Hire Developers
              </a>
            </li>
          </ul>
        </div>

        <div class="anchor-wrapper contact-anchor">
          <a href="/contact-us">
            <span>CONTACT US</span>
          </a>
        </div>

        <div class="anchor-wrapper whatsapp-anchor">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=971526549225&text&type=phone_number&app_absent=0"
          >
            <img src="assets/img/webp/homepage/whatapp-header.webp" alt="" srcset="" />
          </a>
        </div>

        <div class="anchor-wrapper telephone-anchor">
          <a target="_blank" href="tel:+971528050084">
            <img src="assets/img/webp/homepage/telephone-header.webp" alt="" srcset="" />
          </a>
        </div>

        <!-- <div class="others-option header-telephone">
                    <a routerLink="/contact-us" class="nav-link btn btn-primary">
                        <img src="../../assets/img/telephone-header.png" alt="" srcset="">
                    </a>
                </div> -->
      </div>
    </nav>
  </div>
</header>

<!-- mobile -->
<div class="mob-header" *ngIf="!hideHeader">
  <div class="mobile-logo menuclose">
    <a routerLink="/">
      <img src="assets/img/logo/proweblogo.svg" alt="proweb-logo" />
    </a>
  </div>

  <a href="https://wa.me/971526549225" class="enquiry-mdd-trig">
    <img
      src="../../assets/img/header/whatsapp.webp"
      width="25"
      alt=""
      srcset=""
    />
  </a>
  <div class="mobile-nav">
    <button class="toggle-menu">
      <span></span>
    </button>
  </div>
</div>

<div id="mobile-menu">
  <!-- blue main menu -->
  <div class="mainmenu">
    <div class="main-nav">
      <ul class="pt-1 pt-sm-4">
        <li class="lg_size menuclose">
          <a routerLink="/">Home</a>
        </li>
        <li class="lg_size menuclose">
          <a routerLink="/about-us">About Us</a>
        </li>
        <li (click)="serviceVisibility()">
          <span class="servicemenutag">Services</span>
          <span class="arrow-down"></span>
        </li>
        <li (click)="packageVisibility()">
          <span class="packagemenutag">Packages</span>
          <span class="arrow-down"></span>
        </li>
        <li class="menuclose">
          <a routerLink="/hire-dedicated-developers/dubai">Hire Developers</a>
        </li>
        <li class="menuclose">
          <a routerLink="/case-study">Case Studies</a>
        </li>
        <li class="menuclose">
          <a routerLink="/portfolio">Our Work</a>
        </li>
        <li class="menuclose">
          <a routerLink="/contact-us">Contact Us</a>
        </li>
      </ul>

      <div class="bottom-header">
        <div class="bottom-content">
          <div class="d-flex justify-content-evenly">
            <div class="partner-img">
              <img
                src="../../assets/img/header/Google_partner.svg"
                style="width: auto; max-width: 100%"
                alt=""
                srcset=""
              />
            </div>
            <div class="partner-img">
              <img
                src="../../assets/img/header/fb marketing partner.svg"
                width="100%"
                alt=""
                srcset=""
              />
            </div>
            <div class="partner-img">
              <img
                src="../../assets/img/header/zoho.svg"
                width="100%"
                alt=""
                srcset=""
              />
            </div>
            <div class="partner-img">
              <img
                src="../../assets/img/header/shopify.svg"
                width="100%"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <p class="text-white">
            Copyright © 2024 Unisys Technologies L.L.C. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- service menu -->
  <div class="servicemenu" [ngClass]="{ myCSSclass: serviceVisible == false }">
    <div class="main-nav orangenav">
      <div class="bottom-header">
        <div class="service-uae-img">
          <img
            src="../../assets/img/header/uae-driving-orange.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <ul>
        <li>
          <span class="servicehead">Services</span>
          <span
            class="arrow-down"
            (click)="hideService()"
            style="transform: rotate(132deg)"
          ></span>
          <ul class="servicenav menuclose">
            <li class="menuItem">
              <a
                routerLink="/web-design-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Web Design</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/web-development-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Web Development</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/ecommerce-development-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >E-Commerce Development</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/mobile-app-development-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Mobile App Development</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/web-hosting-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Web Hosting</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/blockchain-development-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Blockchain Development</a
              >
            </li>
            <li class="menuItem">
                <a
                  routerLink="/ai-development/dubai"
                  routerLinkActive="active"
                  class="nav-link"
                  >AI Development</a
                >
              </li>
            <li class="menuItem">
              <a
                routerLink="/machine-learning/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Machine Learning</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/software-quality-assurance/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Quality Assurance</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/seo-company/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Search Engine Optimization</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/adwords-agency/dubai"
                routerLinkActive="active"
                class="nav-link"
                >Google Ads 
                </a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <!-- package menu -->
  <div class="servicemenu" [ngClass]="{ myCSSclass: packageVisible == false }">
    <div class="main-nav orangenav">
      <div class="bottom-header">
        <div class="pckage-uae-img">
          <img
            src="../../assets/img/header/uae-driving-orange.svg"
            alt=""
            srcset=""
          />
        </div>
      </div>

      <ul>
        <li>
          <span class="packgehead">Packages</span>
          <span
            class="arrow-down"
            (click)="hidePackage()"
            style="transform: rotate(132deg)"
          ></span>
          <ul class="servicenav menuclose">
            <li class="menuItem">
              <a
                routerLink="/seo-packages"
                routerLinkActive="active"
                class="nav-link"
                >SEO Packages</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/ppc-packages"
                routerLinkActive="active"
                class="nav-link"
                >PPC Packages</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/smm-packages"
                routerLinkActive="active"
                class="nav-link"
                >SMM Packages</a
              >
            </li>
            <li class="menuItem">
              <a
                routerLink="/website-maintenance-packages"
                routerLinkActive="active"
                class="nav-link"
                >Web Maintenance Packages
              </a>
            </li>
            <li class="menuItem">
              <a
                routerLink="/web-hosting-packages"
                routerLinkActive="active"
                class="nav-link"
                >Web Hosting Packages</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
  