<div class="main-banner career-main-banner">
	<div class="d-table">
		<div class="d-table-cell">
			<div class="container">
				<div class="row h-100 justify-content-center align-items-center">
					<div class="col-lg-6">
						<div class="hero-content">
							<h1 class="text-center text-md-start">Embark on your journey with
								<strong class="secondary-color">Pro Web</strong>
							</h1>
							<p>Our success is a collective effort of diverse and knowledgeable minds coming together to
								form a team that consistently delivers excellence. We prioritize talent and capabilities
								over mere credentials, and we're committed to mutual growth for those who become part of
								our team. At Pro Web, we embrace an evolution that's achieved together.</p>

							<div class="text-center text-md-start my-3 my-md-0">
								<a (click)="scroll(job)" class="btn btn-primary bop-btn">See Job Openings</a>
							</div>
						</div>
					</div>
					<div class="col-lg-6 col-md-12 services-left-image" *ngIf="isDesktop">
						<img src="assets/img/services-left-image/big-monitor.png" class="wow fadeInDown"
							data-wow-delay="0.6s" alt="big-monitor">
						<img src="assets/img/services-left-image/creative.png" class="wow fadeInUp"
							data-wow-delay="0.6s" alt="creative">
						<img src="assets/img/services-left-image/developer.png" class="wow fadeInLeft"
							data-wow-delay="0.6s" alt="developer">
						<img src="assets/img/services-left-image/flower-top.png" class="wow zoomIn"
							data-wow-delay="0.6s" alt="flower-top">
						<img src="assets/img/services-left-image/small-monitor.png" class="wow bounceIn"
							data-wow-delay="0.6s" alt="small-monitor">
						<img src="assets/img/services-left-image/small-top.png" class="wow fadeInDown"
							data-wow-delay="0.6s" alt="small-top">
						<img src="assets/img/services-left-image/table.png" class="wow zoomIn" data-wow-delay="0.6s"
							alt="table">
						<img src="assets/img/services-left-image/target.png" class="wow fadeInUp" data-wow-delay="0.6s"
							alt="target">

						<img src="assets/img/services-left-image/main-pic.png" class="wow fadeInUp"
							data-wow-delay="0.6s" alt="main-pic">
					</div>

				</div>
			</div>
		</div>
	</div>
</div>

<section class="vacancy-sec">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="vacancy-head">
					<h2 class="mb-4" style="font-size: 35px;">
						Welcome to <b class="secondary-color">Pro Web </b>
					</h2>
					<p>We are keen on welcoming the ignited minds to achieve and work with our intelligent and
						hard-working team to produce the never-before achieved results by anyone. We believe its each
						member of the team that makes it possible.We're excited to invite passionate minds to join our
						dynamic and dedicated team, where together, we aim to accomplish extraordinary results. We
						firmly believe that it's the combined effort of each team member that paves the way for our
						success. </p>
					<strong>You can just be one among us and make it awesome!</strong>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<h2 class="titles"><span class="jobtitle">Find Your Dream Job Here</span></h2>
				<div class="typing">
					<ngx-typed-js [strings]="['Join Pro Web Family', 'Join Pro Web Family', 'Join Pro Web Family']"
						[loop]="true" [typeSpeed]="50" [backSpeed]="50">
						<p class="typing"></p>
					</ngx-typed-js>
				</div>

				<!-- <h3 class="jointest">Join <b class="secondary-color">RPA </b> Family</h3> -->
				<ul class="vacancy-block-list" #job>
					<li *ngFor="let joblist of careerJobsList" class="li-">

						<div class="vacancy-block row">

							<div class="col-md-2">
								<div class="imagejob">
									<img src="/assets/img/proweb-logo.png" class="img-job" />
								</div>
							</div>

							<div class="col-md-7">
								<div class="carrer-details-block">
									<h5 class="vacancy-designation" *ngIf="joblist.title">{{joblist.title}}</h5>

									<p class="secondary-color" *ngIf="joblist.profile_id">
										<b>Profile ID :&nbsp; {{joblist.profile_id}}</b>
									</p>

									<p *ngIf="joblist.experience">
										<b>Experience:&nbsp;{{joblist.experience}}</b>
									</p>

									<p class="vacancy-skills" *ngIf="joblist.skills">
										<b>Skills:</b>&nbsp;{{joblist.skills}}
									</p>
									<p *ngIf="joblist.location">
										<b>Location :&nbsp; {{joblist.location}}</b>
									</p>
								</div>
							</div>

							<div class="col-md-3">
								<a [routerLink]="['/careers-details', joblist.slug]" class="btn btn-primary ">Know
									More</a>
							</div>

						</div>


					</li>
				</ul>
			</div>
		</div>
	</div>
</section>


<!-- top reasons -->
<!-- <section class="feedback-area ptb-80 bg-f7fafd">
	<div class="container">
		<div class="section-title">
			<h2>Top Reasons to <span class="worktext">Work</span> <span class="attext"> at <span class="rpatattext">Pro
						Web</span></span></h2>
			<div class="bar"></div>
			<div
				style="display:flex; justify-content: center; align-items: center; margin-top:5px;     margin-bottom: 30px;">
				<img src="assets/img/Star.png" alt="">
				<img src="assets/img/Star.png" alt="">
				<img src="assets/img/Star.png" alt="">
				<img src="assets/img/Star.png" alt="">
				<img src="assets/img/Star.png" alt="">
				<span style="margin-bottom: 0px; margin-left: 5px;"><b>4.9</b> rating out of 30 reviews</span>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<owl-carousel-o class="owl-carousel rpatestmonial" [options]="customOptions">
					<ng-template carouselSlide *ngFor="let teamTestimonial of teamTestimonials">
						<div class="item">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 googlebox">
								<div class="team-testr-div">
									<div class="col-md-12  testi-con-main">
										<div class="testi-con">
											<div class="letter">
												<h2>{{teamTestimonial.namefirst}}</h2>
											</div>
											<div style="margin-left:20px;">
												<h6 class="slid-name testi-name">{{teamTestimonial.name}}</h6>
												<div style="display:flex; margin-top:5px;">
													<img src="assets/img/Star.png" alt="">
													<img src="assets/img/Star.png" alt="">
													<img src="assets/img/Star.png" alt="">
													<img src="assets/img/Star.png" alt="">
													<img src="assets/img/Star.png" alt="">
												</div>
											</div>
										</div>
										<img src="assets/img/G-logo.png" alt="">
									</div>
									<p class="team-testr-p1">{{teamTestimonial.feedback}}</p>
								</div>
							</div>
						</div>
					</ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</div>

</section> -->


<!-- testimonial -->
<section class="feedback-area bg-testimonial">
	<div class="container">
		<div class="section-title">
			<h2>Benefits Of Working <span class="attext"> At <span class="rpatattext"> Pro Web </span></span></h2>
			<div class="bar"></div>

		</div>
	</div>
	<div class="marquee2">
		<div class="track2">
			<div class="content2">
				<span *ngFor="let benifits of benifits" style="text-transform: capitalize;"><img
						src="assets/img/aboutpage/whitestar.png" />{{benifits.name}}</span>
			</div>
		</div>
	</div>
	<div class="rating-div">
		<!-- <img src="assets/img/Star.png" alt="">
		<img src="assets/img/Star.png" alt="">
		<img src="assets/img/Star.png" alt="">
		<img src="assets/img/Star.png" alt="">
		<img src="assets/img/Starhalp.png" alt=""> -->
		<!-- <span class="rating-div-span"><b>4.9</b> Google </span> -->
		<!-- <span class="downarrow" (click)="arrowDown()">Check our Reviews</span> -->
	</div>

	<div class="row" *ngIf="showReview">
		<div class="col-md-12">
			<ngx-slick-carousel class="carousel" [config]="carouselConfig1">
				<div ngxSlickItem *ngFor="let teamTestimonial of review">
					<div class="item">
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 googlebox">
							<div class="team-testr-div">
								<div class="col-md-12  testi-con-main">
									<div class="testi-con">
										<div class="letter">
											<h2>{{teamTestimonial.namefirst}}</h2>
										</div>
										<div style="margin-left:20px;">
											<h6 class="slid-name testi-name">{{teamTestimonial.name}}</h6>
											<div style="display:flex; margin-top:5px;">
												<img src="assets/img/Star.png" alt="">
												<img src="assets/img/Star.png" alt="">
												<img src="assets/img/Star.png" alt="">
												<img src="assets/img/Star.png" alt="">
												<img src="assets/img/Star.png" alt="">
											</div>
										</div>
									</div>
									<img src="assets/img/G-logo.png" alt="">
								</div>
								<p class="team-testr-p1">{{teamTestimonial.feedback}}</p>
							</div>
						</div>
					</div>
				</div>
			</ngx-slick-carousel>
		</div>
	</div>



</section>



<!-- <app-instagram-feed></app-instagram-feed> -->