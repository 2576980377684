import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title, Meta } from '@angular/platform-browser';

// import owl carousel
import {OwlCarousel} from 'ngx-owl-carousel';


@Component({
  selector: 'app-qa-testing',
  templateUrl: './qa-testing.component.html',
  styleUrls: ['./qa-testing.component.css']
})
export class QaTestingComponent implements OnInit {

  public images:any=[];
  
  isMobile = true;
  isDesktop = true;

  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    if (width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  @ViewChild('owlElement', {static: true}) owlElement: OwlCarousel;
  // Meta Title
  title = 'QA & Testing - Pro Web';		

  constructor(
  	private titleService: Title,
    private metaTagService: Meta
  ) { }
  scroll(el: HTMLElement) {
		el.scrollIntoView();
	  }
  ngOnInit() {

  	// Meta Title & Description
      this.titleService.setTitle(this.title);
      this.metaTagService.updateTag(
        { name: 'description', content: 'QA & Testing page description here!' }
      );
    // Meta Title & Description

    /* Generate code according to resolution open here */

      if (window.screen.width < 768) {
        this.isMobile = true;
        this.isDesktop = false;
      }
      else{
        this.isMobile = false;
        this.isDesktop = true;
      }

    /* Generate code according to resolution close here */ 	  	

  }

  OwSlideOpt = { 
    stagePadding: 200,
    margin: 50,
    startPosition: 0,
    center: true,
    autoWidth: true,
    loop: true,
    slideTransition: 'linear',
    responsiveClass: true,
    smartSpeed: 650,
    responsive: {
      0: {
        items: 1,
        startPosition: 0,
        stagePadding: 10,
        autoWidth: false,
        dots: true,
        smartSpeed: 250,
      },
      600: {
        stagePadding: 10,
        items: 1,
        margin: 10,
        startPosition: 0,
        autoWidth: false,
      },
      991: {
        center: false
      },
      992: {
        items: 3
      }
    }
  };  

 /* Our work slider script open */  
 owlnextslide() {
  this.owlElement.next([200]);

  var itmescount = 9;

  var currentIndex = $('div.owl-item.active.center').index();
  var currentitem  = parseInt(currentIndex+'') - 4;

  $('#showcount').html(''+currentitem+'/'+itmescount+'');
  }
  
  owlprevslide() {
  this.owlElement.previous([200]);

  var itmescount = 9;

  var currentIndex = $('div.owl-item.active.center').index();
  
  if(currentIndex==4) {
    var currentitem = parseInt(currentIndex+'') + 5;
  } else {
    var currentitem = parseInt(currentIndex+'') - 4;
  }
  $('#showcount').html(''+currentitem+'/'+itmescount+'');
  }

/* Our work slider script close */
currentTab: any = '';

	@HostListener('window:scroll', ['$event'])
	onWindowScroll() {
		console.log(window.pageYOffset)
		if(window.pageYOffset > 570)
		{
			let element = document.getElementById('webdevelopment');
			element && element.classList.add('is-sticky');
		} 
		else 
		{
			let element = document.getElementById('webdevelopment');
			element && element.classList.remove('is-sticky');
		}

		if(window.pageYOffset > 570)
		{
			this.currentTab = 1;
		}
		if(window.pageYOffset > 1600)
		{
			this.currentTab = 2;
		}
		if(window.pageYOffset > 2478)
		{
			this.currentTab = 3;
		}
		if(window.pageYOffset > 3278)
		{
			this.currentTab = 4;
		}
		if(window.pageYOffset > 4441)
		{
			this.currentTab = 5;
		}
    if(window.pageYOffset > 5341)
		{
			this.currentTab = 6;
		}
	}
}
