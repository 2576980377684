import { Component, OnInit, ViewEncapsulation, HostListener, ChangeDetectorRef } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LoadingService } from '../utils/services/loading.service';
import { ActivatedRoute } from '@angular/router';
import { Renderer2 } from '@angular/core';


// import Jquery
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('fade',
      [
        state('void', style({ opacity: 0 })),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)])
      ]
    )
  ],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})

export class HeaderComponent implements OnInit {

  isMobile: boolean | undefined;
  isLoading: boolean = false;
  public show: boolean = false;
  public buttonName: any = 'Show';

  hideHeader: boolean = false;

  // service menu
  serviceVisible: boolean = true;
  packageVisible: boolean = true;


  serviceVisibility() {
    this.serviceVisible = !this.serviceVisible;
  }
  hideService() {
    this.serviceVisible = !this.serviceVisible;
  }

  packageVisibility() {
    this.packageVisible = !this.packageVisible;
  }
  hidePackage() {
    this.packageVisible = !this.packageVisible;
  }


  // -------------------------

  schema = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": "RPA Technology PVT LTD",
    "telephone": "+91-9993784555",
    "email": "admin@rpatechnologies.in",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": " 232, Zone-2 MP Nagar, Bhopal, Madhya Pradesh, 462011 ",
      "addressLocality": "Bhopal",
      "addressCountry": "India"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "4",
      "ratingCount": "100"
    }
  };


  public navbarOpen = false;
  public clicked = false;
  _el: any;
  location: any;
  layoutClass: any;
  headerShow: boolean = false;
  isElementVisible = false;
  mobilemenuone: boolean = false;
  mobilemenutwo: boolean = false;
  mobilemenuthree: boolean = false;


  toggleNavbar = () => {
    this.navbarOpen = !this.navbarOpen;
  }

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    location: Location,
    private cdr: ChangeDetectorRef,

    private route: ActivatedRoute,

    private renderer: Renderer2

  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.location = location.path();
        if (this.location == '/web-hosting') {
          this.layoutClass = 'p-relative';
        } else {
          this.layoutClass = '';
        }
      }
    });
  }



  // toggleMenu() {
  //   const body = document.body;
  //   if (body.style.overflow === 'hidden') {
  //     this.renderer.removeStyle(body, 'overflow');
  //   } else {
  //     this.renderer.setStyle(body, 'overflow', 'hidden');
  //   }
  // }



  ngOnInit() {
    this.loadingService.loading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cdr.detectChanges(); // Manually trigger change detection
    });


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideHeader = event.url === '/thankyou'; // Adjust path as needed
      }
    });


    $(document).ready(function () {
      $('.enquiry-mdd-trig').show();
      $('.toggle-menu').click(function () {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
          $('#mobile-menu').toggleClass("open");
          $('body').removeClass('overflow-hiden');
          $('html').removeClass('overflow-hiden');
          $('.enquiry-mdd-trig').show();
          $("html").css("overflow-y", "scroll");
        } else {
          $(this).addClass('close-menu');
          $(this).toggleClass("active");
          $('body').addClass('overflow-hiden');
          $('html').addClass('overflow-hiden');
          $('#mobile-menu').toggleClass("open");
          $('.enquiry-mdd-trig').hide();
          $("html").css("overflow-y", "hidden");
        }

      });

      $('.menuclose').click(function () {
        $('.toggle-menu').removeClass("active");
        $('#mobile-menu').removeClass("open");
        $('.enquiry-mdd-trig').show();
        $('body').removeClass('overflow-hiden');
        $('html').removeClass('overflow-hiden');
        $("html").css("overflow-y", "scroll");
      });
      $('.menuItem').click(function () {
        $('.toggle-menu').removeClass("active");
        $('#mobile-menu').removeClass("open");
        $("html").css("overflow-y", "scroll");
      });
      $('.enquiry-mdd-trig').click(function () {
        $('.enquiry-mdd').toggleClass("open");
      });

      $('body,html').click(function (e) {
        var container = $(".enquiry-mdd-trig");

        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $('.enquiry-mdd').removeClass("open");
        }
      });

    });

  }



  toggle() {
    this.show = !this.show;
    if (this.show)
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }

  // mobile menu dropdown

  companyMobile() {
    this.mobilemenuone = !this.mobilemenuone
  }

  serviceMobile() {
    this.mobilemenutwo = !this.mobilemenutwo
  }

  packageMobile() {
    this.mobilemenuthree = !this.mobilemenuthree
  }

  // mobile menu dropdown


  onClick(event: FocusEvent): void {
    if (event) {

      event.preventDefault();
      event.stopPropagation();
    }
    this.clicked = true;
  }

  @HostListener('window:click', ['$event'])
  clickedOutside(): void {
    if (this.clicked) {
      this._el.nativeElement.querySelector('dropdown-menu').classList.toogle('show')
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (window.pageYOffset > 100) {
      let element = document.getElementById('header');
      element && element.classList.add('is-sticky');
    } else {
      let element = document.getElementById('header');
      element && element.classList.remove('is-sticky');
    }
  }

  // header hamburgar
  toggleNav() {
    this.headerShow = !this.headerShow
  }


  // get quote


  toggleElement() {
    this.isElementVisible = !this.isElementVisible;
  }


}
