import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

	isMobile = true;
	isDesktop = true;

	@HostListener("window:resize", [])
	onResize() {
		var width = window.innerWidth;
		if (width < 768) {
			this.isMobile = true;
			this.isDesktop = false;
		} else {
			this.isMobile = false;
			this.isDesktop = true;
		}
	}

	ourworks: any = [];
	id : string;

	serverUrl = environment.baseUrl;
	apiurl = environment.apiUrl;
	uploadUrl = environment.uploadUrl;

	public sharehref: string = "";

	constructor(
		private httpClient: HttpClient,
		private route: ActivatedRoute) {  }

	ngOnInit() {

		/* Generate code according to resolution open here */

	      if (window.screen.width < 768) {
	        this.isMobile = true;
	        this.isDesktop = false;
	      }
	      else{
	        this.isMobile = false;
	        this.isDesktop = true;
	      }

	    /* Generate code according to resolution close here */
		
		$('.popup-youtube').magnificPopup({
			disableOn: 320,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false
		});
    
		$('.popup-btn').magnificPopup({
			type: 'image',
			gallery:{
				enabled:true
			}
		});

		this.httpClient.get(this.apiurl+'work_detail/'+this.id).subscribe(async data => {
			let ClientsData: any = data;
			
			this.ourworks = ClientsData;
			// console.log(this.works['title']);
		});

		this.sharehref = window.location.href;
 	}

}
