<section class="spacing">
    <!-- <div class="container-md"> -->
    <div class="row align-items-center">

        <div class="col-md-9">


            <div class="bgimg">

                <div class="contactImg">
                    <img src="../../assets/img/servicesdev/bottombg.webp" alt="contact" srcset="">
                </div>

                <div class="textwrap">
                    <h5 *ngIf="currentRoute == '/seo-company/dubai'">Need something beyond our standard SEO packages?
                    </h5>
                    <h3>{{text}}</h3>
                </div>

            </div>

        </div>
        <div class="col-md-3">
            <div class="mobilecontact">
                <a routerLink="/contact-us">
                    <div class="circlewrap">
                        <img src="../../assets/img/servicesdev/circleimg.webp" alt="" srcset="">
                        <div class="textcircle">
                            Contact Us
                        </div>
                    </div>
                </a>
            </div>

        </div>

    </div>
    <!-- </div> -->
</section>

<hr>