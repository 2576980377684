<section class="iot-main-banner">
    <div class="container">
        <div class="iot-banner-content">
            <span>Internet of Things</span>
            <h2>We get it! IoT growth is happening</h2>
            <p>At this point, it may seem like overkill to point out the continued growth bound to happen in the Internet of Things space for the years to come and how it will create new opportunities for companies, both established and new, from a hardware and software perspective.</p>
            <a href="#" class="btn btn-primary">Get Started</a>
        </div>

        <div class="iot-banner-image">
            <img src="assets/img/iot-banner-image/1.png" class="wow fadeInUp" data-wow-delay="0.8s" alt="image">
            <img src="assets/img/iot-banner-image/2.png" class="wow zoomIn" alt="image">
        </div>

        <div class="animate-border">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
</section>



<section class="iot-services-area ptb-80">
    <div class="container">
        <div class="section-title">
            <h2>Our Featured Services that We Provide</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-software"></i>
                    </div>

                    <h3>Software Development <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-gear"></i>
                    </div>

                    <h3>Smart Home, Office <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-skyline"></i>
                    </div>

                    <h3>Smart City <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-car-insurance"></i>
                    </div>

                    <h3>Automative <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-factory"></i>
                    </div>

                    <h3>Industrial <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-services">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>

                    <h3>System on Chip <br>IoT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>

                    <a href="#"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="iot-cta-area bg-0f054b">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="cta-iot-img">
                    <img src="assets/img/cta-shape2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="cta-iot-content">
                    <h3>Connect and Manage your IoT at Scale</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a href="#" class="btn btn-primary">Get Started!</a>
                </div>
            </div>
        </div>
    </div>

    <div class="circle-box"><img src="assets/img/circle.png" alt="image"></div>
    <div class="cta-shape"><img src="assets/img/cta-shape.png" alt="image"></div>
</section>



<section class="iot-features-area ptb-80 bg-f7fafd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 iot-features-content">
                <h3>App Development for Connected Devices</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>

                <a href="#" class="btn btn-primary">Explore More</a>
            </div>

            <div class="col-lg-6 iot-features-image">
                <img src="assets/img/iot-features-image/1.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
            </div>
        </div>
    </div>
</section>

<section class="iot-features-area ptb-80">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 iot-features-image">
                <img src="assets/img/iot-features-image/2.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="image">
            </div>

            <div class="col-lg-6 iot-features-content">
                <h3>How Can Your City Use IoT Technology?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>

                <a href="#" class="btn btn-primary">Explore More</a>
            </div>
        </div>
    </div>
</section>

<section class="team-area iot-team-area ptb-80 bg-f9f6f6">
    <div class="container">
        <div class="section-title">
            <h2>Our Awesome Team</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/team-image/1.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Josh Buttler</h3>
                            <span>CEO & Founder</span>
                        </div>

                        <ul>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                        </ul>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/team-image/2.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Alex Maxwel</h3>
                            <span>Marketing Manager</span>
                        </div>

                        <ul>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                        </ul>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/team-image/3.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Janny Cotller</h3>
                            <span>Web Developer</span>
                        </div>

                        <ul>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                        </ul>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="team-image">
                        <img src="assets/img/team-image/4.jpg" alt="image">
                    </div>

                    <div class="team-content">
                        <div class="team-info">
                            <h3>Jason Statham</h3>
                            <span>UX/UI Designer</span>
                        </div>

                        <ul>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                            <li><a href="#"></a></li>
                        </ul>

                        <p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="iot-why-choose-us pt-80">
    <div class="container">
        <div class="section-title">
            <h2>Our Core Approach</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-iot-box">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h3>Digital Technology</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-iot-box">
                    <div class="icon">
                        <img src="assets/img/icon2.png" alt="image">
                    </div>
                    <h3>Business Protection</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    <a href="#"></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-iot-box">
                    <div class="icon">
                        <img src="assets/img/icon3.png" alt="image">
                    </div>
                    <h3>Data Analysis</h3>
                    <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    <a href="#"></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-80">
    <div class="container">
        <div class="section-title">
            <h2>The News from Our Blog</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/1.jpg" alt="image">
                        </a>

                        <div class="date">
                            March 15, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">The security risks of changing package owners</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/2.jpg" alt="image">
                        </a>

                        <div class="date">
                             March 17, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a href="#">
                            <img src="assets/img/blog-image/3.jpg" alt="image">
                        </a>

                        <div class="date">
                             March 19, 2019
                        </div>
                    </div>

                    <div class="blog-post-content">
                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="read-more-btn">Read More </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
