import { Component , ViewChild, ElementRef, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css']
})
export class BottomBarComponent {
@Input() text : any;

currentRoute: any = '';


@ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;
constructor(private router: Router) {
  this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      this.currentRoute = event.url;
    }
  });
}


}
