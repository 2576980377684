import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
	selector: 'app-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
	isHovered = false;
	classnamenew = '';
	constructor(
		private titleService: Title,
		private metaTagService: Meta,
	) { }

	ngOnInit() {
		// Meta Title & Description
		this.titleService.setTitle("About Us - Trusted Software Development Solutions | Pro Web");
		this.metaTagService.updateTag(
			{ name: 'description', content: "Experience visionary web and app solutions with Pro Web, your bridge from ideas to reality. Unlock business growth through innovation." }
		);
		this.metaTagService.updateTag(
			{ name: "keywords", content: "about us, about our company, about pro web" }
		);
		// Meta Title & Description
	}

	// certifications
	certifications1 = [
		{
			industryname: ' React.js ',
		},
		{
			industryname: ' Node.js ',
		},
		{
			industryname: 'Python ',
		},
		{
			industryname: ' OpenCart  ',
		},
		{
			industryname: 'Android ',
		},
		{
			industryname: ' Angular ',
		},
		{
			industryname: ' JavaScript ',
		},
		{
			industryname: ' Magento ',
		},
		{
			industryname: ' Dynamics365 ',
		},
		{
			industryname: ' Java ',
		},
		{
			industryname: ' Golang  ',
		},
		{
			industryname: ' aws ',
		}
	]

	certifications2 = [
		{
			industryname: '  iOS ',
		},
		{
			industryname: ' Laravel  ',
		},
		{
			industryname: '  ASP.NET  ',
		},
		{
			industryname: ' Salesforce  ',
		},
		{
			industryname: '  PowerApps  ',
		},
		{
			industryname: '  ReactNative  ',
		},
		{
			industryname: '  PHP  ',
		},
		{
			industryname: '  DigitalOcean  ',
		},
		{
			industryname: '  Shopify  ',
		},
		{
			industryname: '  Django   ',
		},
		{
			industryname: '  Vue.js  ',
		}
	]

	onMouseEnter(e: any) {
		this.isHovered = true;
		if (e == 1) {
			this.classnamenew = "headtop";
		}
		if (e == 2) {
			this.classnamenew = "headleft";
		}
		if (e == 3) {
			this.classnamenew = "headright";
		}
	}
	onMouseLeave(e: any) {
		this.isHovered = false;
		if (e == 1) {
			this.classnamenew = "";
		}
		if (e == 2) {
			this.classnamenew = ""
		}
		if (e == 3) {
			this.classnamenew = "";
		}
	}
}
