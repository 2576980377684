



<div class="cate">
    <h5 class="p-3 text-white fw-bold" style="background: linear-gradient(94.02deg, #F7993B 26%, #2D6FA6 107.22%);">BLOG CATEGORIES</h5>
    <ul class="list p-0">
        <!-- <li><a [routerLink]="['/blog']">ALL</a></li> -->
        <li > 
            <a class="d-flex justify-content-between align-items-center" [routerLink]="['/blog']">  
                <div class="d-flex align-items-center">
                    <img src="../../assets/img/blog-image/categories-img.png" alt="" srcset="">  
                    <p class="mx-3" style="color: #2D6FA6; font-weight: 600;"> ALL</p> 
                </div>
                <div class="circle">
                    <span>{{totalBlogCount}}</span>
                </div>
            </a>
        </li>
        <li *ngFor="let item of blogCategory"> 
            <a class="d-flex justify-content-between align-items-center"
                (click)="changeBlogCategory(item.url)">  
                <div class="d-flex align-items-center">
                    <img src="{{item.image}}" alt="" srcset="">  
                    <p class="mx-3" style="color: #2D6FA6; font-weight: 600;"> {{item.title}}</p> 
                </div>
                <div class="circle">
                    <span>{{item.blog_count}}</span>
                </div>
            </a>
        </li>
    </ul>
</div>
<br>
<div class="cate2">
    <!-- <h5 class="fw-bold"> <span style="color: #FE942F;">Request</span> a Free Quote</h5> -->
    <h5 class="p-3 text-white fw-bold" style="background: linear-gradient(94.02deg, #F7993B 26%, #2D6FA6 107.22%);">Request a Free Quote</h5>
    <p class="px-3 py-1">Guaranteed response within one business day</p>
    <app-alert></app-alert>
    <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
    <form action="" class="my-3 p-3" method="POST" novalidate="novalidate" [formGroup]="blogForm"
        (ngSubmit)="onSubmit($event)" #blogFormNew="ngForm" id="blogForm">
        <div class="mb-3">
            <input type="text" placeholder="Name*" class="form-control" aria-describedby="emailHelp" name="name"
                formControlName="name" [ngClass]="{ 'is-invalid': submitted && fn.name.errors }">
            <div *ngIf="submitted && fn.name.errors" class="invalid-feedback">
                <div *ngIf="fn.name.errors.required">Please, enter your name</div>
            </div>
        </div>
        <div class="mb-3">
            <input type="email" placeholder="Email*" class="form-control" aria-describedby="emailHelp" name="email"
                formControlName="email" [ngClass]="{ 'is-invalid': submitted && fn.email.errors }">
            <div *ngIf="submitted && fn.email.errors" class="invalid-feedback">
                <div *ngIf="fn.email.errors.required">Please, enter a valid email</div>
                <div *ngIf="fn.email.errors.email">Please, enter a valid email</div>
            </div>
        </div>
        <div class="mb-3">
            <div class="d-flex mb-2 countrycontact">
                <select name="country_code" formControlName="country_code" class="ctry-select form-control"
                style="width: 100px;">
                    <option *ngFor="let country of countries"
                        [attr.data-countryCode]="country.country_code"
                        value="{{country.phone_code}}">+{{ country.phone_code }}</option>
                </select>
                <input type="text" inputmode="numeric" placeholder="Contact No." class="form-control"
                    name="phone_number" formControlName="phone_number" (keypress)="numberOnly($event)"
                    [ngClass]="{ 'is-invalid': submitted && fn.phone_number.errors }"
                    (countryChange)="countryChange($event)" />
            </div>
            <div *ngIf="submitted && fn.phone_number.errors" class="invalid-feedback">
                <div *ngIf="fn.phone_number.errors.required">Please, enter Phone number</div>
            </div>
        </div>
        <div class="mb-3">
            <input type="text" placeholder="Existing Business URL (if any)" class="form-control"
                aria-describedby="emailHelp" name="business_url" formControlName="business_url">
        </div>
        <select class="form-select" aria-label="Default select example" name="service" formControlName="service">
            <option value="" selected>Select your service*</option>
            <option *ngFor="let service of services" value="{{service.title}}">{{ service.title }}</option>
        </select>
        <br>
        <div class="mb-3">
            <textarea name="message" formControlName="message" cols="30" rows="6" class="border-secondary"
                placeholder="Write your message here.." style="width: 100%;"></textarea>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-small d-block btn-primary"> <span class="mx-4">Submit</span>  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 8C0 7.76429 0.0827751 7.53822 0.230116 7.37155C0.377456 7.20487 0.577293 7.11124 0.785664 7.11124L19.3163 7.11124L14.3714 1.51913C14.2238 1.35225 14.141 1.1259 14.141 0.889886C14.141 0.653873 14.2238 0.427527 14.3714 0.260641C14.5189 0.0937548 14.719 0 14.9276 0C15.1363 0 15.3363 0.0937548 15.4839 0.260641L21.7692 7.37076C21.8423 7.45331 21.9004 7.55139 21.94 7.65937C21.9796 7.76734 22 7.8831 22 8C22 8.1169 21.9796 8.23266 21.94 8.34063C21.9004 8.44861 21.8423 8.54669 21.7692 8.62925L15.4839 15.7394C15.3363 15.9062 15.1363 16 14.9276 16C14.719 16 14.5189 15.9062 14.3714 15.7394C14.2238 15.5725 14.141 15.3461 14.141 15.1101C14.141 14.8741 14.2238 14.6478 14.3714 14.4809L19.3163 8.88876H0.785664C0.577293 8.88876 0.377456 8.79513 0.230116 8.62845C0.0827751 8.46177 0 8.23571 0 8Z" fill="white"/>
                </svg> </button>
        </div>
    </form>
</div>

<!-- <div class="cate my-3">
    <h5 class="bg-dark text-white p-3">FEATURED BLOGS</h5>
    <div id="carouselExampleIndicators2" class="carousel slide blogslider" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button *ngFor="let item of featuredBlogs; let i = index" type="button"
                data-bs-target="#carouselExampleIndicators2" [attr.data-bs-slide-to]="i" [class.active]="i === 0"
                aria-current="true" [attr.aria-label]="i"></button>
        </div>
        <div class="carousel-inner">
            <div *ngFor="let item of featuredBlogs; let i = index" class="carousel-item" [class.active]="i === 0">
                <a [routerLink]="['/blog', item.url]">
                    <img src="{{item.image}}" class="d-block w-100" alt="...">
                    <div class="carousel-caption d-none d-md-block">
                        <h5 class="text-start my-2">{{item.title}}</h5>
                        <div class="d-flex justify-content-between my-2">
                            <div class="text-secondary">
                                <i class="fa fa-user me-2"></i> <span>Pro Web</span>
                            </div>
                            <div class="text-secondary">
                                <i class="fa fa-calendar me-2"></i><span>{{item.post_date}}</span>
                            </div>
                        </div>
                        <p class="text-start">{{item.short_description}}</p>
                    </div>
                </a>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators2"
            data-bs-slide="prev" *ngIf="featuredBlogs.length > 1">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators2"
            data-bs-slide="next" *ngIf="featuredBlogs.length > 1">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</div> -->