import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoadingService } from '../utils/services/loading.service';

// import jquery
import * as $ from 'jquery';

@Component({
  selector: 'app-bookmypet',
  templateUrl: './caseStudyDetail.component.html',
  styleUrls: ['./caseStudyDetail.component.css'],
})
export class CaseStudyDetailComponent implements OnInit {
  isMobile = true;
  isDesktop = true;
  id: string;
  caseStudyProjectData: any;

  onResize() {
    var width = window.innerWidth;
    if (width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }
  }

  // Meta Title
  title = 'Case Studies - Pro Web';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);

    const qdblogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/ql1.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ql2.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ql3.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ql4.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ql5.webp',
      },
    ];

    const kalachainlogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/kt1.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/kt2.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/kt3.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/kt4.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/kt5.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/kt6.webp',
      },
    ];

    const tools = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reacticoncasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nodeiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/graphqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reduxiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const audilogos = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/shopifylogo.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/graphqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reduxiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const bookmypetlogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reacticoncasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nodeiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/graphqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reduxiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const shakespearelogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/shopifylogo.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nodeiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/graphqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reduxiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const organicmantralogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/angular.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ionic.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const discounterlogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/googlecloud.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/java.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/laravel.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/swift.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const thetravelhblogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysqliconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/aws.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/vue.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/jquery.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/postgreysql.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/skechiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const quillwillogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/figmaiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/illastratureiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reacticoncasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reduxiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/material-ui.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/googlecloud.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nodeiconcasestudiesdetails.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebaseiconcasestudiesdetails.webp',
      },
    ];

    const lamaisontech = [
      {
        img: 'assets/img/webp-image/casestudydetails/corejs-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/php-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/bootarp-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/opencart-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/jquey-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/my-sql-la.webp',
      },
    ];

    const smartdoortech = [
      {
        img: 'assets/img/webp-image/casestudydetails/apche.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/php-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/bootarp-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/drupal.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/jquey-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/my-sql-la.webp',
      },
    ];

    const upsidetech = [
      {
        img: 'assets/img/webp-image/casestudydetails/flutter-upside.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebase.webp',
      },
    ];

    const bezeltechlogo = [
      {
        img: 'assets/img/webp-image/casestudydetails/laravel.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nextjs.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/jquery.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/ql4.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/nginx.webp',
      },

      {
        img: 'assets/img/webp-image/casestudydetails/my-sql-la.webp',
      },
    ];

    const bmwtechstack = [
      {
        img: 'assets/img/webp-image/casestudydetails/jquey-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/shopifylogo.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/bootarp-la.webp',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/my-sql-la.webp',
      },
    ];

    const acaitechstack = [
      {
        img: 'assets/img/webp-image/casestudydetails/laravel-casestudy.svg',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/aws-casstudy.svg',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/firebase-casestudy.svg',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/reactNative-casestudy.svg',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/redux-casestudy.svg',
      },
      {
        img: 'assets/img/webp-image/casestudydetails/mysql-casestudy.svg',
      },
    ];

    const caseStudyData = [
      {
        slug: 'geo-honey',
        herobgimage: 'assets/img/webp-image/casestudydetails/bgImage.webp',
        heroTitleColor:
          'linear-gradient(92.63deg, #4E4E4E 1.21%, #F6B149 43.44%)',
        heroTitle: "From Earth's Heart to Your Table",
        herodesc:
          "The ultimate gateway to a world of exceptional, global-sourced honey delights. Immerse yourself in a journey of taste and discovery, Geohoney brings the finest, pure, and unfiltered honey from nature's hidden gems.",
        category: 'Retail & Consumer',
        serviceOff:
          'Prototyping & UI/UX, Website Design & Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/geohoneymobile.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 UI/UX Designer, 1 React Native Developer , 1 Back- End Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          "Geohoney is a global pollination and green tech corporation which is a leading global raw honey supplier offering unique honey varieties to people worldwide. The mobile app was launched with the aim of strengthening the relationship between the Geohoney brand & its valuable customers. Geohoney's vision is to focus on more initiatives and technological advancements to protect the environment.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/geohoney-logo-white3.webp',
        projectOverbgColor: '#F6B149',
        projectOverbordercolor: '#FCD688',
        proejctOverShadow: '10px 10px #EED08A',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/geotabimage.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/geomobileimg.webp',
        prototype1device1: 'Desktop 1440px',
        prototype1device2: '8 columns 100px , Double Gutter 40px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          "The client's objective was to provide a distinctive mobile experience and seamless usability for their customers while searching for and purchasing honey products. The primary challenge was to develop a comprehensive cross-platform mobile application that works efficiently on both Android and iOS devices. The app needed to deliver fast performance, high reliability, and a user-friendly interface to enhance the overall experience for customers. The goal was to create an intuitive and multidimensional mobile application that simplifies the process of browsing and buying honey products, ensuring convenience and satisfaction for users across platforms.",
        approchDes:
          "Having gained a comprehensive understanding of our client's requirements, we developed a user-friendly mobile application that simplifies the retailing and purchasing of fresh raw honey. We integrated key features from their website, including wholesale and retail product options, as well as worldwide shipping capabilities. Both the iOS and Android versions of the application have been launched, offering upgraded features to enhance the user experience. The application now provides customers with a seamless and convenient platform for exploring and buying honey products, reinforcing the client's retail presence.",
        portfolioimg1:
          'assets/img/webp-image/casestudydetails/mobileappbanner.webp',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/geoprotimg2.webp',
        keyResult:
          "GeoHoney, where modern design meets nature's gateway to the world's finest nectar. As an exclusive eCommerce site, we embark on a captivating journey to uncover pure, unfiltered honey sourced from diverse landscapes. Elevate your culinary experience, connecting with nature's hidden wonders, and savoring each region's essence with every jar.",

        grascleimg: 'assets/img/webp-image/casestudydetails/whitegerhone.webp',
        coloredImage:
          'assets/img/webp-image/casestudydetails/colorgerhone.webp',
        bgColorTransition:
          'linear-gradient(101.68deg, #000000 49.61%, #F6B149 100%)',
        techlogo: tools,

        clientFeedback:
          'I personally dealt with many web developers of all languages and specialities. In one word I can say Mr. Rohit Sadani is the best of all!. I specially noticed how smart he was since I first met with him, and how dedicated, resourceful and over deliver he was when we started working on several online projects.                  ',
        clientName: 'Mr Basem Barry ',
        clientPost: 'CEO At BA Barry Group',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/barndlogo.webp',
        clientProfileImage: 'assets/img/webp-image/casestudydetails/barry.webp',
        googlerating: 'assets/img/webp-image/casestudydetails/barry.webp',
        projecturl: 'https://geohoney.com/',
      },
      {
        slug: 'classic-education',
        herobgimage: 'assets/img/webp-image/casestudydetails/bgclass.webp',
        heroTitleColor:
          'linear-gradient(93.09deg, #0046C2 0.96%, #FF4F48 52.56%)',
        heroTitle: 'Innovate, Educate, Elevate',
        herodesc:
          'Elevate education with Classic Educational Supplies. Discover premium learning materials for nurseries, schools, and retailers in the UAE',
        category: 'Education',
        serviceOff:
          'Custom Shopify App Development, E-commerce Development, Testing/QA Analysis ',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/classiceducation1.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Shopify Developer, 1 Front-End Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          "Classic Educational Supplies Trading LLC is a renowned distributor that offers high-quality educational materials and resources to nurseries, schools, wholesalers, and prominent retailers in the United Arab Emirates. To streamline the handling of product inquiries and fulfill customer needs effectively, a custom Shopify app has been created. This application integrates exceptional functionalities in line with Shopify's criteria and recommendations, facilitating the effective management of orders.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/classlogo.webp',
        projectOverbgColor: '#000B2B',
        projectOverbordercolor: '#1F0D85',
        proejctOverShadow: '10px 10px #8174C9',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/geotabimage.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/geomobileimg.webp',
        prototype1device1: 'Desktop 1440px',
        prototype1device2: '8 columns 100px , Double Gutter 40px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          "The client's objective was to provide a distinctive mobile experience and seamless usability for their customers while searching for and purchasing honey products. The primary challenge was to develop a comprehensive cross-platform mobile application that works efficiently on both Android and iOS devices. The app needed to deliver fast performance, high reliability, and a user-friendly interface to enhance the overall experience for customers. The goal was to create an intuitive and multidimensional mobile application that simplifies the process of browsing and buying honey products, ensuring convenience and satisfaction for users across platforms.",
        approchDes:
          "Having gained a comprehensive understanding of our client's requirements, we developed a user-friendly mobile application that simplifies the retailing and purchasing of fresh raw honey. We integrated key features from their website, including wholesale and retail product options, as well as worldwide shipping capabilities. Both the iOS and Android versions of the application have been launched, offering upgraded features to enhance the user experience. The application now provides customers with a seamless and convenient platform for exploring and buying honey products, reinforcing the client's retail presence.",
        portfolioimg1:
          'assets/img/webp-image/casestudydetails/mobileappbanner.webp',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/geoprotimg2.webp',
        keyResult:
          "GeoHoney, where modern design meets nature's gateway to the world's finest nectar. As an exclusive eCommerce site, we embark on a captivating journey to uncover pure, unfiltered honey sourced from diverse landscapes. Elevate your culinary experience, connecting with nature's hidden wonders, and savoring each region's essence with every jar.",

        clientFeedback:
          'I personally d ealt with many web developers of all languages and specialities. In one word I can say Mr. Rohit Sadani is the best of all!. I specially noticed how smart he was since I first met with him, and how dedicated, resourceful and over deliver he was when we started working on several online projects.                  ',
        clientName: 'Mr Basem Barry ',
        clientPost: 'Ceo At Ba Barry Group',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/barndlogo.webp',
        clientProfileImage: 'assets/img/webp-image/casestudydetails/barry.webp',
        googlerating: 'assets/img/webp-image/casestudydetails/barry.webp',
        projecturl: 'https://geohoney.com/',
        techlogo: tools,
      },
      {
        slug: 'luxurygallery',
        herobgimage: 'assets/img/webp-image/casestudydetails/bgluxury.webp',
        heroTitleColor:
          'linear-gradient(92.59deg, #EFC5B9 0.56%, #747474 65.49%) ',
        heroTitle: 'Step into a World of Timeless Elegance',
        herodesc:
          'Step into the world of ultimate sophistication at LuxuryGallery.com, a destination for curated elegance and unparalleled craftsmanship. Explore a captivating array of opulent treasures sourced from global artisans and luxury brands.',
        category: 'Lifestyle',
        serviceOff:
          'E-commerce Development, Digital Marketing, Testing QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/luxury-laptop.webp',
        noOfteam: '5',
        teamDes:
          ' 1 Project Manager, 1 Magento Developer, 1 Front-End Developer,1 Data Analyst, 1 QA Analyst ',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          "Luxury Gallery offers a curated collection of timeless elegance and impeccable craftsmanship, elevating lifestyles to new heights. Distinguished brands' iconic watches and exquisite jewelry since 1881 are waiting to be discovered. The gallery immerses visitors in sophistication, showcasing horological masterpieces and dazzling creations. With a rich heritage and unwavering commitment to excellence, Luxury Gallery sets a new standard for luxury indulgence. Passion, precision, and prestige converge in this exceptional destination, captivating all who seek a refined living.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/luxurygalleryblack.webp',
        projectOverbgColor: '#FDF2EF',
        projectOverbordercolor: '#EFC5B9',
        proejctOverShadow: '10px 10px #FEE0D8',
        textColor: 'rgba(58, 58, 58, 1)',

        reserachDesignDes:
          "We specialize in providing top-tier SEO research and analysis services to boost online visibility. Through rigorous keyword research, comprehensive competitor analysis, and meticulous on-page and technical audits, we ensure our client's website is primed for search engine success. Our experts evaluate the backlink profile and content quality, shaping strategies to improve domain authority and engage the target audience effectively. Focusing on data-driven insights and tailored recommendations, we empower businesses to thrive in the competitive digital landscape, driving organic traffic, and delivering sustainable, long-term results.",
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        challengesDes:
          'At Luxury Gallery, we adeptly navigated challenges in curating a collection of timeless elegance, showcasing iconic watches and exquisite jewelry from esteemed heritage brands since 1881. Positioning Luxury Gallery as a premier destination for refined living demanded strategic marketing and branding. Staying attuned to market trends and fostering strong client relationships were vital for sustained success in the luxury domain. In terms of SEO, we tackled the unique task of balancing exclusivity and digital accessibility, optimizing content with precision to resonate with the discerning audience while maintaining the allure of luxury.',
        approchDes:
          "At Luxury Gallery, we tackled challenges head-on by meticulously curating an elegant collection of iconic watches and exquisite jewelry from heritage brands since 1881. Our strategic marketing emphasized luxury and exclusivity, while detailed presentation ensured an immersive customer experience. Keeping a finger on market trends and nurturing client relationships sustained our success in the luxury domain. In the digital realm, we deftly balanced exclusivity with SEO, infusing content with keywords that resonated with discerning connoisseurs. This holistic approach not only showcased exceptional pieces but also wove a digital narrative of opulence, upholding Luxury Gallery's legacy in the modern luxury landscape.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/luxurycoloredbanner.webp',

        bgColorTransition:
          'linear-gradient(180deg, #FDF2EF -64.38%, #EFC5B9 157.69%)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/posrtfolio2.webp',
        keyResult:
          'Luxury Gallery has revolutionized luxury retail, offering a seamless platform for global buyers to access and acquire exquisite products. Its curated approach has redefined how luxury goods are discovered and acquired, setting new benchmarks in the online luxury market. By connecting discerning buyers with top-tier brands, Luxury Gallery has reimagined the luxury shopping experience.',
      },
      {
        slug: 'kalachain',
        herobgimage:
          'assets/img/webp-image/casestudydetails/image_kalachain_bg.webp',
        heroTitleColor:
          'linear-gradient(92.59deg, #011218 0.56%, #747474 65.49%)',
        heroTitle: 'Unlocking the Potential of Blockchain',
        herodesc:
          'Kalachain is a NFT collection of Nature Clicks. Many artists upload their clicks as NFT with us to get wider audience',
        category: 'Retail & Consumer',
        serviceOff: 'Web Design & Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/kalachain_laptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Blockchain Developer, 1 Front-End Developer,1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/unitedstarte.webp',
        countryName: 'Texas City, USA',

        projectOverDes:
          'Kalachain stands at the forefront of the blockchain revolution, redefining the business landscape by harnessing the full potential of decentralized technology. This cutting-edge platform leverages a powerful tech stack, incorporating Ethereum for smart contracts, IPFS for decentralized storage, and Polkadot for seamless interoperability. By providing enterprises with the tools to build scalable, secure, and transparent solutions, Kalachain drives digital transformation and sparks innovation across various industries.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/kalachainWhite.webp',
        projectOverbgColor: '#011218',
        projectOverbordercolor: '#5A5C5C',
        proejctOverShadow: '10px 10px #3A3A3A',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        // prototypeTabImage:"assets/img/webp-image/casestudydetails/tabprototype.webp",
        // prototypeMobileImage:"assets/img/webp-image/casestudydetails/mobileprototype.webp",

        challengesDes:
          'While designing and developing the Kalachain platform, we encountered several challenges like integrating multiple blockchain technologies like Ethereum, IPFS, and Polkadot posed complexities in ensuring seamless interoperability. Implementing robust security measures to protect sensitive data and smart contracts from potential vulnerabilities demanded meticulous attention. Scaling the platform to meet enterprise demands while maintaining optimal performance and decentralization was another significant challenge. Additionally, educating businesses about the benefits and adoption of blockchain technology required extensive efforts to drive digital transformation and innovation in various industries.',
        approchDes:
          "To overcome the challenges, we adopted a research-driven approach for integrating Ethereum, IPFS, and Polkadot seamlessly while focusing on communication protocols. Security measures involved rigorous testing, code audits, and encryption techniques to protect data and smart contracts. Scalability was achieved through optimization and cloud infrastructure utilization, launched an extensive education and outreach program to raise awareness about blockchain technology's benefits, assisting businesses in understanding and adopting Kalachain for their unique needs, and fostering digital transformation and innovation across diverse industries.",
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/kalachainbottombaner.webp',
        keyResult:
          'This revolutionary platform empowers businesses to innovate confidently, sparking a decentralized transformation. Its impact is felt through enhanced trust, efficiency, and limitless possibilities within the blockchain landscape. Kalachain stands as a beacon, guiding the world towards a decentralized future.',
        coloredImage: 'assets/img/webp-image/casestudydetails/coloredimg.webp',
        // grascleimg:"assets/img/webp-image/casestudydetails/grayscale.webp",

        bgColorTransition: 'linear-gradient(180deg, #011218 0%, #152849 100%)',
        techlogo: kalachainlogo,
      },

      {
        slug: 'qatar-bank',
        herobgimage: 'assets/img/webp-image/casestudydetails/qdbbanner.webp',
        heroTitleColor:
          'linear-gradient(92.63deg, #4E4E4E 1.21%, #580F79 43.44%)',
        heroTitle: "Unlocking Qatar's Potential",
        herodesc:
          'QDB work towards Qatar’s economic development cycle by providing entrepreneurs and SMEs with a comprehensive suite of financial and advisory services and products,',
        category: 'Professional Service',
        serviceOff: 'Website Design & Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/qatarlaptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager,1 Front-End Developer, 1 ASP.Net Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/qatar.webp',
        countryName: 'Doha, Qatar',

        projectOverDes:
          "Qatar Development Bank (QDB) assumes a central role in driving Qatar's economic expansion, providing an extensive array of financial and advisory solutions tailored to entrepreneurs and small and medium-sized enterprises (SMEs). Serving as a dynamic catalyst for progress, QDB equips these enterprises with essential resources, empowering them for success. By offering a holistic range of offerings encompassing financial backing and specialized insights, QDB cultivates a culture of innovation and facilitates enduring progress within Qatar's entrepreneurial realm.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/qdbanklogo.webp',
        projectOverbgColor: '#580F79',
        projectOverbordercolor: '#41065B',
        proejctOverShadow: '10px 10px #4F2B60',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        // prototypeTabImage:"assets/img/webp-image/casestudydetails/tabprototype.webp",
        // prototypeMobileImage:"assets/img/webp-image/casestudydetails/mobileprototype.webp",

        challengesDes:
          'Designing and developing the Qatar Development Bank (QDB) website posed several intricate challenges. Ensuring the online representation effectively communicated the diverse range of financial and advisory services to entrepreneurs and SMEs required meticulous design and content strategy. Integrating user-friendly navigation and intuitive interface design while accommodating the comprehensive suite of products demanded thoughtful planning while maintaining a seamless user experience added complexity to the development process.',
        approchDes:
          "To navigate these complex challenges, our approach was multifaceted and strategic. We initiated in-depth consultations with QDB's domain experts to deeply understand their services, allowing us to craft a tailored design and content strategy that resonated with the target audience. Through collaboration with the team, we meticulously organized the website's architecture to balance the diverse range of offerings while ensuring intuitive navigation. Regular updates and close coordination with QDB facilitated the dynamic integration.",

        keyResult:
          "The QDB initiative has made a transformative impact on Qatar's business landscape, fostering economic growth and innovation. By providing vital support, funding, and resources to local enterprises, it has catalyzed the development of thriving businesses across various sectors. Through strategic partnerships and initiatives, QDB has propelled entrepreneurship, bolstered job creation, and contributed significantly to the nation's economic.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/qdbcoloredimg.webp',
        // grascleimg:"assets/img/webp-image/casestudydetails/grayscale.webp",
        bgColorTransition:
          'linear-gradient(101.38deg, #000000 0%, #580F79 100.01%)',
        portfolioimg2: 'assets/img/webp-image/casestudydetails/portqdb2.webp',
        techlogo: qdblogo,
      },

      {
        slug: 'audi',
        herobgimage:
          'assets/img/webp-image/casestudydetails/audiaapbannerbgcasestudiesdetails.webp',
        heroTitleColor:
          'linear-gradient(271.96deg, #F50537 35.6%, #000000 99.21%)',
        heroTitle: 'Crafting Experiences Beyond Ordinary',
        herodesc:
          'Audi Lifestyle is an online e-shop to get premium Audi pieces delivered straight to your doorstep serving in the Middle-East region.',
        category: 'Lifestyle',
        serviceOff:
          'Prototyping & UI/UX, E-commerce development, Testing/QA analyst ',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/audilaptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Shopify Developer, 1 Front-End Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          "Audi Collection's online store in the Middle East showcases a curated selection of premium items, subtly paying homage to the Audi enthusiasts' discerning taste. With a seamless and user-friendly e-shop, customers can explore the Audi Lifestyle Collection, effortlessly placing orders and enjoying the convenience of doorstep delivery. Emphasizing both quality workmanship and modern design, Audi Lifestyle Collection aims to elevate the everyday and bring a touch of Audi's elegance into the lives of its customers.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/audicasedetails.webp',
        projectOverbgColor: '#010101',
        projectOverbordercolor: '#4D4747',
        proejctOverShadow: '10px 10px #878787',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/walktroimageaudileftside.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/walktroimageaudirightside.webp',
        prototype1device1: 'Desktop 1440px',
        prototype1device2: '8 columns 100px , Double Gutter 40px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          'Our task was to create a distinctive Shopify eCommerce store tailored to the unique essence of the Audi Lifestyle brand, compelling customers to make purchases. The client also requested the integration of Payfort for seamless transactions and a shipping gateway for efficient order fulfillment. It was crucial to design an interactive interface that streamlined the process for the company, merchants, and users a like.',
        approchDes:
          "Given the significance of the Audi Lifestyle Collection project, we dedicated ourselves to developing an e-commerce store that reflected the brand's identity. Our focus revolved around optimizing user experience and meeting the client's requirements. Through a collaborative effort, our proficient Shopify web development experts leveraged cutting-edge technology that fulfilled the client's vision.",

        keyResult:
          'Our dedicated team has crafted a modern and elegant website, tailor-made for luxury enthusiasts. Explore the finest Audi products with ease and indulge in a seamless shopping experience. Immerse yourself in sophistication and style, as you discover the perfect blend of luxury and convenience in our curated collection.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/audibannerportfolio.webp',
        grascleimg:
          'assets/img/webp-image/casestudydetails/audibannerblackportfolio.webp',
        bgColorTransition: '#343434',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/audibottomslider.webp',
        techlogo: audilogos,

        clientFeedback:
          'Rohit has been very hands on and proactive with stake holder management, he is very approachable and always ensures clarity in user’s requirements. Rohit and his team has been supporting us in our pilot projects using Shopify platform and have delivered some amazing results.',
        clientName: 'Mr. Saif A Khan',
        clientPost:
          'Digital Retail and Customer Applications Manager - Audi Volkswagen Middle East ',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/audilogotestimonail.webp',
        clientProfileImage:
          'assets/img/webp-image/casestudydetails/saifkhan.webp',

        projecturl: 'https://lifestyle.audimiddleeast.com/',
      },
      {
        slug: 'bookmypet',
        herobgimage:
          'assets/img/webp-image/casestudydetails/bmpaapbannerbgcasestudiesdetails.webp',
        heroTitleColor:
          'linear-gradient(to right, #2037A1, #2037A1, #24BAED, #24BAED)',
        heroTitle: '100% Customized App Built On React Native',
        herodesc:
          'Control all your pet care services from your fingertips, and expand the scope of your businesses with a feature-rich.',
        category: 'Pet Care',
        serviceOff:
          'Prototyping & UI/UX, Mobile App Development, Testing/QA Analysis, Dedicated Team',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/bookmypetiphoneimagebanner.webp',
        noOfteam: '5',
        teamDes:
          '1 React Native Developer, 1 Back-End Developer, 1 Front-End Developer, 1 UI/Ux Designer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          'Bookmypet is a mobile app designed for pet owners to conveniently book various services for their pets. With this app, pet parents can access various services such as Boarding, Day Care, Grooming, training, Veterinary facilities, and more, from all over the UAE. The app offers multiple search filters such as location, customer reviews, prices, services, and others, making it easy for users to find and book suitable service providers right from the app.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/bmpwhitelogo.webp',
        projectOverbgColor: '#3973B3',
        projectOverbordercolor: '#548ACB',
        proejctOverShadow: '10px 10px #3B3C3C',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces. ',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/RPA_development-lifecycle.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/walktroimagebookmypetleftside.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/walktroimagebookmypetrightside.webp',
        prototype1device1: 'Tablet 768px',
        prototype1device2: '4 columns 150px, Duble Gutter 20px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          'Our client came up with an approach to provide a platform for all pet parents in Dubai for booking services for their pets.The client wanted to ensure the booking process would be easy to use and well-displayed in the navigation and provide an easy-to-use platform where users can browse and find service providers using easy to use multiple filters and book service for their pet. They also wanted to provide treats program to its users in the form of cashback on each service booked & user can redeem these treats points on booking future services with BookMyPet and transportation service where the taxi provider would provide pickup & drop off service for the pets.',
        approchDes:
          'The Bookmypet App serve as a helpful tool for all the pet parents in Dubai to book affordable pet services for their pet to book Boarding, Grooming, Daycare & vet services .Keeping the requirement in mind Our creative team eagerly stepped up to the challenge and build an easy-to-use app. Our approach was to focus on making the app which is easy to navigate and convenient to users. We also ensured that the booking process was simple, easily accessible & provide an easy browse & book feature. Our Professional team was able to provide them with an easily navigable App to book pet services.',

        keyResult:
          'This project delivered an in-depth understanding of the customer personal and their interaction with the service. The new app has a seamless look & feel attracting to the users. Faster and easier to navigate, enabling users to find information quickly. We launched applications in the Play Store and App Store after testing.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/portfoliobanchcasestudies.webp',
        grascleimg:
          'assets/img/webp-image/casestudydetails/blackportfoliobanchcasestudies.webp',
        bgColorTransition:
          'linear-gradient(to right, #000, #000, #235995, #235995)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/bannerbottom.webp',
        techlogo: bookmypetlogo,
      },
      {
        slug: 'shakespeare',
        herobgimage: 'assets/img/webp-image/casestudydetails/bannerbgsha.webp',
        heroTitleColor:
          'linear-gradient(to right, #000, #000, #AF8A68, #AF8A68)',
        heroTitle: 'A Symphony Of Taste And Tradition',
        herodesc:
          'Discover a captivating world of culinary excellence and timeless elegance at Shakespeare and Co. where classic and contemporary flavors intertwine.',
        category: 'Retail & Consumer',
        serviceOff:
          'Prototyping & UI/UX, E-Commerce Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/shakespearelaptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Shopify Developer, 1 Front-End Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          'Shakespeare and Co. is a homegrown Café Restaurant concept founded in Dubai by Dr. Edward Saad in 2001. With its warm and inviting ambiance, each store creates a unique and enchanting experience for patrons. Operating 32 stores across the UAE and 8 franchised stores in the GCC serving over 5 million customers a year, Shakespeare and Co.’s acclaimed culinary offerings encompass classic and contemporary fare, inspired by the world’s most beloved cuisines.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/projectlogo.webp',
        projectOverbgColor: '#AF8A68',
        projectOverbordercolor: '#F1EEE1',
        proejctOverShadow: '10px 10px #878787',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces. ',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/ezgif.com-gif-maker.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/shakespearelaptopleft.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/shakespearelaptopright.webp',
        prototype1device1: 'Desktop 1440px',
        prototype1device2: '8 columns 100px , Double Gutter 40px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          'When Shakespeare came to us, they presented us with a few challenges for us to help them overcome. Despite their enormous success in sales and developing a strong social presence, they realized that they were focused too heavily on selling products offline.They wanted to give the brand a better identity, improve the site’s UX with ease to order, and update the design itself to look more modern. Additionally, they wanted a feature to allow users to book a delivery slot to simplify the process and deliver the best.',
        approchDes:
          'Keeping the challenges and goals in mind, our team created a brand-new digital presence for Shakespeare & Co. Being the site built in Shopify, we chose the most suitable theme so the team would have more control over product templates and features, integrating Shopify apps for better product content. We created the site to give users a more modern look and feel, showcase their pre-existing solid social presence, highlight the bestsellers, and provide an easy-to-use purchasing system for new and existing customers.',

        keyResult:
          'Elevated a 20-year bakery legacy with an elegant online presence. This project is a successful demonstration of discovering delightful bakery deliveries with a seamless click-to-order experience. Embrace the perfect fusion of design and brand for an unforgettable journey from purchase to enjoyment at home.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/shakespearebottomportfolio.webp',
        grascleimg:
          'assets/img/webp-image/casestudydetails/blackshakespearebottomportfolio.webp',
        bgColorTransition: '#343434',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/shakespearebottom.webp',
        techlogo: shakespearelogo,

        clientFeedback:
          'A special thank you to Rohit the project lead, as well as Alex who initially handled my inquiries during the tendering stage, as well as Megha and the development team for their expeditious and courteous correspondence throughout the project! A brilliant team and the easiest recommendation i will ever make!',
        clientName: 'Mr. Cyrus Graesslin',
        clientPost: 'Regional Director - Shakespeare and Co. Restaurants',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/Shakespearelogotestimonial.webp',
        clientProfileImage:
          'assets/img/webp-image/casestudydetails/processeddp.webp',

        projecturl: 'https://shakespeare-and-co.com/',
      },
      {
        slug: 'organics-mantra',
        herobgimage:
          'assets/img/webp-image/casestudydetails/oraginmintraapbannerbgcasestudiesdetails.webp',
        heroTitleColor:
          'linear-gradient(to right, #557529, #557529, #FE9400, #FE9400)',
        heroTitle: 'Unlock The Power Of Organic Living',
        herodesc:
          'Organics Mantra is an e-commerce platform selling naturally produced vegetables, fruits, groceries & other day-to-day items at the doorstep.',
        category: 'Retail & Consumer',
        serviceOff:
          'Mobile App Development, Testing/QA Analysis, Dedicated Team',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/orginmantralaptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager,1 Front End Developer,1 Angular Developer, 1 QA Analyst      ',
        countryFlag:
          'assets/img/webp-image/casestudydetails/flagindiacasestudies.webp',
        countryName: 'Hyderabad, India',

        projectOverDes:
          'Organics Mantra is an e-commerce app that provides a wide selection of naturally produced vegetables, fruits, groceries, and other day-to-day items delivered right to your doorstep. With a focus on sustainability and the environment, Organics Mantra takes inspiration from age-old Indian traditions and practices to offer products that have minimal impact on the environment. Their mission is to minimize the consumption of harmful chemicals and additives, catering to health enthusiasts and individuals who advocate for the well-being of people by promoting an organic way of life.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/orginmintralogo.webp',
        projectOverbgColor: '#557529',
        projectOverbordercolor: '#788B5F',
        proejctOverShadow: '10px 10px #364E16',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/shakespeare/ezgif.com-gif-maker.gif',

        // prototypeTabImage:"assets/img/webp-image/casestudydetails/oraginmintraleft.webp",
        // prototypeMobileImage:"assets/img/webp-image/casestudydetails/orginmintrarightpart.webp",
        // prototype1device1:"Desktop 1440px",
        // prototype1device2:"8 columns 100px , Double Gutter 40px",
        // prototype2device1:"Mobile 320px",
        // prototype2device2:"4 columns 54px, Duble Gutter 20px",

        challengesDes:
          'When it comes to fixing bugs and implementing improvements for the Organics Mantra app, there were several challenges that aroused in front of the team. One of the main challenges was maintaining code quality and scalability. As the app grows and new features are added, the complexity of the codebase increase and makes it harder to identify and fix bugs, as well as introduce potential issues when making improvements. Implementing efficient debugging practices and following coding best practices helped our team mitigate these challenges.',
        approchDes:
          'Our expert team conducted a thorough analysis of the code structure and functionality, leading to bug fixes and usability improvements for both the app front end and admin interface. We prioritized major features and simplified actions for administrators, particularly focusing on enhancing the subscription functionality. By adding fields and improving functions, we made subscriptions more user-friendly for app users and easily manageable for admins. The result is an app with improved performance, enhanced user experience, and streamlined subscription management.',

        keyResult:
          "The Organics Mantra app successfully facilitated the delivery of naturally produced vegetables, fruits, groceries, and day-to-day items to customers' doorsteps, aligning with their commitment to environmental sustainability and promoting an organic lifestyle that resonates with health-conscious individuals.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/orginmintraportfoliobanner.webp',
        // grascleimg:"assets/img/webp-image/casestudydetails/blackorginmintraportfoliobanner.webp",
        bgColorTransition:
          'linear-gradient(to right, #557529, #557529, #FF9400, #FF9400)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/orgnicmintrabottombanner.webp',
        techlogo: organicmantralogo,
      },
      {
        slug: 'discounter',
        herobgimage:
          'assets/img/webp-image/casestudydetails/discounterapbannerbgcasestudiesdetails.webp',
        heroTitleColor:
          'linear-gradient(to right, #4E4E4E, #FEC64B, #FEC64B, #FEC64B)',
        heroTitle: 'Shop Smart, Save More',
        herodesc:
          'Discounter App offers instant Exclusive discounts and offers for top vendors in the UAE for FREE.',
        category: 'Retail & Consumer',
        serviceOff:
          'Prototyping & UI/UX, Mobile App Development, Testing/QA Analysis, Dedicated Team',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/discounterlaptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Swift/Kotlin Developer, 1 Front-End Developer, 1 UI/UX Designer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          'The Discounter App is a popular platform in the UAE, offering discounts on restaurants, beauty treatments, and entertainment. With thousands of offers available, it has become a top choice for instant deals and promo codes in the Middle East. The app aims to revolutionize the market and provide a unique experience to users by collaborating with businesses and simplifying the redemption process. Its goal is to be the go-to platform for affordable experiences in the region. ',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/discounterlogowhite.webp',
        projectOverbgColor: '#FEC64B',
        projectOverbordercolor: '#FCD688',
        proejctOverShadow: '10px 10px #D5BC80',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/shakespeare/ezgif.com-gif-maker.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/discounterleft.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/discounterright.webp',
        prototype1device1: 'Tablet 768px',
        prototype1device2: '4 columns 150px, Duble Gutter 20px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          "When Discounter approached Pro Web, we recognized the need to cater to a wider range of categories and target specific audiences. Our UI concept was designed to attract and engage a larger user base while providing service providers with the ability to update offers and discounts easily. Our goal was to increase the app's popularity and make it more appealing to both users and businesses alike. By implementing a user-friendly interface, we ensured that accessing and redeeming offers on the Discounter App is seamless and intuitive. Our collaboration with Discounter aimed to create a platform that revolutionizes the discount experience, making it convenient and enticing for users across the Middle East.",
        approchDes:
          "After gaining a clear understanding of our client's vision, our dedicated team embarked on thorough research and extensive knowledge gathering. Collaborating closely, our experienced designers and developers meticulously crafted the final product, building each module with precision. Our focus was on incorporating key features such as live tracking, rating and reviews, coupon redemption, location-based offer filtering, offer and vendor sharing, regular notifications, and an intuitive checkout process, aligning with the preferences of Discounter. The result was a user-friendly app that catered to every aspect of the discount experience.",

        keyResult:
          'Discounter app, a cutting-edge shopping solution where we meticulously curated exclusive deals and discounts from top brands, transforming the shopping landscape this user-friendly ensure a seamless and interactive experience for users. From concept to execution, we harnessed our skills to create a powerful tool for smart shopping, making the Discounter app a go-to destination for savings.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/discounterportfolio.webp',
        grascleimg:
          'assets/img/webp-image/casestudydetails/blackdiscounterportfolio.webp',
        bgColorTransition:
          'linear-gradient(to right, #000, #000, #000, #FEC64B)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/discounterbottomslider.webp',
        techlogo: discounterlogo,

        clientFeedback:
          'Rohit was always supportive through out our 2 years of operations, would constantly devote him self to the delivery of the project and support to the team',
        clientName: 'Mr.Abdelrahman Rashwan',
        clientPost: 'Founder & CEO - The Discounter',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/discountertestimonial.webp',
        clientProfileImage: 'assets/img/webp-image/casestudydetails/disco.webp',

        projecturl: 'https://www.thediscounterapp.com/',
      },

      {
        slug: 'travelhub',
        herobgimage:
          'assets/img/webp-image/casestudydetails/travelhubbannerbg.webp',
        heroTitleColor: 'linear-gradient(180deg, #2A9BD6 0%, #1F4A96 100%)',
        heroTitle: 'Discover The World With The Travel Hub Lens',
        herodesc:
          'The Travel Hub helps to connect like-minded travelers, fostering inspiration, and facilitating commercial opportunities in one platform.',
        category: 'Tour & Travel Services',
        serviceOff:
          'Prototyping & UI/UX, Website Design & Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/travelhublaptop.webp',
        noOfteam: '5',
        teamDes:
          '1 Project Manager, 1 Graphic Designer, 1 Frontend Developer, 1 Backend Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',

        projectOverDes:
          'The Travel Hub stands for a lasting immersive travel experience that brings different cultures together. With the travel landscape changing at a fast pace, they wanted to create a shared space for travelers and explorers to interact via Articles, Share Reviews, and discuss travel-related topics over forums. The Travel Hub marked its beginning in 2020 with an aim to create a hub for passionate travelers to share their travel experiences. ',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/whitelogotravel.webp',
        projectOverbgColor: '#2A98D3',
        projectOverbordercolor: '#50BEF9',
        proejctOverShadow: '10px 10px #3985A6',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/RPA_development-lifecycle.gif',

        prototypeTabImage:
          'assets/img/webp-image/casestudydetails/travelhubleft.webp',
        prototypeMobileImage:
          'assets/img/webp-image/casestudydetails/travelhubrightpart.webp',
        prototype1device1: 'Desktop 1440px',
        prototype1device2: '8 columns 100px , Double Gutter 40px',
        prototype2device1: 'Mobile 320px',
        prototype2device2: '4 columns 54px, Duble Gutter 20px',

        challengesDes:
          "To fulfill the client's requirements, our team developed a unique platform for global travelers to register and share their travel experiences. We revamped the existing website, incorporating additional features and improving user interaction. This involved customizing the platform for travel blogging and enhancing traveler profiles with exclusive features. We also designed curated travel features and developed custom analytics for both traveler profiles and the admin. The project required extensive brainstorming and dedicated effort from our team to create a robust and user-friendly platform.",
        approchDes:
          'The client approached us with a basic site with a simple UI. They majorly emphasized on creating an attractive UI for grabbing the attention of new users. Building an easy interface was also one of their prime focuses. Our professional designers and developers worked on the entire management of user profile features, calculated content response and statistics for the articles uploaded by the travelers and influencers on their profile giving them all customizable features to upload pictures in the grid and different crop ratios.',

        keyResult:
          'The Travel Hub has fostered a vibrant community of travelers and explorers, facilitating the exchange of travel experiences, reviews, and ideas through articles and forums, thereby fostering cross-cultural understanding and inspiring meaningful connections among adventurers worldwide.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/travelhubportfolio.webp',
        grascleimg:
          'assets/img/webp-image/casestudydetails/blacktravelhubportfolio.webp',
        bgColorTransition:
          'linear-gradient(to right, #1658a2, #1658a2, #2989c8, #2989c8)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/travelhubbottombanner.webp',
        techlogo: thetravelhblogo,

        clientFeedback:
          'I have been working with Pro Web to design and develop my travel website. It has a great deal of functionality that required a custom build. They have been extremely professional from the outset and their flexibility in being able to work solutions for complex problems has been amazing. Above all else, their level of communication is first class, I have been able to speak with Rohit and his team daily throughout the process. I cant recommend this team highly enough!',
        clientName: 'Mr. Andrew Marty',
        clientPost: 'Co- Founder At The Travel Hub',
        clientbrandlogo:
          'assets/img/webp-image/casestudydetails/traveltestimniallogo.webp',
        clientProfileImage:
          'assets/img/webp-image/casestudydetails/travelhubclientspic.webp',

        projecturl: 'https://thetravelhub.com/',
      },
      {
        slug: 'quillatwill',
        herobgimage:
          'assets/img/webp-image/casestudydetails/quillatwillbannerbgcasestudiesdetails.webp',
        heroTitleColor:
          'linear-gradient(to right, #2A9BD6, #2A9BD6, #1F4A96, #1F4A96)',
        heroTitle: 'Write, Create, Share',
        herodesc:
          'Quill At Will empowers writers and ignites creativity. Unleash your literary potential with an innovative platform and connect with a supportive community of like-minded wordsmiths.     ',
        category: 'Education',
        serviceOff:
          'Prototyping & UI/UX, Web & App Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/quillatwilllaptop.webp',
        noOfteam: '3',
        teamDes: '1 Project Manager, 1 React.js Developer, 1 QA Analyst',
        countryFlag:
          'assets/img/webp-image/casestudydetails/flagindiacasestudies.webp',
        countryName: 'Hyderabad, India',

        projectOverDes:
          'Quill At Will is an Online journal / Diary writing platform designed for the mind and soul to boost positive energy. Store your thoughts and express yourself through journaling which makes writing fun and enables sharing creative expression with others. This interactive and intuitive platform aims at enhancing creative confidence. It is a versatile and easy-to-use portal that helps you write and publish blogs, poems, and essays maintain your diary notes, and also send postcards to friends.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/quillatwillwhitelogo.webp',
        projectOverbgColor: '#4431AB',
        projectOverbordercolor: '#1F0D85',
        proejctOverShadow: '10px 10px #8174C9',

        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/webp-image/casestudydetails/RPA_development-lifecycle.gif',

        // prototypeTabImage:"assets/img/webp-image/casestudydetails/quillatwillleftpart.webp",
        // prototypeMobileImage:"assets/img/webp-image/casestudydetails/quillatwillrightpart.webp",
        // prototype1device1:"Desktop 1440px",
        // prototype1device2:"8 columns 100px , Double Gutter 40px",
        // prototype2device1:"Mobile 320px",
        // prototype2device2:"4 columns 54px, Duble Gutter 20px",

        challengesDes:
          'During the development we faced challenges in designing an intuitive interface that fosters creative expression, ensuring data security and privacy, optimizing scalability and performance to accommodate a growing user base, and ensuring cross-platform compatibility for seamless accessibility across different devices. The complexity of integrating various features like blog and postcard functionalities, while maintaining a cohesive and engaging user experience was a big challenge.',
        approchDes:
          "We adopted a user-centric design approach, conducting thorough market research and user testing to ensure the platform's intuitiveness and appeal. We prioritized user privacy and security along with Scalability, which was addressed through a flexible infrastructure that could handle increasing traffic, and performance optimization techniques were implemented. Cross-platform compatibility was achieved by employing responsive design principles. The team worked tirelessly to seamlessly integrate blog and postcard functionalities, ensuring a cohesive user experience while enabling creative expression and sharing with ease.",

        keyResult:
          'Quill At Will emerges as a transformative digital platform igniting positivity within individuals.Seamlessly weaving versatility and user-friendliness, it facilitates seamless creation and sharing of blogs, poems, essays, and diary entries. Through its versatility Quill At Will nurtures connections, sparks inspiration, and  a vibrant community of aspiring writers and soulful sharers.',
        coloredImage:
          'assets/img/webp-image/casestudydetails/portfolioquillatwill.webp',
        // grascleimg:"assets/img/webp-image/casestudydetails/blackportfolioquillatwill.webp",
        bgColorTransition:
          'linear-gradient(to right, #442EAA, #442EAA, #4AC0D2, #4AC0D2)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/quillatwillbottombanner.webp',
        techlogo: quillwillogo,
      },

      {
        slug: 'maison-luxe',
        herobgimage: 'assets/img/webp-image/casestudydetails/lamaison-bg.webp',
        heroTitleColor:
          'linear-gradient(92.63deg, #4E4E4E 1.21%, #00664F 43.44%)',
        heroTitle: 'Crafting Timeless Luxury',
        herodesc:
          'Maison Luxe is a hub of international luxury brands and products, housing the most sought-after brands by bringing personalized shopping experiences.',
        category: 'Lifestyle',
        serviceOff: 'E-commerce Development, Testing/QA Analysis',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/lamaison-laptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Front-End Developer,1 Drupal Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',
        projectOverDes:
          'Maison Luxe stands as a prestigious destination renowned for its exceptional curation of international luxury brands and products. Catering to discerning shoppers, it offers personalized shopping experiences that cater to those seeking the most sought-after brands. Within its elegant confines, Maison Luxe seamlessly merges opulence and exclusivity, establishing itself as the go-to hub for individuals with a taste for the finest luxuries the world has to offer.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/lamaison-logo.webp',
        projectOverbgColor: '#00664F',
        projectOverbordercolor: '#033E30',
        proejctOverShadow: '10px 10px #7AB0A4',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          "Ensuring that the user experience reflects the personalized shopping experiences offered in-store while maintaining a user-friendly interface was one of the hard challenges we faced and integrating a vast and diverse collection of international luxury brands and products within the website's architecture and navigation was another technical challenge, especially regarding load times and organization. Securing sensitive customer data and transactions to preserve Maison Luxe's prestigious reputation was a significant undertaking.",
        approchDes:
          "We adopted a meticulous and comprehensive approach to overcome these challenges. Thorough research and close collaboration with Maison Luxe granted us profound insights into their personalized in-store shopping experiences. Implementing advanced database management optimized the website's architecture for smooth navigation and minimized load times, while robust security protocols safeguarded customer data and transactions. Rigorous testing and optimization ensured a consistent and enjoyable browsing experience across various devices.",
        keyResult:
          "La Maison du Luxe's presence in the market has redefined luxury living by curating an exquisite collection of premium furnishings and décor. Catering to discerning tastes, their offerings have elevated interior design standards, creating a lasting impact on the market's perception of opulence and sophistication. Through its meticulously selected range, La Maison du Luxe has become synonymous with refined aesthetics and exclusive home environments.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/colored-image.webp',
        bgColorTransition:
          'linear-gradient(101.38deg, #033E30 0%, #00664F 100.01%)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/bottom-bg-img.webp',
        techlogo: lamaisontech,
      },

      {
        slug: 'smart-door',
        herobgimage: 'assets/img/webp-image/casestudydetails/smartherobg.webp',
        heroTitleColor:
          'linear-gradient(92.63deg, #AD134B 1.21%, #721638 43.44%)',
        heroTitle: 'Smart Access Simplified',
        herodesc:
          'SmartDoor makes the process of finding and visiting properties easier for buyers, sellers, and renters by making the property visiting experience more efficient, reliable, and hassle-free.',
        category: 'Property & Real Estate',
        serviceOff:
          'Mobile App Development, Testing/QA Analysis, Dedicated Team',
        heroRightImage: 'assets/img/webp-image/casestudydetails/smartdoor.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager,1 Front-End Developer, 1 Opencart Developer, 1 QA Analyst',
        countryFlag:
          'assets/img/webp-image/casestudydetails/flagindiacasestudies.webp',
        countryName: 'Pune, India',
        projectOverDes:
          'SmartDoor revolutionizes the real estate experience by seamlessly integrating advanced technology, allowing you to effortlessly buy, rent, or showcase properties. Our innovative digital key system and customizable visit scheduling eliminate the need for traditional on-site tours. With SmartDoor, property management becomes not only efficient but also environmentally conscious, reducing the hassle of lock changes for every tenant turnover. Embrace the future of real estate with SmartDoor and unlock a new era of convenience and flexibility.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/smartdoorlogo.webp',
        projectOverbgColor: '#AD134B',
        projectOverbordercolor: '#721638',
        proejctOverShadow: '10px 10px #CAB7BE',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          'Developing SmartDoor posed several challenges, each met with innovative solutions. Creating a user-friendly interface for property listings and visit scheduling required meticulous design and seamless integration. Overcoming the skepticism of traditional real estate practices and encouraging the adoption of technology-driven solutions was another hurdle. Additionally, ensuring compatibility with diverse property types and existing infrastructure required extensive testing, refinement, and unwavering dedication.',
        approchDes:
          'To address the challenges in building SmartDoor user experience was prioritized through iterative design, streamlining property listings and visit scheduling for intuitive navigation. The development process included comprehensive compatibility testing across various property types and systems, resulting in a seamless and reliable user experience.The collaborative efforts of our skilled team led to the successful creation of SmartDoor, a groundbreaking solution at the forefront of modern real estate technology.',
        keyResult:
          " SmartDoor's mobile app & its services have revolutionized the market by redefining access control. Empowering users to manage and monitor their doors remotely, the app has elevated security convenience, establishing SmartDoor as a frontrunner in modern, tech-driven home and business protection solutions. Additionally, the app's user-friendly interface and real-time notifications have set a new standard for seamless, smart access management.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/smartdoormobileimage.webp',
        bgColorTransition:
          'linear-gradient(101.38deg, #AD134B 0%, #721638 100.01%)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/smartdoorimg2.webp',
        techlogo: smartdoortech,
      },

      {
        slug: 'upside-health',
        herobgimage: 'assets/img/webp-image/casestudydetails/uphealth-bg.webp',
        heroTitleColor:
          'linear-gradient(92.63deg, #231F5C 1.21%, #2E2D43 43.44%)',
        heroTitle: 'Unlock Your Wellness Potential',
        herodesc:
          'UpsideHealth is a transformative platform dedicated to unlocking individual wellness potential. Elevate your health and enhance your life through personalized solutions.',
        category: 'Retail & Consumer ',
        serviceOff:
          'Mobile App Development, Testing / QA Analysis , Dedicated Team',
        heroRightImage:
          'assets/img/webp-image/casestudydetails/upsidemobile2.webp',
        noOfteam: '3',
        teamDes: '1 Project Manager, 1 Flutter Developer, 1 QA Analyst',
        countryFlag:
          'assets/img/webp-image/casestudydetails/flagindiacasestudies.webp',
        countryName: 'Pune, India',
        projectOverDes:
          'UpsideHealth is a trailblazing platform that redefines the way we approach individual well-being. It empowers users to unlock their full potential and take charge of their health journey. With its unique and customizable solutions, UpsideHealth paves the way for a profound transformation towards improved vitality and life satisfaction. Embracing advanced technology and personalized strategies, this platform shines as a guiding light for those striving to amplify their overall life experience.',
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/upsidelogo.webp',
        projectOverbgColor: '#231F5C',
        projectOverbordercolor: '#05004E',
        proejctOverShadow: '10px 10px #B9B8CD',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          'Developing and enhancing the UpsideHealth app presented a series of formidable challenges. Adapting cutting-edge technology to diverse user needs demanded intricate design and rigorous testing. Integrating personalized solutions while maintaining data privacy and security required meticulous attention. Scaling the platform to accommodate increasing user demands while ensuring seamless performance stretched our resources. Balancing innovation with user-friendly interface design posed a constant challenge, demanding continuous iteration.',
        approchDes:
          'To surmount these challenges, we adopted a multifaceted approach. Our development process involved close collaboration between tech experts, designers, and wellness professionals to ensure a user-centric experience. Agile methodologies allowed us to swiftly adapt to evolving user requirements while maintaining app stability. Feedback was actively solicited and incorporated to strike a balance between innovation and intuitive design, resulting in a resilient and user-friendly app.This collaborative effort empowered us to create a cutting-edge solution. ',
        keyResult:
          "The introduction of the mobile app for Upside Health's Sense feature has significantly transformed the market, enhancing user accessibility and engagement. This innovation empowers individuals to seamlessly monitor and manage their health, fostering a new era of proactive well-being management and driving a notable shift towards personalized, on-the-go healthcare solutions.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/upsidenewcolor.webp',
        bgColorTransition: '#231F5C',
        portfolioimg2: 'assets/img/webp-image/casestudydetails/upsie-img2.webp',
        techlogo: upsidetech,
      },

      {
        slug: 'the-bezel-club',
        herobgimage: 'assets/img/webp-image/casestudydetails/bezelbg.webp',
        heroTitleColor:
          'linear-gradient(147.65deg, #D2A76F -213.15%, #FFFFFF -117.85%, #815C2B -22.55%, #D2A76F 120.41%, #47361E 168.06%)',
        heroTitle: 'Unveil Your Watch Collection, Join The Bezel Club',
        herodesc:
          ' The Bezel Club: Your exclusive hub for curated luxury watch trading and passionate collector communities, featuring an NFT integration for added exclusivity.',
        category: 'Retail & Consumer ',
        serviceOff: 'Website Design & Development, Testing/QA Analysis',
        heroRightImage: 'assets/img/webp-image/casestudydetails/laptop.webp',
        noOfteam: '4',
        teamDes:
          '1 Project Manager, 1 Vue.js Developer,1 Laravel Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',
        projectOverDes:
          "The Bezel Club stands as an elite watch enthusiasts' community, dedicated to facilitating curated buying and selling experiences. This premium platform for trading luxury timepieces has excelled in fostering an environment where members can exhibit their collections and foster a community of like-minded individuals passionate about high-end watches. It even boasts an exclusive feature that allows members of NFT clubs to showcase their offerings within the marketplace.",
        projectOverLogo:
          'assets/img/webp-image/casestudydetails/thebezelclublogo.webp',
        projectOverbgColor: '#A57D49',
        projectOverbordercolor: '#836134',
        proejctOverShadow: '10px 10px #D4BFA3',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          "To fulfill the client's requirements, our team developed a unique platform for global travelers to register and share their travel experiences. We revamped the existing website, incorporating additional features and improving user interaction. This involved customizing the platform for travel blogging and enhancing traveler profiles with exclusive features. We also designed curated travel features and developed custom analytics for both traveler profiles and the admin. The project required extensive brainstorming and dedicated effort from our team to create a robust and user-friendly platform.",
        approchDes:
          'The client approached us with a basic site with a simple UI. They majorly emphasized on creating an attractive UI for grabbing the attention of new users. Building an easy interface was also one of their prime focuses. Our professional designers and developers worked on the entire management of user profile features, calculated content response and statistics for the articles uploaded by the travelers and influencers on their profile giving them all customizable features to upload pictures in the grid and different crop ratios. ',
        keyResult:
          "The Bezel Club website has left an indelible mark on its audience, emerging as a premium hub for passionate watch enthusiasts. Its dedication to curating user experiences within the realm of luxury timepieces has cultivated a community where collectors can proudly showcase their prized possessions further enriching the platform's exclusivity and appeal to watch connoisseurs.",
        coloredImage:
          'assets/img/webp-image/casestudydetails/bezelcoloredimg.webp',
        bgColorTransition:
          'linear-gradient(147.65deg, #D2A76F -213.15%, #FFFFFF -117.85%, #815C2B -22.55%, #D2A76F 120.41%, #47361E 168.06%)',
        portfolioimg2:
          'assets/img/webp-image/casestudydetails/bezelbottom.webp',
        techlogo: bezeltechlogo,
      },

      {
        slug: 'bmw',
        herobgimage: 'assets/img/webp-image/casestudydetails/bgimagebmw.webp',
        heroTitleColor: '#0E0F0F',
        heroTitle: 'Experience the BMW Lifestyle',
        herodesc:
          'The BMW Kuwait store for lifestyle products is a digital platform that provides a captivating window into the world of BMW beyond automobiles. It offers an array of lifestyle content, encompassing fashion, design, travel, etc.',
        category: ' Lifestyle',
        serviceOff: 'E-commerce Development, Testing/QA Analysis',
        heroRightImage: 'assets/img/webp-image/casestudydetails/bmwlaptop.webp',
        noOfteam: '4',
        teamDes:
          ' 1 Project Manager, 1 Shopify Developer, 1 Front-End Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/uaeflag.webp',
        countryName: 'Dubai, UAE',
        projectOverDes:
          "The BMW Kuwait store for lifestyle products is a digital platform that provides a captivating window into the world of BMW beyond automobiles. It offers an array of lifestyle content, encompassing fashion, design, travel, and technology, all in alignment with BMW's values of luxury and sophistication. The website also seamlessly integrates BMW lifestyle products for purchase and features updates on exclusive events and experiences. With its user-friendly interface and localized content, this platform aims to deepen the brand's connection with the Kuwaiti audience, enriching their lifestyle with the essence of BMW's innovation and elegance.",
        projectOverLogo: 'assets/img/webp-image/casestudydetails/logo.webp',
        projectOverbgColor: '#1E1F1F',
        projectOverbordercolor: '#000000',
        proejctOverShadow: '10px 10px #535353',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          "Designing and developing the website brought several challenges. Crafting a user-friendly interface with e-commerce integration was complex, and catering to Kuwaiti lifestyle preferences required extensive research. Coordinating between different teams to maintain brand consistency and overcoming technical complexities were additional hurdles. Staying current with evolving web technologies and trends was an ongoing challenge. However, our team's dedication led to the successful creation of the BMW Kuwait Lifestyle Website, a testament to our commitment to delivering a dynamic and user-centric platform.",
        approchDes:
          'To overcome the challenges faced in designing and developing the website, our team adopted a systematic approach. We initiated a thorough user experience research phase to ensure that the interface was user-friendly and met local lifestyle preferences. Effective collaboration among cross-functional teams ensured brand consistency and seamless e-commerce integration. The commitment to innovation, extensive research, and teamwork ultimately led to the successful launch of the BMW Kuwait Lifestyle Website, providing users with a dynamic, personalized, and enriching online experience.',
        keyResult:
          " BMW Kuwait Lifestyle has left a lasting mark on its audience by seamlessly merging luxury and lifestyle in a singular online experience. It has empowered individuals to elevate their daily lives, cementing the brand's reputation as a symbol of elegance and innovation in the Kuwaiti market.",
        coloredImage: 'assets/img/webp-image/casestudydetails/colorredimg.webp',
        bgColorTransition: '#1C1C1C',
        portfolioimg2: 'assets/img/webp-image/casestudydetails/bottomimg.webp',
        techlogo: bmwtechstack,
      },

      // acai new
      {
        slug: 'acai',
        herobgimage: 'assets/img/webp-image/casestudydetails/purplebg.png',
        heroTitleColor: '#6253A3',
        heroTitle: 'Elevate Spaces with Artistry',
        herodesc:
          ' 7Walls specializes in providing innovative printed wall coverings, including wallpapers and canvases. They offer personalized design ideas to refresh and innovate indoor spaces in various settings.',
        category: ' Retail & Consumer',
        serviceOff: 'Mobile App Development, Testing/QA Analysis',
        heroRightImage: 'assets/img/webp-image/casestudydetails/acaiBanner.png',
        noOfteam: '4',
        teamDes:
          ' 1 Project Manager, 1 React Native Developer,1 Laravel Developer, 1 QA Analyst',
        countryFlag: 'assets/img/webp-image/casestudydetails/singapore.svg',
        countryName: 'VivoCity, Singapore',
        projectOverDes:
          "ACAI Café, a distinctive Singaporean food chain renowned for its delectable, health-conscious smoothie bowls made from premium organic ingredients, has introduced 'Project Açaí' – an innovative loyalty app. This app is meticulously crafted to enhance users' enjoyment by offering exclusive discounts and reward points in collaboration with trusted brand partners. Furthermore, it serves as a valuable resource for users to discover the art of crafting delicious Açaí bowls at home, thanks to the app's collection of recipes and informative articles.",
        projectOverLogo: 'assets/img/webp-image/casestudydetails/acai_logo.svg',
        projectOverbgColor: '#6253A3',
        projectOverbordercolor: '#514585',
        proejctOverShadow: '10px 10px #A094D6',
        reserachDesignDes:
          'Our diverse team of designers, developers, and analysts collaboratively strategized and meticulously crafted every aspect of the project, be it for web or app development. Together with the client, we carefully envisioned the core functionalities of the applications and the backend panel, ensuring a seamless and user-centric experience. Our dedication to excellence drives us to deliver a unified solution that meets the highest standards of performance, design, and functionality across all web and app interfaces.',
        reserachDesignImage:
          'assets/img/casestudiesdetails_imagesbanner/audi/ezgif.com-gif-maker.gif',
        challengesDes:
          'Developing the Project Açaí app posed several complex challenges that our team skillfully tackled. We seamlessly integrated with brand partners, ensuring exclusive discounts and reward points functionality while maintaining data security. Crafting an engaging user experience that combined loyalty features with rich content, such as recipes and articles, required careful design and development. Platform compatibility, scalability, and rigorous quality assurance were also critical aspects that we addressed. In the competitive landscape, we strived to stand out, creating an app that not only met user needs but also complied with regulations, fostering trust and success in the market.',
        approchDes:
          "Our approach to overcoming the challenges in developing the Project Açaí app was multifaceted. We emphasized collaborative partnerships with brand collaborators, fostering open communication channels to facilitate integration and align objectives. User-centric design was at the core of our strategy, with continuous user research and feedback loops guiding the refinement of the app's interface and functionality, ensuring an intuitive and engaging experience. Adopting a cross-platform development framework enabled us to create an app that seamlessly operated on both iOS and Android devices, streamlining development efforts and maximizing reach.",
        keyResult:
          'Successfully launched on both iOS and Android, the Project Acai app revolutionizes the health-conscious market with its seamless, interactive, and user-engaging interface Experience the perfect blend of wellness and cutting-edge design, embracing a seamless journey from order to nourishment, all through the convenience of your mobile device.',
        coloredImage: 'assets/img/webp-image/casestudydetails/acai_middle.png',
        bgColorTransition: '#6253A3',
        portfolioimg2: 'assets/img/webp-image/casestudydetails/acai_bottom.png',
        techlogo: acaitechstack,
      },
    ];

    this.caseStudyProjectData = caseStudyData.find(
      (data) => data.slug === this.id
    );

    if (this.caseStudyProjectData) {
      console.log('Found:', this.caseStudyProjectData);
    } else {
      console.log('Slug not found.');
    }
  }

  ngOnInit() {
    this.loadingService.setLoadingState(true);

    setTimeout(() => {
      this.loadingService.setLoadingState(false);
    }, 2000);
    /* Generate code according to resolution open here */

    if (window.screen.width < 768) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }

    /* Generate code according to resolution close here */
  }
  // Team section open here

  carouselConfig = {
    autoplay: true,
    autoplaySpeed: 0,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    speed: 5000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
    ],
  };
}
