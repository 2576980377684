<div class="baner-img"></div>

<div class="scrollImg" (click)="goToBottom()">
    <img src="assets/img/servicesdev/scrollicon.webp" alt="globe" />
</div>

<div #scrollTarget></div>

<!-- -------------------->

<!-- <div class="page-title-area">

    <img src="https://www.prowebtechnos.com/wp-content/uploads/2022/01/bnner-1.png" />

    <div class="first">
        <img src="assets/img/smmImg.png" alt="smm image" />
    </div>

    <div class="second">
        <app-form></app-form>
    </div>

</div> -->

<section class="ptb-80">


    <div class="container">

        <div class="row">

            <!-- description -->

            <div>



                <h2 class="text-center mainheading">Pro Web<span class="prohead"> Graphic Design
                        Packages </span></h2>

                <div class="bar"></div>
                <p class="text-center desc">Graphic designing plays a crucial role in retaining your customers for a
                    longer duration and eventually enhancing your lead numbers. This also helps in enticing more viewers
                    and attracts them towards your website. Moreover, your clients are the most prime motivation for our
                    designs, and hence we believe in delivering the best yet “just yours.” We specialize in designing
                    logos, business cards, brochures, letterheads, and many other Identity-related items. Just tell us
                    your requirements, and we will right away get started to designing the most interactive and enticing
                    graphics.</p>
            </div>


            <!-- table -->
            <div class="ptb-20 py-3">

                <h2 class="text-center mainheading mb-3">Our Exclusive<span class="prohead"> Graphic Design
                        Packages </span></h2>

                <!-- <h2 class="text-center pb-3"> <span style="color:black !important;"> Our Exclusive</span> <span
                        style="color:#fd7d00 !important;"> Graphic Design Packages </span> </h2> -->


                <div class="bar"></div>

                <!-- Desktop -->

                <div class="tableContent">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png"
                                        class="goldbadge" alt=""> </th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="white">
                                <td>Budget</td>
                                <td class="text-center"> <span class="pricecolor">499 AED </span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor">999 AED</span> Per Month</td>
                                <td class="text-center"> <span class="pricecolor">1999 AED</span> Per Month</td>
                            </tr>
                            <tr class="white">
                                <td>Logo Sample</td>
                                <td class="text-center">1
                                </td>
                                <td class="text-center">1
                                </td>
                                <td class="text-center">2</td>
                            </tr>

                            <tr class="white">
                                <td>Company Profile (4-6 Pages) </td>
                                <td class="text-center"><i class="fa fa-times-circle remove"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-times-circle remove"></i>

                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>

                                </td>

                            </tr>

                            <tr class="white">
                                <td>Business Card Design </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>

                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>

                                </td>

                            </tr>


                            <tr class="white">
                                <td>Letterhead Design</td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Envelope Design </td>
                                <td class="text-center"><i class="fa fa-times-circle remove"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                                <td class="text-center"><i class="fa fa-check-circle"></i>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>Notepad Or Notebook Design </td>
                                <td class="text-center"> <i class="fa fa-times-circle remove"></i> </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>
                            <tr class="white">
                                <td>Receipt Voucher Design </td>
                                <td class="text-center"> <i class="fa fa-times-circle remove"></i></td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i>
                                </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i>
                                </td>
                            </tr>

                            <tr class="white">
                                <td>A4 Flyer Or Rollup Design</td>
                                <td class="text-center"> <i class="fa fa-times-circle remove"></i> </td>
                                <td class="text-center"><i class="fa fa-times-circle remove"></i> </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Email Signature Template</td>
                                <td class="text-center"> <i class="fa fa-times-circle remove"></i> </td>
                                <td class="text-center"><i class="fa fa-times-circle remove"></i> </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Web and Print Ready Formats </td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>


                            <tr class="white">
                                <td>Output Format</td>
                                <td class="text-center"> .psd, .jpg, and .png </td>
                                <td class="text-center">.psd, .jpg, and .png</td>
                                <td class="text-center"> .psd, .jpg, and .png, .ai, .cdr
                                </td>
                            </tr>


                        </tbody>
                    </table>
                </div>


                <div class="mobTabpills">

                    <ul>
                        <li (click)="seo(1)" [ngClass]="{'bgtab' : package == 1}">Startup</li>
                        <li (click)="seo(2)" [ngClass]="{'bgtab' : package == 2}">Gold</li>
                        <li (click)="seo(3)" [ngClass]="{'bgtab' : package == 3}">Enterprise</li>
                    </ul>

                </div>


                <!-- *************************************** -->

                <!-- Mobile -->

                <div class="tableContentMobile">

                    <table class="table" *ngIf="package == 1">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead graybg">Startup</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>Expected Results</td>
                                <td>9-12 Months</td>

                            </tr>

                            <tr class="gray">
                                <td>Price</td>
                                <td> <span class="pricecolor">AED 5500</span> Per Month</td>

                            </tr>

                            <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr>

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 1</td>

                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 10 Hours </td>

                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 2 to 4 </td>

                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>

                            </tr>

                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 2">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead yellowbg" style="position: relative;">Gold <img
                                        src="./assets/img/icons/best-selling.png"
                                        class="goldbadge" alt=""> </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>Expected Results</td>
                                <td>6-9 Months</td>
                            </tr>

                            <tr class="gray">
                                <td>Price</td>
                                <td> <span class="pricecolor">AED 7700</span> Per Month</td>
                            </tr>

                            <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr>

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 2 </td>
                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 20 Hours </td>
                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 5 to 8 </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                        </tbody>
                    </table>

                    <table class="table" *ngIf="package == 3">
                        <thead>
                            <tr>
                                <th scope="col" class="comonhead fw-bold">Features</th>
                                <th scope="col" class="comonhead bluebg">Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="gray">
                                <td>Expected Results</td>
                                <td>3-6 Months</td>
                            </tr>

                            <tr class="gray">
                                <td>Price</td>
                                <td> <span class="pricecolor">AED 12850</span> Per Month</td>
                            </tr>

                            <tr>
                                <th colspan="4" class="heading">
                                    On-Page
                                    Optimization </th>
                            </tr>

                            <tr class="white">
                                <td>Custom Strategy</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Keyword Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Technical Error Fixing</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>
                            <tr class="white">
                                <td>Technical SEO Optimisation</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>
                            <tr class="white">
                                <td>Content Research</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Content Pieces Per Month</td>
                                <td class="text-center"> 4 </td>
                            </tr>
                            <tr class="white">
                                <td>Link Building Audit</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Link Prospecting Per Month</td>
                                <td class="text-center"> 50 Hours </td>
                            </tr>

                            <tr class="white">
                                <td>Email Outreach</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                            <tr class="white">
                                <td>Guest Posts Per Month</td>
                                <td class="text-center"> 9 to 16 </td>
                            </tr>

                            <tr class="white">
                                <td>Monthly Performance Reporting</td>
                                <td class="text-center"> <i class="fa fa-check-circle"></i> </td>
                            </tr>

                        </tbody>
                    </table>

                </div>

                <!-- ************************************* -->



                <!-- Desktop -->

                <!-- <div class="wrapper">

                    <div class="card">

                        <div class="top">
                            <h2>Basic</h2>
                            <h4>AED 2500</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>12 posts/reels and 5 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram)
                            </li>
                            <li>Social Media account setup.
                                Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Monthly progress report &
                                recommendations.</li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Advanced</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>20 posts/reels and 12 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 2
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.</li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)</li>
                        </ul>

                    </div>

                    <div class="card">

                        <div class="top">
                            <h2>Pro</h2>
                            <h4>AED 4000</h4>
                        </div>
                        <hr>

                        <ul class="list">
                            <li>30 posts/reels and 20 interactive
                                stories per month.</li>
                            <li>SMM for- Meta accounts (Facebook,
                                Instagram) + Choice of any 3
                                platforms (X/ Threads/ Snapchat/
                                Pinterest/ TikTok)
                            </li>
                            <li>Social Media account setup.

                            </li>
                            <li>Graphic design & Creative Writing for
                                posts/uploads.
                            </li>
                            <li>Dedicated account manager.
                            </li>
                            <li>Monthly progress report &
                                Recommendations.
                            </li>
                            <li>Social Media Profile optimization
                                (keywords, hashtags)
                            </li>
                            <li>Community Management including
                                replying to comments, messages and
                                moderating follower activities. (to be
                                reviewed after every 5k increase in
                                followers base).
                            </li>
                            <li>Purpose-driven social media ad
                                strategy plan. (Ad budget extra)
                            </li>
                            <li>Influencer outreach (Budget extra)
                            </li>
                        </ul>

                    </div>
                </div> -->

                <!-- ************************************* -->

            </div>

        </div>
    </div>


</section>