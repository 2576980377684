import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-graphic-design-package',
  templateUrl: './graphic-design-package.component.html',
  styleUrls: ['./graphic-design-package.component.css']
})
export class GraphicDesignPackageComponent implements OnInit {

  @ViewChild('scrollTarget', { static: true }) scrollTarget: any = ElementRef;

  goToBottom() {
    // window.scrollTo(0, 6000);
    this.scrollTarget.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  title = 'Graphic Design Package - Proweb';


  constructor(
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }


  // seo package click function

  package: any = 1;
  seo(a: any) {
    this.package = a;
  }


}
