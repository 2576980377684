<!-- Start Page Title -->
<div class="page-title-area">
	<div class="d-table">
		<div class="d-table-cell" [style.background]="'url(' + caseStudyProjectData.herobgimage + ')'">
			<div class="container">
				<div class="row">
					<div class="col-md-5">
						<h1 class="bannerheadingtext" [style.background]="caseStudyProjectData.heroTitleColor">
							{{caseStudyProjectData.heroTitle}}</h1>
						<span class="textbanner">{{caseStudyProjectData.herodesc}}</span>
						<div class="row categoryregion">
							<div class="col-md-6 category">
								<span class="categorytext">Category :</span>
								<span class="dubaiuaetext">{{caseStudyProjectData.category}}</span>
							</div>
							<div class="col-md-6 region">
								<span class="categorytext">Region :</span>
								<span class="dubaiuaetext"><img src={{caseStudyProjectData.countryFlag}}
										style="padding-right: 8px;" width="29" height="14"
										alt="flag" />{{caseStudyProjectData.countryName}}</span>
							</div>
						</div>
						<div class="servicesofferbox">
							<span class="servicesoffertextheading">Services Offered : </span>
							<span class="servicesoffertext">{{caseStudyProjectData.serviceOff}}</span>
						</div>
						<div class="teambox">
							<span class="teamheading">Team : </span>
							<span class="specialisttext">{{caseStudyProjectData.noOfteam}} Specialists</span>
							<span class="specialisttextdeveloper">{{caseStudyProjectData.teamDes}}</span>
						</div>
					</div>
					<div class="col-md-7 flex-bottom">
						<div class="bannerrightimage"><img src={{caseStudyProjectData.heroRightImage}}
								class="bannerimagerighttoppadding" width="500" height="500"
								alt="case study right side image"> </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- End Page Title -->
<section class="topbgtextgrey">
	<div class="container">
		<div class="row">
			<div class="col-md-10">
				<div class="projectviewbox" [style.background]="caseStudyProjectData.projectOverbgColor"
					[style.border-color]="caseStudyProjectData.projectOverbordercolor"
					[style.box-shadow]="caseStudyProjectData.proejctOverShadow">
					<div class="row">
						<div class="col-md-3"><img src={{caseStudyProjectData.projectOverLogo}} class="brandlogo"
								width="136" height="136" alt="brandlogo"></div>
						<div class="col-md-9">
							<h2 class="projectoverviewheading" [style.color]="caseStudyProjectData.textColor">Project
								Overview</h2>
							<div class="textcasedetails" [style.color]="caseStudyProjectData.textColor">
								{{caseStudyProjectData.projectOverDes}}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>

<section class="technologiesbackground">
	<div class="container">
		<div class="section-title3">
			<h2>Futuristic Technology Stack We Used</h2>
			<div class="bar3"></div>

		</div>
	</div>
	<div class="containerfull">
		<ngx-slick-carousel class="carousel owl-carousel industriessectionmain" [config]="carouselConfig">
			<div ngxSlickItem *ngFor="let tools of caseStudyProjectData.techlogo">
				<div class="col-lg-12">
					<div class="industries industriessection">
						<div class="industries-image">
							<img src="{{tools.img}}" width="95" height="81" alt="tech stack">
						</div>
					</div>
				</div>
			</div>
		</ngx-slick-carousel>

	</div>
</section>

<section class="reseachdesignbox">
	<div class="container">
		<div class="row">
			<div class="col-md-7 boxcenter121">
				<div class="boxcenter">
					<div class="projectnameheading">Research & Design </div>
					<img src="assets/img/webp-image/casestudydetails/RPA_development-lifecycle.gif" width="" height=""
						alt="designgf" class="designgf" *ngIf="isMobile" />
					<span class="reseachtext">{{caseStudyProjectData.reserachDesignDes}}</span>
				</div>
			</div>
			<div class="col-md-5" *ngIf="isDesktop">

				<img src={{caseStudyProjectData.reserachDesignImage}} width="500" height="500" alt="designgf" />
			</div>
		</div>
	</div>
</section>

<section class="walkthrough" *ngIf="isDesktop && caseStudyProjectData.prototypeTabImage ">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="casestudioheading">Walkthrough</div>
				<div class="bar"></div>
				<div class="projectnameheading">ProtoTypes</div>
			</div>

		</div>
		<div class="row" style="margin-top: 20px;">
			<div class="col-md-7">
				<img src={{caseStudyProjectData.prototypeTabImage}} width="740" height="480" alt="image">
				<div class="tablettextcontent">
					<h2 class="mobile768">{{caseStudyProjectData.prototype1device1}}</h2>
					{{caseStudyProjectData.prototype1device2}}
				</div>
			</div>
			<div class="col-md-5 typoboxright">
				<img src={{caseStudyProjectData.prototypeMobileImage}} class="typoboxrightimages" width="265"
					height="500" alt="image">
				<div class="tablettextcontent">
					<h2 class="mobile768">{{caseStudyProjectData.prototype2device1}}</h2>
					{{caseStudyProjectData.prototype2device2}}
				</div>
			</div>
		</div>
	</div>
</section>

<section class="approchnew-sec">
	<div class="container">
		<div class="row">
			<div class="col-md-6 center">
				<div class="projectnameheading mobileheading" *ngIf="isMobile">CHALLENGES</div>
				<img class="imagesapporch" *ngIf="isDesktop"
					src="assets/img/webp-image/casestudydetails/challangesimages.webp" width="250" height="250"
					alt="image" />
				<img class="imagesapporch" *ngIf="isMobile"
					src="assets/img/webp-image/casestudydetails/challangesimagesmobile.webp" width="250" height="250"
					alt="image" />
				<div class="projectnameheading" *ngIf="isDesktop">CHALLENGES</div>
				<div class="approachtextbox">
					{{caseStudyProjectData.challengesDes}}
				</div>
			</div>
			<div class="col-md-6 center">
				<div class="projectnameheading" *ngIf="isMobile">APPROACH</div>
				<img class="imagesapporch" src="assets/img/webp-image/casestudydetails/apparochimages.webp" width="250"
					height="250" alt="image" />
				<div class="projectnameheading" *ngIf="isDesktop">APPROACH</div>
				<div class="approachtextbox">
					{{caseStudyProjectData.approchDes}}
				</div>
			</div>

		</div>
	</div>

</section>

<section class="portfolio-section" *ngIf="!caseStudyProjectData.grascleimg &&  isDesktop"
	[style.background]="caseStudyProjectData.bgColorTransition">
	<img src={{caseStudyProjectData.coloredImage}} width="1495" height="663" alt="image" />
</section>

<section class="portfolio-section" *ngIf="caseStudyProjectData.coloredImage && isMobile"
	[style.background]="caseStudyProjectData.bgColorTransition">
	<img src={{caseStudyProjectData.coloredImage}} width="1495" height="663" alt="image" />
</section>

<section class="portfolio-section" [style.background]="caseStudyProjectData.bgColorTransition"
	*ngIf="isDesktop && caseStudyProjectData.grascleimg">
	<div class="containerbmp" [style.background]="'url(' + caseStudyProjectData.coloredImage + ')'">
		<div class="divisor" [style.background]="'url(' + caseStudyProjectData.grascleimg + ')'"></div>
	</div>
</section>

<section class="keyresult-section">
	<div class="container">
		<div class="row">
			<div class="col-md-12 center">
				<div class="projectnameheading mobilekey" *ngIf="isMobile">KEY RESULTS</div>
				<img class="imagesapporch" src="assets/img/webp-image/casestudydetails/bmpkeyresultimage.webp"
					width="250" height="250" alt="image" />
				<div class="projectnameheading" *ngIf="isDesktop">KEY RESULTS</div>
				<div class="keyresulttextbox">{{caseStudyProjectData.keyResult}}</div>
			</div>
		</div>
	</div>
	<div class="containerfull whitebackground" *ngIf="isDesktop">
		<div class="row">
			<div class=""><img class="imagesapporch" src={{caseStudyProjectData.portfolioimg2}} width="" height=""
					alt="image"></div>
		</div>
	</div>

</section>

<div class="whitebackground" *ngIf="isMobile">
	<img class="imagesapporch" src={{caseStudyProjectData.portfolioimg2}} width="" height="" alt="image">
</div>

<section class="clienttestimonial-section" *ngIf="caseStudyProjectData.clientName">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="projectnameheading mobilekey">HAPPY CLIENT</div>
			</div>

		</div>
		<div class="clienttextmonialbox">
			<div class="testimonial-quote group right">
				<img src={{caseStudyProjectData.clientProfileImage}} width="140" height="140" alt="image">
				<div class="quote-container">

					<div>
						<blockquote>
							<p><i>"{{caseStudyProjectData.clientFeedback}}"</i></p>
						</blockquote>
						<cite><span>{{caseStudyProjectData.clientName}}</span><br>
							{{caseStudyProjectData.clientPost}}
						</cite>
						<cite class="noborder">
							<a href={{caseStudyProjectData.projecturl}}><img
									src={{caseStudyProjectData.clientbrandlogo}} width="140" height="140" alt="image"
									class="logobookmypet"></a>


						</cite>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>

<section class="bottom-section">
	<div class="container">
		<div class="row align-items-center">
			<div class="col-md-6 transform">How do you want us to help transform
				your business?</div>
			<div class="col-md-6">
				<div class="whiteboxtell">
					<a routerLink="/contact-us" class="alinkcustom">
						<div class="requirmenttext">Tell us your requirements <img class="rightarrowbmp"
								src="assets/img/webp-image/casestudydetails/rightarrowbook.webp" alt="image" width="42"
								height="22"></div>
					</a>
				</div>
			</div>
		</div>
		<!-- <div class="row rowtopmargin">
			<div class="col-md-6 transform">What do you aspire to become as a
				tech professional?</div>
			<div class="col-md-6">
				<div class="whiteboxtell">
					<a routerLink="/careers" class="alinkcustom">
						<div class="requirmenttext">Explore career opportunities <img class="rightarrowbmp"
								src="assets/img/webp-image/casestudydetails/rightarrowbook.webp" alt="image" width="42"
								height="22"></div>
					</a>
				</div>
			</div>
		</div> -->
	</div>
</section>